import React, { useEffect, useState } from 'react';
import MainHeader from '../../layouts/main/MainHeader';
import Image from './component/image-background';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import DynamicSVG from '../../components/DynamicSVG';
// import { formatItemsPublicationDate } from '../../utils/formatItemsPublicationDate';
import { CircularProgressbar } from 'react-circular-progressbar';

const QuizzHistorique = () => {
  const [quizHistory, setQuizHistory] = useState([]);
  const { user } = useAuth0();
  const userId = user.sub;

  const findScoreById = (idUser, scores) => {
    const index = scores.findIndex((score) => score.user === idUser);
    if (index > -1) {
      return scores[index].score;
    }
  };

  const comparaison = (score_auth, scores_other) => {
    const different = score_auth - scores_other;
    if (isNaN(score_auth) && isNaN(scores_other)) {
      return {
        type: 'En attente',
        different: 0,
        bg: 'bg-[#E42F5A] w-[80px] h-6 rounded-2xl text-center',
      };
    }
    // console.log(different, 'differet');
    // if (isNaN(different)) {
    //   return {
    //     type: 'pending',
    //     different: 0,
    //     bg: 'bg-[#E42F5A] w-[80px] h-6 rounded-2xl  text-center',
    //   };
    // }
    if (different > 0) {
      // console.log(different, 'difdif');
      return {
        type: 'Victoire',
        different,
        bg: 'bg-[#27C089] w-[80px] h-6 rounded-2xl text-center',
      };
    }
    return {
      type: 'Defaite',
      different,
      bg: 'bg-[#E42F5A] w-[80px] h-6 rounded-2xl  text-center',
    };
  };

  const calculerPourcentage = (score) => {
    if (isNaN(score)) {
      return 0;
    }
    const resultat = (score * 100) / 350;
    return resultat;
  };

  const getColor = (percentage) => {
    if (percentage < 40) {
      return { bg: '#E42F5A', bg2: '#422547' }; // Rouge si le pourcentage est inférieur à 40%
    } else if (percentage < 70) {
      return { bg: '#018FFB', bg2: '#153967' }; // Bleu si le pourcentage est inférieur à 70%
    } else {
      return { bg: '#27C089', bg2: '#1d4250' }; // Vert sinon
    }
  };

  const fetchDataById = async (id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BACK_OFFICE_URL}levels/niveau/${id}`
        // `http://localhost:8080/levels/niveau/${id}`
      );
      return response.data[0]?.themes[0]?.nomTheme || 'Unknown Theme';
    } catch (error) {
      console.error(error);
      return 'Unknown Theme';
    }
  };

  const fetchQuizHistory = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BACK_OFFICE_URL}quiz/historique/${userId}`
        // `http://localhost:8080/quiz/historique/${userId}`
      );
      const quizHistoryData = response.data;

      if (quizHistoryData) {
        const datas = await Promise.all(
          quizHistoryData.map(async (niveau) => {
            const theme = await fetchDataById(niveau.niveau);
            niveau.themeName = theme;
            return niveau;
          })
        );
        setQuizHistory(datas);
      }
    } catch (error) {
      console.error(
        "Erreur lors de la récupération de l'historique des quiz :",
        error
      );
    }
  };

  useEffect(() => {
    fetchQuizHistory();
  }, []);

  return (
    <div className="w-full">
      <Image />
      <MainHeader items={2} leftText="Historique" />
      {quizHistory.map((quiz, index) => (
        <div key={index} className="text-white">
          {quiz.type_jeu === 'avec_amis' && (
            <div>
              <div className=" bg-[#1A2342] rounded-lg my-2">
                <div className="flex items-center justify-evenly">
                  {
                    comparaison(
                      findScoreById(quiz?.players[0]?.user_id, quiz.scores),
                      findScoreById(quiz?.players[1]?.user_id, quiz.scores)
                    )?.type
                  }
                  {/* {findScoreById(quiz?.players[0]?.user_id, quiz.scores)} */}

                  <div>
                    <img
                      src={
                        quiz?.players[0]?.user_metadata?.profile_picture_flavors
                          ?.S || './assets/images/default_profile.png'
                      }
                      alt="avatar"
                      className="w-10 h-10 my-2 rounded-full object-cover"
                    />
                  </div>

                  <div>
                    <p className="text-white text-xs">VS</p>
                  </div>
                  <div>
                    {/* {quiz?.players.slice(1).map((invitedUser, index) => ( */}
                    <div className="flex items-center">
                      <img
                        src={
                          quiz?.players[1]?.user_metadata
                            ?.profile_picture_flavors?.S ||
                          process.env.REACT_APP_AUTH0_REDIRECT_URI +
                            './assets/images/img_default_profile.png'
                        }
                        alt="avatar"
                        className="w-10 h-10 rounded-full object-cover mr-4"
                      />
                      <span
                        className={
                          comparaison(
                            findScoreById(
                              quiz?.players[0]?.user_id,
                              quiz.scores
                            ),
                            findScoreById(
                              quiz?.players[1]?.user_id,
                              quiz.scores
                            )
                          )?.bg
                        }
                      >
                        {userId == quiz?.players[0]?.user_id
                          ? comparaison(
                              findScoreById(
                                quiz?.players[0]?.user_id,
                                quiz.scores
                              ),
                              findScoreById(
                                quiz?.players[1]?.user_id,
                                quiz.scores
                              )
                            )?.different
                          : findScoreById(
                              quiz?.players[0]?.user_id,
                              quiz.scores
                            )}
                        {/* {findScoreById( quiz?.players[1]?.user_id, quiz.scores)} */}
                      </span>
                    </div>
                    {/* // ))} */}
                  </div>
                </div>
                {/* <p className="ml-5 text-[8px]">
                  Il y a {formatItemsPublicationDate(quiz.date_creation)}
                </p> */}
              </div>
            </div>
          )}
          {quiz.type_jeu === 'solo' && (
            <div className="flex bg-[#1A2342] rounded-lg my-2 py-2">
              <DynamicSVG
                src="./assets/icons/ic_question.svg"
                className="w-1 h-1 absolute pl-2 pb-6 "
              />
              <div className="mx-16 truncate w-72 ">
                <p>{quiz?.themeName}</p>
                <p>{quiz?.nombre_de_questions} questions</p>
                {/* 
                <p className="text-[8px]">
                  Il y a {formatItemsPublicationDate(quiz.date_creation)}
                </p> */}
              </div>
              <div className="ml-auto ">
                <CircularProgressbar
                  value={calculerPourcentage(quiz?.scores[0]?.score)}
                  text={`${Math.round(
                    calculerPourcentage(quiz?.scores[0]?.score)
                  )}`}
                  className="w-11 h-11 mr-3"
                  styles={{
                    root: {}, // Ne modifiez pas le style de base
                    path: {
                      stroke: getColor(
                        calculerPourcentage(quiz?.scores[0]?.score)
                      ).bg,
                    }, // Couleur de la barre de progression
                    trail: {
                      stroke: getColor(
                        calculerPourcentage(quiz?.scores[0]?.score)
                      ).bg2,
                    }, // Ne pas afficher la piste
                    text: {
                      fill: getColor(
                        calculerPourcentage(quiz?.scores[0]?.score)
                      ).bg,
                      fontSize: '30px',
                      fontWeight: 'bold',
                    }, // Couleur et taille du texte
                  }}
                />
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default QuizzHistorique;
