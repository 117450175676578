import React from 'react';
import { Link, useParams } from 'react-router-dom';
import Page from '../../components/Page';
// import { useAuth0 } from "@auth0/auth0-react";
// import { getDatabaseUserInfo } from '../../services/User';
// import { useEffect,useState } from 'react';

const QuizzChallengeAmi = () => {
  const { userId } = useParams();

  // const { user } = useAuth0();
  // const userCo = user.sub;

  // const { getAccessTokenSilently } = useAuth0();
  // const [isLoading, setIsLoading] = useState(true);
  // const [userInfoList, setUserInfoList] = useState([]);

  // useEffect(() => {
  //   const getUserListInfo = async () => {
  //     try {
  //       const token = await getAccessTokenSilently();
  //       const userIds = [userId, userCo];
  //       const userInfoList = [];

  //       for (const userId of userIds) {
  //         const userDatas = await getDatabaseUserInfo(userId, token);
  //         userInfoList.push(userDatas);
  //       }

  //       console.log("Informations des utilisateurs :", userInfoList);
  //       setUserInfoList(userInfoList);
  //       setIsLoading(false);
  //     } catch (err) {
  //       console.error(err);
  //       setIsLoading(false);
  //     }
  //   };

  //   getUserListInfo();
  // }, []);

  return (
    <Page
      className="flex flex-col items-center justify-center min-h-screen"
      title="Quizz"
    >
      <div
        className="fixed top-0 sm:w-1/2 md:w-2/3 lg:w-1/3 xl:w-1/4"
        style={{ pointerEvents: 'none' }}
      >
        <img
          src={
            process.env.REACT_APP_AUTH0_REDIRECT_URI +
            '/assets/images/img_sfeira_watermark.png'
          }
          alt="sfeira"
        />
        <div className="livescore-purple-neon"></div>
        <div className="livescore-blue-neon"></div>
      </div>
      <div className="w-full">
        <div
          className="flex items-center justify-evenly"
          style={{ marginTop: 80 }}
        >
          <div>
            <img
              src={
                process.env.REACT_APP_AUTH0_REDIRECT_URI +
                '/assets/images/user.png'
              }
              alt="Image 1"
              className="w-20 h-20"
            />
          </div>
          <div>
            <p className="text-white">VS</p>
          </div>
          <div>
            <img
              src={
                process.env.REACT_APP_AUTH0_REDIRECT_URI +
                '/assets/images/user2.png'
              }
              alt="Image 2"
              className="w-20 h-20"
            />
          </div>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />{' '}
        <div className="m-auto">
          <Link to={`/quizz/theme/${userId}`}>
            {/* <Link to="/quizz/start-defi"> */}
            <button className="text-sm py-3 my-2 text-white bg-blue rounded-full w-full">
              Choisir un thème
            </button>
          </Link>
        </div>
      </div>
    </Page>
  );
};

export default QuizzChallengeAmi;
