import { useState } from 'react';
import { searchUserRelation } from '../../../services/Relation';
import { useAuth0 } from '@auth0/auth0-react';
import { Browser } from '@capacitor/browser';

const REACT_APP_AUTH0_REDIRECT_URI = process.env.REACT_APP_AUTH0_REDIRECT_URI;
const useFetchUserRelations = () => {
  const [userFetchedRelation, setUserFetchedRelation] = useState([]);
  const [isFetchingUserRelation, setIsFetchingUserRelation] = useState(false);
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

  const fetchUserRelation = async (data) => {
    try {
      setIsFetchingUserRelation(true);
      const token = await getAccessTokenSilently();
      const res = await searchUserRelation(token, data);
      setUserFetchedRelation(res);
      setIsFetchingUserRelation(false);
      return res;
    } catch (e) {
      if (e.error === 'invalid_grant' || e.error === 'missing_refresh_token') {
        loginWithRedirect({
          appState: {
            returnTo: REACT_APP_AUTH0_REDIRECT_URI,
          },
          async openUrl(url) {
            // Redirect using Capacitor's Browser plugin
            await Browser.open({
              url,
              windowName: '_self',
            });
          },
        });
      }
      setIsFetchingUserRelation(true);
      console.error(e);
    }
  };

  return {
    fetchUserRelation,
    isFetchingUserRelation,
    userFetchedRelation,
  };
};

export default useFetchUserRelations;
