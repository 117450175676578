import axiosInstance from '../utils/api';

const REACT_APP_API_USER_RELATION_URL =
  process.env.REACT_APP_API_USER_RELATION_URL;

export const createRelation = async (data, token) => {
  try {
    const res = await axiosInstance(
      REACT_APP_API_USER_RELATION_URL,
      'post',
      '/relations',
      {
        Authorization: `Bearer ${token}`,
      },
      {
        ...data,
      }
    );
    return res;
  } catch (error) {
    console.error(error);
  }
};

export const updateRelation = async (data, relationId, token) => {
  try {
    const res = await axiosInstance(
      REACT_APP_API_USER_RELATION_URL,
      'patch',
      `relations/${relationId}`,
      {
        'content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      {
        ...data,
      }
    );
    return res;
  } catch (error) {
    console.error(error);
  }
};

export const searchUserRelation = async (token, data) => {
  try {
    const res = await axiosInstance(
      REACT_APP_API_USER_RELATION_URL,
      'post',
      `relations/search`,
      {
        'content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data
    );
    return res;
  } catch (error) {
    console.error(error);
  }
};

export const getFollowUserRelationCount = async (token, userId) => {
  try {
    const res = await axiosInstance(
      REACT_APP_API_USER_RELATION_URL,
      'get',
      `users/${userId}/counters`,
      {
        'content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }
    );
    return res;
  } catch (error) {
    console.error(error);
  }
};

export const getUserSuggestions = async (token) => {
  try {
    const res = await axiosInstance(
      REACT_APP_API_USER_RELATION_URL,
      'get',
      `relations/suggestions`,
      {
        'content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }
    );
    return res;
  } catch (error) {
    console.error(error);
  }
};

export const deleteRelation = async (token, relationId) => {
  try {
    const res = await axiosInstance(
      REACT_APP_API_USER_RELATION_URL,
      'delete',
      `relations/${relationId}`,
      {
        'content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }
    );
    return res;
  } catch (error) {
    console.error(error);
  }
};

export const blockUser = async (token, userId) => {
  try {
    const res = await axiosInstance(
      'http://localhost:4000/',
      'post',
      `relations/block`,
      {
        'content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      {
        endId: userId,
      }
    );
    return res;
  } catch (error) {
    console.error(error);
  }
};

export const unBlockUser = async (token, userId) => {
  try {
    const res = await axiosInstance(
      REACT_APP_API_USER_RELATION_URL,
      'post',
      `relations/unblock`,
      {
        'content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      {
        endId: userId,
      }
    );
    return res;
  } catch (error) {
    console.error(error);
  }
};

export const getUserFriendship = async (token, users) => {
  try {
    const res = await axiosInstance(
      REACT_APP_API_USER_RELATION_URL,
      'post',
      'relations/friendships',
      {
        'content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      {
        users,
      }
    );
    return res;
  } catch (error) {
    console.error(error);
  }
};

export const getFirends = async (token) => {
  try {
    const res = await axiosInstance(
      REACT_APP_API_USER_RELATION_URL,
      'get',
      'relations/friends',
      {
        'content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }
    );
    return res;
  } catch (error) {
    console.error(error);
  }
};
