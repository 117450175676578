import React from 'react';

export default function LegalNoticeText() {
  return (
    <div className="text-sm text-white mb-16">
      <div className="text-xl">Éditeur de site</div>
      Le présent Site est la propriété de la société SFEIRA SAS agissant en
      qualité d’éditeur pour et sur www.sfeira.com Siège social : 2 rue de
      l’épine, 59 650 Villeneuve d’ascq, France. Email : contact@sfeira.com
      Numéro de téléphone : +33 3 62 27 97 97 Société par actions simplifiée, au
      capital social de 1 102 000 € Immatriculée au Registre du Commerce et des
      Sociétés de Lille Métropole, sous le numéro SIREN 951087170 Directeur de
      la publication : M. David CASSARO
      <div className="text-xl">Conception du site</div>
      Le présent Site a été conçu par la société SFEIRA SAS Email :
      contact@sfeira.com Numéro de téléphone : +33 3 62 27 97 97
      <div className="text-xl">Hébergeur de site</div>
      Le présent Site est hébergé par ODOO Belgique et dont l’adresse postale
      est le Ferme 1 : Chaussée de Namur 40 Ferme 2 : Rue des Bourlottes 9, 1367
      Grand-Rosière, Belgique.
      <div className="text-xl">Description des services fournis</div>
      Le Site www.sfeira.com a pour objet de fournir à tout Utilisateur les
      informations relatives aux services proposées par l’application Sfeira
      App. L’application Sfeira App offre à tous les supporteurs, joueurs et
      clubs de football une plateforme d’échange et de mise en relation dans un
      environnement totalement consacré à leur sport.
      <div className="text-xl">Propriété intellectuelle</div>
      La Société est le titulaire exclusif de tous les droits de propriété
      intellectuelle portant sur la structure et les contenus du Site et de la
      Prestation. Les contenus (notamment et non limitativement les
      informations, textes, graphiques, données, images, photos, visuels, vidéos
      et bande sonore, ainsi que la forme de tous ces éléments et du Site
      lui-même) sont protégés au titre des droits d’auteur et/ou autres droits
      de propriété intellectuelle. Les contenus sont la propriété exclusive de
      leurs éditeurs respectifs. Toute copie, reproduction, représentation,
      exploitation, adaptation, altération, modification, traduction, diffusion,
      intégrale ou partielle, des contenus, qu’ils appartiennent à la Société ou
      à un tiers qui a concédé des droits à la Société, par quelque procédé que
      ce soit, est illicite. Les contenus présentés dans ce Site sont
      susceptibles de modification sans préavis et sont mis à disposition sans
      aucune garantie d’aucune sorte, expresse ou tacite et ne peuvent donner
      lieu à un quelconque droit à dédommagement. Les contenus protégés peuvent
      notamment être identifiés par les mentions de réserve suivantes : « © » ou
      « Tous droits réservés ». Les noms et logos apparaissant sur le Site sont
      des marques déposées. Ces signes distinctifs sont la propriété exclusive
      de la Société ou de tiers ayant concédé des droits à la Société. Toute
      utilisation, reproduction totale ou partielle, ou imitation de ces marques
      est interdite, au sens des articles L.713-2 et suivants du Code de la
      propriété intellectuelle, sauf autorisation préalable et expresse de la
      Société.
      <div className="text-xl">Droit à l’image</div>
      Toutes personnes, représentées sur les photographies publiées sur le Site
      ont consenti à l’usage de leur image dans le cadre des prestations
      réalisées par la Société. Les salariés et dirigeants de la Société dont
      l’image est utilisée sur le Site ont également consenti à l’utilisation de
      leur photographie dans le cadre dudit Site. À défaut d’accord préalable
      écrit consenti par les personnes intéressées, l’utilisation de leur image
      est strictement interdite, à quelque titre que ce soit.
      <div className="text-xl">Limite de responsabilité</div>
      La Société fait ses meilleurs efforts pour vérifier l’exactitude des
      informations contenues sur son Site mais ne peut être tenu responsable des
      omissions, inexactitudes et des carences dans la mise à jour de ces
      informations. La Société ne saurait être tenue responsable de tout dommage
      direct ou indirect résultant de l’accès à son Site (intrusion extérieure,
      présence de virus informatique…) ou, au contraire, de l’impossibilité d’y
      accéder (rupture de service…). Toute demande d’information en rapport avec
      le contenu ou le fonctionnement du Site ainsi que le signalement de toute
      erreur ou omission peut être adressée à : contact@sfeira.com
      <div className="text-xl">Liens vers d’autres sites</div>
      Le Site contient des liens hypertextes vers d’autres sites et réseaux
      sociaux (Facebook, twitter, Instagram). Cependant, la Société ne peut
      vérifier le contenu des sites ainsi visités et décline donc toute
      responsabilité quant aux risques éventuels de contenus illicites. La
      création de liens ou de frames du Site nécessite l’autorisation préalable
      de la Société qui peut être révoquée à tout moment, à l’entière discrétion
      de la Société. Cette autorisation préalable vaut aussi bien pour la
      création : • d’un lien hypertexte sur la page de garde dans une nouvelle
      fenêtre du navigateur, • d’un lien vers une page intérieure du Site, •
      d’un lien à travers des fenêtres (framing), • d’un lien vers un objet
      contenu dans le site (inlining), • et plus généralement de toute technique
      de création de lien hypertexte.
      <div className="text-xl">Réseaux sociaux</div>
      Le Site permet aux utilisateurs d’accéder aux réseaux sociaux de la
      Société (Facebook, Twitter, Instagram) en cliquant sur l’icône
      représentant le réseau social. Cette action amène l’utilisateur à se
      connecter ou à s’inscrire audits réseaux sociaux. La Société n’est pas
      responsable des actions effectuées par l’utilisateur sur le site de ces
      réseaux sociaux. En cas de doute, de problème ou de réclamation,
      l’utilisateur devra contacter le service client du réseau social concerné
      ou se référer à leurs Conditions générales d’utilisation.
    </div>
  );
}
