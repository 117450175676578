import Router from './routes';

import './App.css';
import 'simplebar-react/dist/simplebar.min.css';
import 'react-day-picker/dist/style.css';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
// import DynamicSVG from './components/DynamicSVG';
// import { ACTIVE_SVG_FILL } from './utils/svgColor';
// import DesktopError from './components/DesktopError';

import { App as CapApp } from '@capacitor/app';
import { Browser } from '@capacitor/browser';
import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
  checkAndRequestPermission,
  registerDevice,
} from './services/notifications/Notification';
import UseDeviceTypeChecker from './hooks/useDeviceTypeChecker';
import { initializeAnalytics } from './services/Analytics';
import useFetchUnreadNotificationCount from './hooks/fetch/notification/useFetchUnreadNotificationCount';
import useGetUserMessages from './hooks/fetch/messaging/useGetUserMessages';
import useMessagingFilters from './hooks/useMessaging';
import useGetAllUnreadMessagesNumber from './hooks/fetch/messaging/useGetAllUnreadMessagesNumber';
import useAuthUserInfo from './hooks/useAuthUserInfo';
import { useNavigate } from 'react-router';
import { Device } from '@capacitor/device';
// import { useNavigate } from 'react-router';

function App() {
  const analyticsEnabled = process.env.REACT_APP_ANALYTICS_ENABLED || false;
  // const REACT_APP_AUTH0_REDIRECT_URI = process.env.REACT_APP_AUTH0_REDIRECT_URI;
  // const navigate = useNavigate();
  // const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  // if (!isMobile) return <DesktopError />;

  // check for notification activation
  const { isAuthenticated, getAccessTokenSilently, loginWithRedirect } =
    useAuth0();
  const { fetchNotificationsCount } = useFetchUnreadNotificationCount();
  const deviceType = UseDeviceTypeChecker();
  const { getUserMessages } = useGetUserMessages();
  const {
    messagingState: {
      invitationsMessages,
      groupsMessages,
      individualsMessages,
    },
  } = useMessagingFilters();
  const { getAllUnreadMessagesNumber } = useGetAllUnreadMessagesNumber();
  const { setUserDeviceToken, userDeviceToken, userInfo } = useAuthUserInfo();
  const navigate = useNavigate();

  useEffect(() => {
    if (analyticsEnabled === 'true') {
      return () => initializeAnalytics();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      getUserMessages();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  useEffect(() => {
    try {
      if (
        invitationsMessages.length > 0 ||
        groupsMessages.length > 0 ||
        individualsMessages.length > 0
      ) {
        getAllUnreadMessagesNumber();
      }
    } catch (error) {
      console.error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invitationsMessages, groupsMessages, individualsMessages]);

  useEffect(() => {
    try {
      const askToActivateNotificationsIfNeeded = async () => {
        const info = await Device.getInfo();
        if (info.platform !== 'ios') {
          if (!isAuthenticated || userDeviceToken) {
            return;
          }
          const authToken = await getAccessTokenSilently();

          if (await checkAndRequestPermission()) {
            const deviceToken = await registerDevice(
              authToken,
              deviceType,
              fetchNotificationsCount,
              navigate,
              userInfo
            );

            if (deviceToken) {
              setUserDeviceToken(deviceToken);
            }
          }
        }
      };

      askToActivateNotificationsIfNeeded();
    } catch (error) {
      console.error(error);
      if (
        error.error === 'invalid_grant' ||
        error.error === 'missing_refresh_token'
      ) {
        loginWithRedirect({
          appState: {
            returnTo: '/',
          },
          async openUrl(url) {
            // Redirect using Capacitor's Browser plugin
            await Browser.open({
              url,
              windowName: '_self',
            });
          },
        });
      }
    }
  }, [isAuthenticated, userDeviceToken, userInfo]);

  const { handleRedirectCallback } = useAuth0();
  useEffect(() => {
    // Handle the 'appUrlOpen' event and call `handleRedirectCallback`
    CapApp.addListener('appUrlOpen', async ({ url }) => {
      if (
        url.includes('state') &&
        (url.includes('code') || url.includes('error'))
      ) {
        await handleRedirectCallback(url);
      }
      // navigate(REACT_APP_AUTH0_REDIRECT_URI);
      // No-op on Android
      await Browser.close();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleRedirectCallback]);

  return (
    <MotionLazyContainer>
      <Router />
    </MotionLazyContainer>
  );
}

export default App;
