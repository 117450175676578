import DefaultSnackbar from '../../components/messaging/Snackbar/DefaultSnackbar';
import GroupInvitationSnackbar from '../../components/messaging/Snackbar/GroupInvitationSnackbar';
import GroupSnackbar from '../../components/messaging/Snackbar/GroupSnackbar';
import OneToOneInvitationSnackbar from '../../components/messaging/Snackbar/OneToOneInvitationSnackbar';
import OneToOneSnackbar from '../../components/messaging/Snackbar/OneToOneSnackbar';
import axiosInstance from '../../utils/api';

import * as nativeNotification from './NativeNotification';
import * as webNotification from './WebNotification';

import { Capacitor } from '@capacitor/core';
import { closeSnackbar, enqueueSnackbar } from 'notistack';

const REACT_APP_API_NOTIFICATION_URL =
  process.env.REACT_APP_API_NOTIFICATION_URL;

const isPushNotificationsAvailable =
  Capacitor.isPluginAvailable('PushNotifications');

export const checkAndRequestPermission = async () => {
  if (isPushNotificationsAvailable) {
    return nativeNotification.checkAndRequestPermission();
  } else {
    return webNotification.checkAndRequestPermission();
  }
};

export const registerDevice = async (
  authToken,
  deviceType,
  callback,
  navigate,
  userInfo
) => {
  if (isPushNotificationsAvailable) {
    return nativeNotification.registerDevice(
      authToken,
      deviceType,
      callback,
      navigate,
      userInfo
    );
  } else {
    return webNotification.registerDevice(
      authToken,
      deviceType,
      callback,
      navigate,
      userInfo
    );
  }
};

export const unregisterDevice = async (authToken, deviceToken) => {
  if (isPushNotificationsAvailable) {
    return nativeNotification.unregisterDevice(authToken, deviceToken);
  } else {
    return webNotification.unregisterDevice(authToken, deviceToken);
  }
};

export const showInAppMessagingNotification = (payload, navigate, userInfo) => {
  const notification = payload.data;
  const snackbarOptions = {
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'center',
    },
  };

  let snackbarKey = null;

  const removeSnackbar = () => {
    closeSnackbar(snackbarKey);
  };

  if (notification.groupType === 'oneToOne') {
    // one to one messaging snackbar render
    snackbarKey = enqueueSnackbar(
      <OneToOneSnackbar
        payload={notification}
        removeSnackbar={removeSnackbar}
        navigate={navigate}
        userInfo={userInfo}
      />,
      snackbarOptions
    );
  } else {
    // group messaging snackbar render
    snackbarKey = enqueueSnackbar(
      <GroupSnackbar
        payload={notification}
        removeSnackbar={removeSnackbar}
        navigate={navigate}
        userInfo={userInfo}
      />,
      snackbarOptions
    );
  }
};

export const showInAppMessagingInvitationNotification = (
  payload,
  navigate,
  userInfo
) => {
  const notification = payload.data;
  const snackbarOptions = {
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'center',
    },
  };

  let snackbarKey = null;

  const removeSnackbar = () => {
    closeSnackbar(snackbarKey);
  };

  if (notification.groupType === 'oneToOne') {
    // one to one messaging snackbar render
    snackbarKey = enqueueSnackbar(
      <OneToOneInvitationSnackbar
        payload={notification}
        removeSnackbar={removeSnackbar}
        navigate={navigate}
        userInfo={userInfo}
      />,
      snackbarOptions
    );
  } else {
    // group messaging snackbar render
    snackbarKey = enqueueSnackbar(
      <GroupInvitationSnackbar
        payload={notification}
        removeSnackbar={removeSnackbar}
        navigate={navigate}
        userInfo={userInfo}
      />,
      snackbarOptions
    );
  }
};

export const showInAppNotification = (message) => {
  const snackbarOptions = {
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'center',
    },
  };

  enqueueSnackbar(<DefaultSnackbar payload={message} />, snackbarOptions);
};

/**
 * Registers the token remotly, so that the server can send push notifications to this device
 * @param authToken
 * @param deviceToken
 * @param deviceType
 * @returns {Promise<*|undefined>}
 */
export const registerToken = async (authToken, deviceToken, deviceType) => {
  try {
    return await axiosInstance(
      REACT_APP_API_NOTIFICATION_URL,
      'post',
      `deviceTokens`,
      {
        Authorization: `Bearer ${authToken}`,
      },
      {
        deviceToken: deviceToken,
        platform: deviceType,
        language: 'fr',
      }
    );
  } catch (error) {
    console.error(error);
  }
};

export const unregisterToken = async (authToken, deviceToken) => {
  try {
    return await axiosInstance(
      REACT_APP_API_NOTIFICATION_URL,
      'delete',
      `deviceTokens/${deviceToken}`,
      {
        Authorization: `Bearer ${authToken}`,
      }
    );
  } catch (error) {
    console.error(error);
  }
};

export const getAllNotificationsByUserId = async (token, currentPage) => {
  try {
    return await axiosInstance(
      REACT_APP_API_NOTIFICATION_URL,
      'get',
      `notifications?language=fr&currentPage=${currentPage}`,
      {
        Authorization: `Bearer ${token}`,
      }
    );
  } catch (error) {
    console.error(error);
  }
};

export const updateNotification = async (token, id) => {
  try {
    return await axiosInstance(
      REACT_APP_API_NOTIFICATION_URL,
      'patch',
      `notifications/${id}`,
      {
        Authorization: `Bearer ${token}`,
      },
      {
        tapped: true,
      }
    );
  } catch (error) {
    console.error(error);
  }
};

export const getUnreadNotificationsCountByUserId = async (token) => {
  try {
    return await axiosInstance(
      REACT_APP_API_NOTIFICATION_URL,
      'get',
      `notifications/unread/count`,
      {
        Authorization: `Bearer ${token}`,
      }
    );
  } catch (error) {
    console.error(error);
  }
};

export const markAllNotificationAsRead = async (token) => {
  try {
    return await axiosInstance(
      REACT_APP_API_NOTIFICATION_URL,
      'patch',
      `notifications/readAll`,
      {
        Authorization: `Bearer ${token}`,
      }
    );
  } catch (error) {
    console.error(error);
  }
};
