import { useEffect, useState } from 'react';
import { getLeagues } from '../services/Predictor';

const useLeagues = () => {
  const [leagues, setLeagues] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const fetchLeagues = async () => {
    setLoading(true);
    const data = await getLeagues();
    setLeagues(data);
    setLoading(false);
  };

  useEffect(() => {
    fetchLeagues().catch(setError);
  }, []);

  return [leagues, loading, error];
};

export default useLeagues;
