import React, { createContext, useReducer } from 'react';

const initialHashtagState = {
  hashtag: [],
};

const actions = {
  setHashtag: (state, payload) => {
    const existingHashtag = state.hashtag.find(
      (hashtag) => hashtag._id === payload._id
    );
    if (!existingHashtag) {
      return {
        ...state,
        hashtag: [...state.hashtag, payload],
      };
    }

    return state;
  },
  removeHashtag: (state, payload) => {
    const updateHashtag = state.hashtag.filter(
      (hashtag) => hashtag._id !== payload
    );
    return {
      ...state,
      hashtag: updateHashtag,
    };
  },
};

const HashtagContext = createContext(initialHashtagState);

const reducer = (state, { payload, type }) =>
  actions[type] ? actions[type](state, payload) : state;

const HashtagContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialHashtagState);

  return (
    <HashtagContext.Provider
      value={{ hashtagDispatch: dispatch, hashtagState: state }}
    >
      {children}
    </HashtagContext.Provider>
  );
};

export { HashtagContext, HashtagContextProvider };
