import React, { useState } from 'react';
import DynamicSVG from '../../DynamicSVG';
import { useSwipeable } from 'react-swipeable';

const GroupSnackbar = ({ payload, removeSnackbar, navigate, userInfo }) => {
  const [swipeTo, setSwipeTo] = useState('');

  const handleRemoveSnackbar = () => {
    setTimeout(() => {
      removeSnackbar();
    }, 200);
  };

  const handlers = useSwipeable({
    onSwiped: (eventData) => {
      const direction = eventData.dir;
      switch (direction) {
        case 'Left':
          setSwipeTo('translate-x-[-100%]');
          handleRemoveSnackbar();
          return;
        case 'Right':
          setSwipeTo('translate-x-full');
          handleRemoveSnackbar();
          return;
        case 'Up':
          setSwipeTo('translate-y-[-100%]');
          handleRemoveSnackbar();
          return;
        case 'Down':
          setSwipeTo('translate-y-full');
          handleRemoveSnackbar();
          return;
      }
    },
  });
  const renderMessage = (payload) => {
    const userName =
      userInfo && `${userInfo.family_name}_${userInfo.given_name}`;
    // const enrichers =
    //   payload.enrichers?.length > 0 ? JSON.parse(payload.enrichers) : null;
    // const filteredEnrichers = enrichers.filter(
    //   (enricher) => enricher.target === userId
    // );

    const messageWithColor = payload.message.replace(
      /(#\w+)|(@\w+)/g,
      (match) => {
        if (match.startsWith('@') && match === `@${userName}`) {
          return `<span style="color: #0482e5; background-color: rgba(4, 130, 229, 0.1); padding: 3px; border-radius: 5px;" class="${match} mention">${match.replace(
            '_',
            ' '
          )}</span>`;
        } else {
          return match;
        }
      }
    );

    return (
      <span
        className="text-sm"
        dangerouslySetInnerHTML={{
          __html: messageWithColor.replace(/\n/g, '<br />'),
        }}
      />
    );

    // return filteredEnrichers.length > 0 ? (
    //   <p>
    //     <span
    //       style={{
    //         color: '#0482e5',
    //         backgroundColor: 'rgba(4, 130, 229, 0.1)',
    //         padding: '3px',
    //         borderRadius: '5px',
    //       }}
    //     >
    //       {senderName}
    //     </span>{' '}
    //     vous a mentionné dans sa conversation
    //   </p>
    // ) : payload.message?.length > length ? (
    //   `${payload.message?.slice(0, length)} ...`
    // ) : (
    //   payload.message
    // );
  };

  const handleClickSnackbar = () => {
    navigate(`/messaging/groups/conversation/${payload.groupId}`);
    removeSnackbar();
  };

  return (
    <div
      className={`relative gap-7 bg-white text-white cursor-pointer w-[20rem] px-2 py-4 rounded-xl ${swipeTo}`}
      style={{
        cursor: 'grab',
        transition: 'transform 0.3s ease',
      }}
      onClick={handleClickSnackbar}
      {...handlers}
    >
      <div
        className="absolute flex items-center justify-center p-2 rounded-full bg-white -top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
        style={{
          boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
          background:
            'var(--gradient-primary, linear-gradient(135deg, #017FFE 1.04%, #28F365 98.44%))',
        }}
      >
        <DynamicSVG
          src="./assets/icons/ic_dotted_message.svg"
          strokeColor="#FFFF"
          svgHeight={22}
          svgWidth={22}
        />
      </div>
      <div className="flex gap-2 px-2">
        <div className="relative flex items-center justify-center">
          {payload.groupPicture ? (
            <img
              src={payload.groupPicture}
              alt="user profile image"
              className="w-12 h-12 rounded-full object-cover"
            />
          ) : (
            <div className="flex items-center justify-center px-2 py-3 rounded-full bg-purple h-10 w-10 text-[13px]">
              {payload.groupName?.slice(0, 3).toUpperCase()}
            </div>
          )}
        </div>
        <div className="flex flex-auto items-center justify-between gap-3">
          <div>
            <p className="text-sm font-semibold text-black">
              {payload.groupName}
            </p>
            <p className="text-sm text-black font-normal">
              <span className="font-medium">{payload.senderGivenName}: </span>
              {renderMessage(payload, 40)}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GroupSnackbar;
