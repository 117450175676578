import React, { createContext, useReducer } from 'react';

const initialLikeState = {
  liked: [],
};

const actions = {
  setLiked: (state, payload) => {
    const isExist = state.liked.find((like) => like.postId === payload.postId);
    if (!isExist) {
      return {
        ...state,
        liked: [...state.liked, payload],
      };
    }

    return state;
  },
  setDisLiked: (state, payload) => {
    return {
      ...state,
      liked: state.liked.filter((like) => like.postId !== payload.postId),
    };
  },
};

const LikeContext = createContext(initialLikeState);

const reducer = (state, { payload, type }) =>
  actions[type] ? actions[type](state, payload) : state;

const LikeContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialLikeState);

  return (
    <LikeContext.Provider
      value={{ likedDispatch: dispatch, likedState: state }}
    >
      {children}
    </LikeContext.Provider>
  );
};

export { LikeContext, LikeContextProvider };
