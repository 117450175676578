import React from 'react';

export default function ConfidentialityPoliticText() {
  return (
    <div className="text-white text-sm mb-16">
      <div className="text-xl font-bold">
        Importance de la protection de votre vie privée.
      </div>
      <p>
        La mission de Sfeira est de connecter les fans, les joueurs et les
        dirigeants du football amateur afin d’accompagner le développement de ce
        sport sur les angles sportifs et financiers. Au cœur de cette mission,
        nous plaçons notre engagement de transparence concernant les données
        collectées à votre sujet, leur utilisation et les entités avec
        lesquelles nous les partageons. La présente Politique de confidentialité
        s’applique dès lors que vous avez recours à nos services (décrits
        ci-dessous).
      </p>
      <div className="text-xl font-bold">
        1. Responsable du traitement de vos données personnelles
      </div>
      Au sens du RGPD, le responsable de traitement de vos données personnelles
      est la société CC Investissements dont le siège social est situé au 2 rue
      de l’Epine, 59 650 Villeneuve d’ascq – France et enregistrée au RCS de
      Lille Métropole sous le numéro 807825989.
      <div className="text-xl font-bold">2. Services</div>
      La présente Politique de confidentialité s’applique à app.sfeire.com et au
      site www.sfeira.com, aux applications de la marque Sfeira, et à tout autre
      site, application, communication et service associé à Sfeira à l’exclusion
      des services explicitement fournis dans le cadre d’une autre politique de
      confidentialité.
      <div className="text-xl font-bold">
        3. Collecte et utilisation de vos données personnelles.
      </div>
      Pour l’inscription aux services proposés par Sfeira nous recueillons :
      <br />
      • Les données d’identification
      <br />
      • Les données personnelles.
      <br />
      Légalement par :<br />
      • L’exécution du contrat conclu entre Sfeira et l’utilisateur (Acceptation
      du contrat de CGU).
      <br />
      • Le consentement pour les cookies.
      <br />
      Délai :<br />
      • Les données sont conservées durant la durée d’utilisation active de la
      plate-forme.
      <br />
      • En cas de non-utilisation de la plateforme sur une durée supérieure à 6
      mois, nous nous réservons le droit de supprimer l’ensemble des données
      utilisateurs et de clôturer les comptes.
      <br />
      Pour la gestion des services relatifs au réseau social Sfeira nous
      recueillons:
      <br />
      • Données d’identification
      <br />
      • Données de connexion et de suivi
      <br />
      • Cookies
      <br />
      • Le contenu publié sur notre plate-forme (texte, video, photos..)
      <br />
      Délai :<br />
      • Les données générées dans le cadre d’une utilisation active des services
      relatifs à Sfeira sont conservées 12 mois.
      <br />
      • En cas de non-utilisation de la plateforme sur une durée supérieure à 6
      mois, nous nous réservons le droit de supprimer l’ensemble des données de
      services et de clôturer le compte utilisateur.
      <br />
      Pour la prévention et détection des fraudes et gestion des incidents de
      sécurité, nous recueillons :<br />
      • Données d’identification
      <br />
      • Données personnelles
      <br />
      • Données de connexion et de suivi
      <br />
      Légalement par :<br />
      • Intérêt légitime de Sfeira à lutter contre la fraude et les atteintes à
      ses systèmes d’information
      <br />
      Délai :<br />
      • 1 an à compter de la date d’acceptation des CGU.
      <br />
      Pour la gestion des éventuels litiges avec les utilisateurs :<br />
      • Données d’identification
      <br />
      • Données de contenus.
      <br />
      Légalement par :<br />
      • Intérêt légitime à faire valoir ses droits en justice
      <br />
      Délai :<br />
      • 1 an à compter de la date d’acceptation des CGU.
      <br />
      <div className="text-xl font-bold">Données d’utilisation</div>
      Nous pouvons également recueillir des informations sur la façon dont le
      Service est accédé et utilisé (« Données d’utilisation »). Ces données
      d’utilisation peuvent inclure des informations telles que l’adresse IP de
      votre ordinateur, le type de navigateur, la version du navigateur, les
      pages de notre Site que vous visitez, l’heure et la date de votre visite,
      le temps passé sur ces pages, les identificateurs uniques d’appareils et
      autres données de diagnostic d’utilisation du Service. Ces données sont
      collectées sur le fondement de notre intérêt légitime à améliorer notre
      Service afin de :<br />
      • Fournir et maintenir le Service Sfeira.
      <br />
      • Analyser l’utilisation du Service afin de l’améliorer
      <br />
      • Surveiller l’utilisation du Service
      <br />
      • Détecter, prévenir et résoudre les problèmes techniques
      <br />
      <div className="text-xl font-bold">Suivi de la navigation et cookies</div>
      Nous utilisons des cookies et des technologies de suivi similaires pour
      suivre l’activité sur notre Service et conserver certaines informations.
      Les cookies sont des fichiers contenant une petite quantité de données qui
      peuvent inclure un identifiant unique anonyme. Des cookies sont envoyés à
      votre navigateur à partir d’un site Web et stockés sur votre appareil. Les
      technologies de suivi utilisées sont également des balises, des traceurs
      et des scripts pour recueillir et suivre l’information et pour améliorer
      et analyser notre Service. Vous pouvez demander à votre navigateur de
      refuser tous les cookies ou d’indiquer quand un cookie est envoyé.
      Toutefois, si vous n’acceptez pas les cookies, il se peut que vous ne
      puissiez pas utiliser certaines parties de notre Service. Le dépôt de
      cookies est soumis à votre consentement préalable.
      <br />
      Liste des cookies que nous utilisons :<br />
      • Auth0
      <br />• Sentry
      <div className="text-xl font-bold">3. Transfert de données</div>
      Les données vous concernant listées ci-dessus peuvent faire l’objet d’un
      transfert vers nos sous-traitants en dehors de l’Union européenne. Nous
      prenons toutes les mesures raisonnablement nécessaires pour nous assurer
      que vos données sont traitées en toute sécurité et conformément à cette
      politique de confidentialité et aucun transfert de vos données
      personnelles n’aura lieu vers une organisation ou un pays à moins que des
      contrôles adéquats ne soient en place, y compris la sécurité de vos
      données et autres informations personnelles.
      <div className="text-xl font-bold">4. Sous-traitants</div>
      Dans le cadre du Service fourni, nous avons recours à plusieurs
      sous-traitants situés au sein et en dehors de l’Union européenne. Ainsi,
      vos données personnelles peuvent notamment être traitées par les
      sous-traitants suivants :<br />
      • CleverCloud
      <br />
      • Firebase
      <br />
      • Auth0
      <br />
      • SendinBlue
      <br />
      • Sentry
      <br />
      Afin de respecter votre vie privée, vos données personnelles sont
      conservées par nos sous-traitants pendant la seule durée nécessaire à la
      finalité de leur traitement.
      <div className="text-xl font-bold">5. Exercice de vos droits</div>
      Conformément aux articles 15 à 22 du règlement général sur la protection
      des données (RGPD), concernant les données que nous détenons sur vous vous
      disposez des droits suivants :<br />
      • droit d’accès (article 15 du RGPD)
      <br />
      • droit de rectification (article 16 du RGPD)
      <br />
      • droit d’effacement (article 17 du RGPD)
      <br />
      • droit à la limitation du traitement (article 18 du RGPD)
      <br />
      • droit à la portabilité des données (article 20 du RGPD)
      <br />
      • droit d’opposition (article 21 du RGPD)
      <br />
      • droit de définir des directives relatives au sort de vos données après
      votre décès
      <br />
      Chacun de ces droits fait l’objet d’une explication détaillée sur le site
      de la CNIL. Vous pouvez accéder à ces explications en cliquant sur ce lien
      :
      https://www.cnil.fr/fr/les-droits-pour-maitriser-vos-donnees-personnelles
      Chacun de ces droits peut être exercé à tout moment directement :<br />
      • par courriel: contact@sfeira.com ou en nous écrivant
      <br />
      • par voie postale au 2 rue de l’épine, 59 650, Villeneuve d’ascq -
      France.
      <br />
      En fonction de votre demande, nous pouvons être amenés à vous demander la
      copie d’une pièce d’identité afin de garantir la confidentialité de vos
      données personnelles. Vous disposez également du droit d’introduire une
      réclamation auprès de la CNIL si vous estimez que vos droits n’ont pas été
      respectés.
      <div className="text-xl font-bold">
        6. Sécurité des données personnelles
      </div>
      Pour garantir un respect optimal de votre vie privée, Sfeira fait ses
      meilleurs efforts, en prenant toutes mesures appropriées, pour assurer la
      sécurité et la confidentialité de vos données à caractère personnel en vue
      de les protéger contre toute perte, destruction accidentelle, altération
      et accès non autorisés. À ce titre, Sfeira met notamment en œuvre un
      certain nombre de mesures physiques et logiques pour garantir la
      confidentialité et l’intégrité de vos données personnelles, notamment :
      <br />
      • Contrôle de l’accès aux locaux ; 2 rue de l’épine, 59 650, Villeneuve
      d’ascq - France. - France
      <br />• Contrôle des accès aux fichiers et outils (habilitations)
    </div>
  );
}
