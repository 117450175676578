import { createContext, useReducer } from 'react';

const initalMessagingState = {
  invitationsMessages: [],
  groupsMessages: [],
  individualsMessages: [],
  unreadMessagesNumber: 0,
};

const actions = {
  setInvitationsMessages: (state, payload) => {
    const existingIndex = state.invitationsMessages.findIndex(
      (item) => item.groupId === payload.groupId
    );

    if (existingIndex === -1) {
      // if the message doesn't exist, we add it
      return {
        ...state,
        invitationsMessages: [...state.invitationsMessages, payload],
      };
    }

    // if the message already exist,
    // we replace actual the message with the new message
    const updatedMessages = [...state.invitationsMessages];
    updatedMessages[existingIndex] = payload;

    return {
      ...state,
      invitationsMessages: updatedMessages,
    };
  },

  setGroupsMessages: (state, payload) => {
    const existingIndex = state.groupsMessages.findIndex(
      (item) => item.groupId === payload.groupId
    );

    if (existingIndex === -1) {
      // if the message doesn't exist, we add it
      return {
        ...state,
        groupsMessages: [...state.groupsMessages, payload],
      };
    }

    // if the message already exist,
    // we replace actual the message with the new message
    const updatedMessages = [...state.groupsMessages];
    updatedMessages[existingIndex] = payload;

    return {
      ...state,
      groupsMessages: updatedMessages,
    };
  },

  setIndividualsMessages: (state, payload) => {
    const existingIndex = state.individualsMessages.findIndex(
      (item) => item.groupId === payload.groupId
    );

    if (existingIndex === -1) {
      // if the message doesn't exist, we add it
      return {
        ...state,
        individualsMessages: [...state.individualsMessages, payload],
      };
    }

    // if the message already exist,
    // we replace actual the message with the new message
    const updatedMessages = [...state.individualsMessages];
    updatedMessages[existingIndex] = payload;

    return {
      ...state,
      individualsMessages: updatedMessages,
    };
  },

  resetMessagesList: (state) => {
    return {
      ...state,
      invitationsMessages: [],
      groupsMessages: [],
      individualsMessages: [],
    };
  },

  setUnreadMessagesNumber: (state, payload) => {
    return {
      ...state,
      unreadMessagesNumber: payload,
    };
  },

  removeGroupFromDiscussionList: (state, payload) => {
    const existingIndex = state.groupsMessages.findIndex(
      (item) => item.groupId === payload
    );
    if (existingIndex >= 0) {
      const newGroupList = state.groupsMessages.filter(
        (group) => group.groupId != payload
      );
      return {
        ...state,
        groupsMessages: newGroupList,
      };
    }
    return state;
  },

  updateGroupsMessages: (state, payload) => {
    // console.log('payload: ', payload);

    const existingIndex = state.groupsMessages.findIndex(
      (item) => item.groupId === payload.groupId
    );

    const updatedMessages = [...state.groupsMessages];

    if (existingIndex === -1) {
      updatedMessages.push(payload);
    } else {
      updatedMessages[existingIndex] = payload;
    }
    // console.log('state updated: ', updatedMessages);
    return {
      ...state,
      groupsMessages: updatedMessages,
    };
  },

  // removeMSGFromGroup: (state, payload) => {},
};

const MessagingContext = createContext(initalMessagingState);

const reducer = (state, { payload, type }) => {
  if (actions[type]) {
    return actions[type](state, payload);
  }
  return state;
};

const MessagingContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initalMessagingState);

  return (
    <MessagingContext.Provider
      value={{ MessagingDispatch: dispatch, messagingState: state }}
    >
      {children}
    </MessagingContext.Provider>
  );
};

export { MessagingContext, MessagingContextProvider };
