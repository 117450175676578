import { useState } from 'react';
import { createRelation as createRelationService } from '../../../services/Relation';

const useCreateRelation = () => {
  const [isCreatingRelation, setIsCreatingRelation] = useState(false);
  const [isRelationCreated, setIsRelationCreated] = useState(false);

  const createRelation = async (auth0Token, data) => {
    try {
      setIsCreatingRelation(true);
      await createRelationService(data, auth0Token);
      setIsCreatingRelation(false);
      setIsRelationCreated(true);
    } catch (e) {
      setIsCreatingRelation(true);
      setIsRelationCreated(false);
      console.error(e);
    }
  };

  return { createRelation, isCreatingRelation, isRelationCreated };
};

export default useCreateRelation;
