import React, { useEffect, useState } from 'react';
import axios from 'axios';
import MainHeader from '../../layouts/main/MainHeader';
import { useParams } from 'react-router';
import { useAuth0 } from '@auth0/auth0-react';
import DynamicSVG from '../../components/DynamicSVG';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Image from './component/image-background';

const QuizzPodium = () => {
  const [quizDetails, setQuizDetails] = useState([]);
  const [ranking, setRanking] = useState([]);
  const [rankingData, setRankingData] = useState([]);
  const [defi, setDefi] = useState([]);
  // const [scoresCo, setScoresCo] = useState([]);
  const [first, setFirst] = useState([]);

  const { user } = useAuth0();
  const userId = user.sub;

  const { typeJeu } = useParams();
  const findScoreById = (idUser, scores) => {
    const index = scores.findIndex((score) => score.user === idUser);
    if (index > -1) {
      return scores[index].score;
    }
  };
  const comparaison = (score_auth, scores_other) => {
    const different = score_auth - scores_other;
    if (isNaN(score_auth) && isNaN(scores_other)) {
      return {
        type: 'En attente',
        different: 0,
        bg: 'bg-[#E42F5A] w-[80px] h-6 rounded-2xl text-center',
      };
    }
    if (different > 0) {
      // console.log(different, 'difdif');
      return {
        type: 'Victoire ',
        different,
        bg: 'bg-[#E42F5A] w-[80px] h-6 rounded-2xl  text-center',
        img: `${process.env.REACT_APP_AUTH0_REDIRECT_URI}/assets/images/win2.png`,
        styleimg: 'w-12',
      };
    }
    return {
      type: 'Defaite',
      different,
      bg: 'bg-[#27C089] w-[80px] h-6 rounded-2xl  text-center',
      img: `${process.env.REACT_APP_AUTH0_REDIRECT_URI}/assets/images/lose2.png`,
      styleimg: 'w-8',
    };
  };

  const fetchQuizDefi = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BACK_OFFICE_URL}quiz/challenge-ranking/${userId}`
        // `http://localhost:8080/quiz/challenge-ranking/${userId}`
      );

      setDefi(response.data);
    } catch (error) {
      console.error('Erreur de récupération des quiz avec amis:', error);
    }
  };

  const fetchDataSolo = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BACK_OFFICE_URL}quiz/quiz-details/${userId}`
        // `http://localhost:8080/quiz/quiz-details/${userId}`
      );
      setQuizDetails(response.data.result);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchRankingThemeData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BACK_OFFICE_URL}quiz/quiz-theme/${userId}`
        // `http://localhost:8080/quiz/quiz-theme/${userId}`
      );
      setRankingData(response.data.result);
    } catch (error) {
      console.error('Erreur lors de la récupération du classement :', error);
    }
  };

  const fetchGlobalRanking = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BACK_OFFICE_URL}quiz/globalRanking`
        // 'http://localhost:8080/quiz/globalRanking'
      );
      let datas = response.data.ranking.map((item, key) => {
        return { item, ...(item.rang = key + 1) };
      });
      const data_copie = [...datas];
      data_copie.sort((a, b) => {
        return a.rang - b.rang;
      });
      setFirst(data_copie);
      const user_auth = datas.filter((item) => userId === item.item._id.user);
      const index = datas.findIndex((item) => userId === item.item._id.user);
      let tmp = datas[0];
      datas[0] = user_auth[0];
      datas[index] = tmp;
      setRanking(datas);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (typeJeu == 'défi') {
      fetchQuizDefi();
    } else if (typeJeu == 'solo') {
      fetchDataSolo();
    }
  }, [userId]);

  useEffect(() => {
    if (typeJeu == 'global') {
      fetchGlobalRanking();
    } else if (typeJeu == 'thème') {
      fetchRankingThemeData();
    }
  }, []);

  const levelImg = (quiz) => {
    if (quiz.niveauDetails.questlevel === 1) {
      return {
        imageSrc: `${process.env.REACT_APP_AUTH0_REDIRECT_URI}/assets/images/facile.png`,
        text: 'Facile',
      };
    } else if (quiz.niveauDetails.questlevel === 2) {
      return {
        imageSrc: `${process.env.REACT_APP_AUTH0_REDIRECT_URI}/assets/images/moyen.png`,
        text: 'Moyen',
      };
    } else if (quiz.niveauDetails.questlevel === 3) {
      return {
        imageSrc: `${process.env.REACT_APP_AUTH0_REDIRECT_URI}/assets/images/difficile.png`,
        text: 'Difficile',
      };
    }
  };

  const porcentage = (item) => {
    return {
      porcentageRes: (item.totalScore * 100) / (item.totalQuizLevels * 350),
    };
  };

  const getColor = (percentage) => {
    if (percentage < 40) {
      return { bg: '#E42F5A', bg2: '#422547' }; // Rouge si le pourcentage est inférieur à 40%
    } else if (percentage < 70) {
      return { bg: '#018FFB', bg2: '#153967' }; // Bleu si le pourcentage est inférieur à 70%
    } else {
      return { bg: '#27C089', bg2: '#1d4250' }; // Vert sinon
    }
  };

  return (
    <div className="w-full">
      <Image />
      <MainHeader items={2} leftText={`Classement ${typeJeu}`} />
      <ul>
        {typeJeu === 'global' && (
          <div className="h-screen">
            <div className="flex items-end justify-center">
              <div className="bg-[#1A2342] rounded-lg h-32 w-28 py-6 flex flex-col items-center text-white">
                <img
                  src={
                    first[1]?.item?.userPhoto?.[0]?.L ||
                    process.env.REACT_APP_AUTH0_REDIRECT_URI +
                      './assets/images/img_default_profile.png'
                  }
                  alt="avatar"
                  className="w-12 h-12 rounded-full  object-cover border-4 border-[#009BD6]"
                />
                <div className="bg-[#009BD6] w-4 rounded-tl-xl rounded-bl-2xl rounded-tr-xl rounded-br-2xl -mt-2 text-center">
                  2
                </div>
                <span className="text-xs">
                  {first[1]?.item?.userName} {first[1]?.item?.Name}
                </span>
                <div className="text-xs">
                  {Math.round(first[1]?.item?.totalScore)} points
                </div>
              </div>
              <div className="bg-[#0b1025] rounded-lg h-36 w-28 py-2 flex flex-col items-center text-white">
                <img
                  src={
                    first[0]?.item?.userPhoto?.[0]?.L ||
                    process.env.REACT_APP_AUTH0_REDIRECT_URI +
                      './assets/images/img_default_profile.png'
                  }
                  alt="avatar"
                  className="w-12 h-12 rounded-full object-cover border-4 border-[#FFAA00]"
                />
                <div className="bg-[#FFAA00] w-4 rounded-2xl -mt-2 text-center">
                  1
                </div>
                <span className="text-xs">
                  {first[0]?.item?.userName} {first[0]?.item?.Name}
                </span>
                <div className="text-xs">
                  {Math.round(first[0]?.item?.totalScore)} points
                </div>
              </div>
              <div className="bg-[#1A2342] rounded-lg h-32 w-28 py-6 flex flex-col items-center text-white">
                <img
                  src={
                    first[2]?.item?.userPhoto?.[0]?.L ||
                    process.env.REACT_APP_AUTH0_REDIRECT_URI +
                      './assets/images/img_default_profile.png'
                  }
                  alt="avatar"
                  className="w-12 h-12 rounded-full object-cover border-4 border-[#00D95F]"
                />
                <div className="bg-[#00D95F] w-4 rounded-2xl -mt-2 text-center">
                  3
                </div>
                <span className="text-xs">
                  {first[2]?.item?.userName} {first[2]?.item?.Name}
                </span>
                <div className="text-xs">
                  {Math.round(first[2]?.item?.totalScore)} points
                </div>
              </div>
            </div>
            {ranking.map((user, index) => (
              <li
                key={index}
                className={
                  index == 0
                    ? 'rounded-md  border border-solid border-[#017FFE] bg-gradient-to-br from-[#017FFE] to-[#28F365] my-2 text-white flex items-center gap-2.5 py-2'
                    : 'rounded-md bg-[#1A2342] my-2 text-white flex items-center gap-2.5 py-2'
                }
              >
                <div className="ml-2 font-semibold">{user?.item?.rang}</div>
                <div className="flex flex-auto gap-2.5 items-center">
                  <img
                    src={
                      user?.item?.userPhoto?.[0]?.L ||
                      process.env.REACT_APP_AUTH0_REDIRECT_URI +
                        './assets/images/img_default_profile.png'
                    }
                    alt="avatar"
                    className="w-10 h-10 ml-2 rounded-full object-cover"
                  />
                  <div className="text-white text-sm font-semibold">
                    {user?.item?.userName} {user?.item?.Name}
                  </div>
                </div>

                <div className="ml-auto flex items-center text-xs font-medium mr-3">
                  <img
                    src={
                      process.env.REACT_APP_AUTH0_REDIRECT_URI +
                      '/assets/images/Vector.png'
                    }
                    alt="Image"
                    className="rounded-none w-4 h-4 mr-1"
                  />
                  + {Math.round(user?.item?.totalScore)}
                </div>
              </li>
            ))}
          </div>
        )}
        {typeJeu === 'thème' && (
          <div>
            {rankingData.map((item, index) => (
              <li
                key={index}
                className="bg-[#1A2342] rounded-lg text-white flex items-center my-2 py-2"
              >
                <DynamicSVG
                  src="./assets/icons/ic_question.svg"
                  className="ml-2"
                />
                <p className="text-white pl-6">{item.theme.nomTheme}</p>
                <div className="ml-auto">
                  <CircularProgressbar
                    value={porcentage(item).porcentageRes}
                    text={`${Math.round(porcentage(item)?.porcentageRes)}`}
                    className="w-11 h-11 mr-3"
                    styles={{
                      root: {}, // Ne modifiez pas le style de base
                      path: {
                        stroke: getColor(porcentage(item)?.porcentageRes).bg,
                      }, // Couleur de la barre de progression
                      trail: {
                        stroke: getColor(porcentage(item)?.porcentageRes).bg2,
                      }, // Ne pas afficher la piste
                      text: {
                        fill: getColor(porcentage(item)?.porcentageRes).bg,
                        fontSize: '30px',
                        fontWeight: 'bold',
                      }, // Couleur et taille du texte
                    }}
                  />
                </div>
              </li>
            ))}
          </div>
        )}
        {typeJeu === 'solo' && (
          <div>
            {quizDetails.map((quiz, index) => (
              <li
                key={index}
                className="bg-[#1A2342] rounded-lg my-2 p-2.5 flex justify-between items-center"
              >
                {/* <div> */}
                <DynamicSVG
                  src="./assets/icons/ic_question.svg"
                  className=" "
                />
                <div className="p-2">
                  <div className="text-white truncate w-40">
                    {quiz.themeDetails.nomTheme}
                  </div>
                  <div className="flex justify-between">
                    <div className="flex items-center text-xs text-white">
                      {/* <li className="text-grey text-xs pl-16 flex items-stretch "> */}{' '}
                      <img
                        src={
                          process.env.REACT_APP_AUTH0_REDIRECT_URI +
                          '/assets/images/mdi_calendar-outline.png'
                        }
                        alt="Image"
                        className="rounded-none w-4 h-4 mr-2"
                      />
                      {quiz.themeDetails.periode}
                    </div>
                    <div className="flex items-center text-xs text-white">
                      <img
                        src={levelImg(quiz).imageSrc}
                        alt="Image"
                        className="rounded-none w-3 h-3 mr-2"
                      />
                      {levelImg(quiz).text}
                    </div>
                    {/* </li> */}
                  </div>
                </div>
                <div className="flex items-center text-xs text-white ml-auto mr-2 w-30">
                  <img
                    src={
                      process.env.REACT_APP_AUTH0_REDIRECT_URI +
                      '/assets/images/Vector.png'
                    }
                    alt="Image"
                    className="rounded-none w-4 h-4 mr-1"
                  />
                  <span>
                    {isNaN(Math.round(quiz.quiz.scores[0]?.score))
                      ? 0
                      : Math.round(quiz.quiz.scores[0]?.score)}{' '}
                    points
                  </span>
                </div>
              </li>
            ))}
          </div>
        )}
        {typeJeu === 'défi' && (
          <div>
            {defi.map((quiz, index) => (
              <div
                key={index}
                className="flex items-center justify-evenly  bg-[#1A2342] rounded-lg mt-2  py-2"
              >
                <div className="flex flex-col items-center my-2 w-12 text-white text-xs font-semibold">
                  <>
                    <img
                      src={
                        comparaison(
                          findScoreById(quiz?.players[0]?.user_id, quiz.scores),
                          findScoreById(quiz?.players[1]?.user_id, quiz.scores)
                        )?.img
                      }
                      className={
                        comparaison(
                          findScoreById(quiz?.players[0]?.user_id, quiz.scores),
                          findScoreById(quiz?.players[1]?.user_id, quiz.scores)
                        )?.styleimg
                      }
                    />
                  </>
                  <>
                    {
                      comparaison(
                        findScoreById(quiz?.players[0]?.user_id, quiz.scores),
                        findScoreById(quiz?.players[1]?.user_id, quiz.scores)
                      )?.type
                    }
                    {/* : findScoreById(quiz?.players[0]?.user_id, quiz.scores)} */}
                  </>
                </div>
                <div className="flex flex-col items-center my-1">
                  <img
                    src={
                      quiz?.players[0]?.user_metadata?.profile_picture_flavors
                        ?.S ||
                      process.env.REACT_APP_AUTH0_REDIRECT_URI +
                        './assets/images/img_default_profile.png'
                    }
                    alt="avatar"
                    className="w-10 h-10 rounded-full object-cover"
                  />
                  <span className="text-white text-xs">
                    {' '}
                    {quiz?.players[0]?.user_metadata?.given_name}
                  </span>
                </div>

                <div>
                  <p className="text-white text-sm font-medium">VS</p>
                </div>
                <div className="flex -space-x-14 overflow-hidden">
                  {/* {quiz?.players.slice(1).map(({quiz?.players[0], index) => ( */}
                  <div className="flex items-center">
                    <div className="flex flex-col items-center my-1">
                      <img
                        src={
                          quiz?.players[1]?.user_metadata
                            ?.profile_picture_flavors?.S ||
                          process.env.REACT_APP_AUTH0_REDIRECT_URI +
                            './assets/images/img_default_profile.png'
                        }
                        alt="avatar"
                        className="w-10 h-10 rounded-full object-cover"
                      />
                      <span className="text-white text-xs">
                        {quiz?.players[1]?.user_metadata?.given_name}
                      </span>
                    </div>
                    <div className="custom-button-point !text-xl font-medium w-14 ml-4">
                      {
                        comparaison(
                          findScoreById(quiz?.players[0]?.user_id, quiz.scores),
                          findScoreById(quiz?.players[1]?.user_id, quiz.scores)
                        )?.different
                      }
                      {/* {findScoreById(
                          {quiz?.players[0].{quiz?.players[0]Details.user_id,
                          quiz.scores
                        )} */}
                    </div>
                  </div>
                  {/* ))} */}
                </div>
              </div>
            ))}
          </div>
        )}
      </ul>
    </div>
  );
};

export default QuizzPodium;
