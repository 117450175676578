import React, { useState } from 'react';
import DynamicSVG from '../../DynamicSVG';
import { useSwipeable } from 'react-swipeable';

const OneToOneSnackbar = ({ payload, removeSnackbar, navigate }) => {
  const [swipeTo, setSwipeTo] = useState('');

  const handleRemoveSnackbar = () => {
    setTimeout(() => {
      removeSnackbar();
    }, 200);
  };

  const handlers = useSwipeable({
    onSwiped: (eventData) => {
      const direction = eventData.dir;
      switch (direction) {
        case 'Left':
          setSwipeTo('translate-x-[-100%]');
          handleRemoveSnackbar();
          return;
        case 'Right':
          setSwipeTo('translate-x-full');
          handleRemoveSnackbar();
          return;
        case 'Up':
          setSwipeTo('translate-y-[-100%]');
          handleRemoveSnackbar();
          return;
        case 'Down':
          setSwipeTo('translate-y-full');
          handleRemoveSnackbar();
          return;
      }
    },
  });

  const renderMessage = (message, length) => {
    return message?.length > length
      ? `${message?.slice(0, length)} ...`
      : message;
  };

  const handleClickSnackbar = () => {
    navigate(`/messaging/messages/conversation/${payload.groupId}`);
    removeSnackbar();
  };

  return (
    <div
      className={`relative gap-7 bg-white text-white cursor-pointer w-[20rem] px-2 py-4 rounded-xl ${swipeTo}`}
      style={{
        cursor: 'grab',
        transition: 'transform 0.3s ease',
      }}
      onClick={handleClickSnackbar}
      {...handlers}
    >
      <div
        className="absolute flex items-center justify-center p-2 rounded-full bg-white -top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
        style={{
          boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
          background:
            'var(--gradient-primary, linear-gradient(135deg, #017FFE 1.04%, #28F365 98.44%))',
        }}
      >
        <DynamicSVG
          src="./assets/icons/ic_dotted_message.svg"
          strokeColor="#FFFF"
          svgHeight={22}
          svgWidth={22}
        />
      </div>
      <div className="flex gap-2 px-2">
        <div className="relative  flex items-center justify-center">
          {payload.senderPicture ? (
            <img
              src={payload.senderPicture}
              alt="user profile image"
              className="w-10 h-10 rounded-full object-cover"
            />
          ) : (
            <div className="px-2 py-3 rounded-full bg-purple h-10 w-10 text-center text-[13px]">
              {payload.senderFamilyName?.slice(0, 1).toUpperCase()}
              {payload.senderGivenName?.slice(0, 1).toUpperCase()}
            </div>
          )}
        </div>
        <div className="flex flex-auto items-center justify-between gap-3">
          <div>
            <p className="text-sm font-semibold text-black">
              {payload.senderFamilyName}
            </p>
            <p className="text-sm text-black font-normal">
              {renderMessage(payload.message, 30)}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OneToOneSnackbar;
