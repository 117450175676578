import React from 'react';
import DynamicSVG from '../../components/DynamicSVG';
import { DEFAULT_SVG_STROKE } from '../../utils/svgColor';
import { isIos } from '../../utils/iOS';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

const OneChamp = ({ selected = false, onClick, data }) => {
  const { attributes, listeners, setNodeRef, transform } = useSortable({
    id: data?.externalId,
  });
  const style = {
    transform: CSS.Translate.toString(transform),
  };
  return (
    <div
      style={style}
      className="flex justify-between bg-primaryLight-color p-3 rounded-lg"
    >
      <div className="flex gap-2">
        {selected && (
          <div
            ref={setNodeRef}
            {...listeners}
            {...attributes}
            className="flex items-center touch-none"
          >
            <img
              src="/assets/icons/ic_drag_indicator.svg"
              className="w-4 h-4 rounded-3xl"
            />
          </div>
        )}
        <div className="flex gap-2">
          {!isIos() && <img src={data?.logo} className="w-8 h-8 rounded-3xl" />}
          <div className="flex flex-col">
            <p className="font-bold text-white text-xs">{data?.name}</p>
            <p className="text-primaryDark-stroke text-xs">{data?.country}</p>
          </div>
        </div>
      </div>
      <div className="flex items-center">
        <DynamicSVG
          src={`${
            selected
              ? '../assets/icons/ic_favorite_gold.svg'
              : '../assets/icons/ic_favorite_white.svg'
          }`}
          className="cursor-pointer"
          onClick={onClick}
          strokeColor={DEFAULT_SVG_STROKE}
        />
      </div>
    </div>
  );
};

export default OneChamp;
