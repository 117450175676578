import React, { createContext, useContext, useEffect, useState } from 'react';

const LngContext = createContext();

export const LngProvider = ({ children }) => {
  const [isShowLng, setIsShowLng] = useState(false);
  const localS = localStorage.getItem('isShowLng');

  useEffect(() => {
    if (localS === 'true') {
      setIsShowLng(true);
    } else {
      setIsShowLng(false);
    }
  }, []);

  return (
    <LngContext.Provider value={{ isShowLng, setIsShowLng }}>
      {children}
    </LngContext.Provider>
  );
};

export const useLng = () => {
  return useContext(LngContext);
};
