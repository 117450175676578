import React /* , { useRef } */ from 'react';
// import { HelmetProvider } from 'react-helmet-async';
import { Helmet } from 'react-helmet-async';
// import html2canvas from 'html2canvas';

const LienPartager = () => {
  return (
    <div
      /* ref={componentRef}  */ className="bg-[#D9D9D9] rounded-lg shadow-lg p-4"
    >
      <Helmet>
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Sfeira : L'application 100% sport" />
        <meta
          property="og:description"
          content="Rejoins moi sur l'app Sfeira pour participer au quiz 100% foot auquel je viens de te défier ! Relèveras-tu le challenge ? Télécharge l'app et réglons ça sur le terrain !"
        />
        <meta
          property="og:image"
          content="https://sfeira-web-staging.cleverapps.io/logo32.png"
        />
      </Helmet>
      <div>
        <img
          src="https://sfeira-web-staging.cleverapps.io/logo32.png"
          alt="sfeira"
          className="w-full h-auto rounded-lg shadow-lg"
        />
        <h2>Sfeira : l'application 100% sport</h2>
      </div>
      <ul className="mt-4 text-center">
        <li>
          Rejoins moi sur l’app Sfeira pour participer au quiz 100% foot auquel
          je viens de te défier !{' '}
        </li>
        <li>Relèveras-tu le challenge ?</li>
        <li>Télécharge l’app et réglons ça sur le terrain !</li>
      </ul>
    </div>
  );
};

export default LienPartager;
