import React from 'react';

const DynamicSVG = ({
  src,
  className,
  style,
  svgWidth,
  svgHeight,
  strokeColor,
  fillColor,
  ...others
}) => {
  const svgRef = React.useRef(null);

  React.useEffect(() => {
    const svgElement = svgRef.current;
    const xhr = new XMLHttpRequest();
    xhr.open('GET', process.env.PUBLIC_URL + '/' + src, true);
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4 && xhr.status === 200) {
        const svgContent = xhr.responseText;
        if (svgElement) {
          svgElement.innerHTML = svgContent;
          // Update size
          const svg = svgElement.querySelector('svg');
          if (svgWidth) {
            svg.setAttribute('width', svgWidth);
          }
          if (svgHeight) {
            svg.setAttribute('height', svgHeight);
          }

          // Manipulate the stroke color
          const paths = svgElement.querySelectorAll('path');
          paths.forEach((path) => {
            path.setAttribute('stroke', strokeColor);
            if (fillColor) {
              path.setAttribute('fill', fillColor);
            }
          });
        }
      }
    };
    xhr.send();
  }, [src, strokeColor, fillColor, svgWidth, svgHeight]);

  return <div className={className} style={style} ref={svgRef} {...others} />;
};

export default DynamicSVG;
