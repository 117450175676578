import React from 'react';

import { Link } from 'react-router-dom';

const QuizzResult = () => {
  return (
    <div className="h-screen">
      <div className="">
        <img
          src={process.env.REACT_APP_API_ACTIVITY_URL + '/logo.png'}
          alt="Image"
          className="m-auto "
          style={{
            width: '198.93px',
            height: '160px',
            padding: '2.58px 0 0 0',
          }}
        />
      </div>
      <div className="button-container-result">
        <h2 className="text-center">
          Félicitation vous avez gagné le challenge
        </h2>
        <h4 className="custom-button-point">Vous avez +200 points quiz</h4>
        <div className="py-5">
          <button className="bg-white py-4 px-4 rounded-3xl font-bold text-xs">
            {' '}
            <Link to="/quizz/quizz-podium">Voir les réponses correcte</Link>
          </button>
        </div>
      </div>
      <div className="container-result">
        <div className="box-result">
          <h5>CORRECT ANSWER</h5>
          <label>7</label>
        </div>
        <div className="box-result">
          <h5>COMPLETION</h5>
          <label>80%</label>
        </div>
        <div className="box-result">
          <h5>SKIPPED</h5>
          <label>0</label>
        </div>
        <div className="box-result">
          <h5>INCORRECT ANSWER</h5>
          <label>3</label>
        </div>
      </div>
      <div>
        <div className="flex justify-around">
          <button className="bg-[#018FFB] rounded-full font-bold text-white py-3.5 px-8 w-40 text-xs">
            Terminer
          </button>
          <button className="rounded-full font-bold text-white py-3.5 px-8 w-40 border border-white text-xs">
            Partager
          </button>
        </div>
      </div>
    </div>
  );
};

export default QuizzResult;
