export const USER_ROLES = {
  ADMIN: 'ADMIN',
  CLUB: 'CLUB',
  PLAYER: 'PLAYER',
  FAN: 'FAN',
  CONTROLLER: 'CONTROLLER',
};

const roleHierarchy = {
  [USER_ROLES.ADMIN]: [USER_ROLES.ADMIN],
  [USER_ROLES.FAN]: [USER_ROLES.ADMIN, USER_ROLES.FAN],
  [USER_ROLES.CLUB]: [USER_ROLES.ADMIN, USER_ROLES.CLUB],
  [USER_ROLES.PLAYER]: [USER_ROLES.ADMIN, USER_ROLES.PLAYER],
  [USER_ROLES.CONTROLLER]: [USER_ROLES.ADMIN, USER_ROLES.CONTROLLER],
};

const checkRole = (currentRole, excludeRoleHierarchy = false, rolesToCheck) => {
  const safeRequiredRoles = Array.isArray(rolesToCheck)
    ? rolesToCheck
    : [rolesToCheck];
  if (excludeRoleHierarchy) return safeRequiredRoles.includes(currentRole);
  return safeRequiredRoles.some((requiredRole) =>
    roleHierarchy[requiredRole].includes(currentRole)
  );
};

export { checkRole };
export default USER_ROLES;
