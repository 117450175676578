/**
 * This service is 'private' and should not be called by other classes than './Notification'
 */
import {
  deleteToken,
  getMessaging,
  getToken,
  onMessage,
} from 'firebase/messaging';
import { INVITATION_MESSAGE, NEW_MESSAGE } from '../../constants/notifications';
import { firebaseApp } from '../../utils/firebaseConfig';
import {
  registerToken,
  showInAppMessagingInvitationNotification,
  showInAppMessagingNotification,
  showInAppNotification,
  unregisterToken,
} from './Notification';

// the firebase app
let messaging;

let user = null;

export const messageListener = async (callback, navigate) => {
  onMessage(messaging, (payload) => {
    const location = window.location.href;
    const splitedLocation = location.split('/');
    const restrictedLocation = splitedLocation[3] === 'messaging';

    if (callback) callback();

    // show personnalized notification banner for message notification
    if (payload.data.notificationType === NEW_MESSAGE) {
      if (!restrictedLocation)
        return showInAppMessagingNotification(payload, navigate, user);
    } else if (payload.data.notificationType === INVITATION_MESSAGE) {
      if (!restrictedLocation)
        return showInAppMessagingInvitationNotification(
          payload,
          navigate,
          user
        );
    } else {
      // show normal notification banner for other notifications
      return showInAppNotification(payload.notification.body, navigate, user);
    }
  });
};

export const checkAndRequestPermission = async () => {
  try {
    console.log('Initializing push notifications');

    // Commented due to hexa games project

    // let permStatus = await Notification?.requestPermission();

    // if (permStatus !== 'granted') {
    //   showInAppNotification(
    //     'Veuillez autoriser Sfeira à vous envoyer des notifications pour les recevoir'
    //   );
    //   throw new Error('User denied permissions!');
    // }

    console.log('User allowed permission');
    return true;
  } catch (error) {
    console.error('Error in checkAndRequestPermission:', error);
    return false;
  }
};

export const registerDevice = async (
  authToken,
  deviceType,
  callback,
  navigate,
  userInfo
) => {
  console.log('Registering web device');
  user = userInfo;

  try {
    // push notification listener
    if (!messaging) {
      messaging = getMessaging(firebaseApp);
      await messageListener(callback, navigate);
    }

    const deviceToken = await getToken(messaging, {
      vapidKey: process.env.REACT_APP_VAPIDKEY,
    });
    if (deviceToken) {
      await registerToken(authToken, deviceToken, deviceType);
      return deviceToken;
    } else {
      console.log('Failed for getting device token');
      return null;
    }
  } catch (err) {
    console.log('Error on getting device token: ' + err);
  }
};

export const unregisterDevice = async (authToken, deviceToken) => {
  if (!authToken || !deviceToken) {
    console.error('Invalid authToken or deviceToken provided');
    return;
  }

  try {
    await deleteToken(messaging);
    await unregisterToken(authToken, deviceToken);
  } catch (error) {
    console.error('Error during unregisterDevice:', error);
    // Propager l'erreur à l'appelant si nécessaire
    throw error;
  }
};
