import { useAuth0 } from '@auth0/auth0-react';
import { Browser } from '@capacitor/browser';
import { useEffect, useState } from 'react';

const REACT_APP_AUTH0_REDIRECT_URI = process.env.REACT_APP_AUTH0_REDIRECT_URI;
const useFetchAuth0Token = () => {
  const [auth0Token, setAuth0Token] = useState(null);
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

  useEffect(() => {
    const fetchAuth0Token = async () => {
      try {
        setAuth0Token(await getAccessTokenSilently());
      } catch (error) {
        console.error(error);
        if (error.error === 'invalid_grant') {
          loginWithRedirect({
            appState: {
              returnTo: REACT_APP_AUTH0_REDIRECT_URI,
            },
            async openUrl(url) {
              // Redirect using Capacitor's Browser plugin
              await Browser.open({
                url,
                windowName: '_self',
              });
            },
          });
        }
      }
    };

    fetchAuth0Token();
  });

  return { auth0Token };
};

export default useFetchAuth0Token;
