const path = (root, subLink) => `${root}${subLink}`;

const ROOTS = '/';
const ROOTS_AUTH = '/auth';

const PATH_AUTH = {
  login: path(ROOTS_AUTH, '/login'),
};

const BASE_PATH = {
  general: {},
  root: ROOTS,
};
const POST_PATH = {
  create: path(ROOTS, 'create-post'),
  root: ROOTS,
};

export { BASE_PATH, PATH_AUTH, POST_PATH };
