import { useEffect, useState } from 'react';

const UseDeviceTypeChecker = () => {
  const [deviceType, setDeviceType] = useState('');

  useEffect(() => {
    const userAgent = navigator.userAgent;

    if (userAgent.match(/Android/i)) {
      setDeviceType('Android');
    } else if (userAgent.match(/iPhone|iPad|iPod/i)) {
      setDeviceType('iOS');
    } else {
      setDeviceType('Web');
    }
  }, []);

  return deviceType;
};

export default UseDeviceTypeChecker;
