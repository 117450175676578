import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import enJSON from './locale/en.json';
import frJSON from './locale/fr.json';
import ptJSON from './locale/pt.json';

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(
    {
      resources: {
        en: { ...enJSON },
        pt: { ...ptJSON },
        fr: { ...frJSON },
      },
      detection: {
        order: [
          'querystring',
          'cookie',
          'localStorage',
          'navigator',
          'htmlTag',
          'path',
          'subdomain',
        ],
        caches: ['localStorage', 'cookie'],
      },
      fallbackLng: 'en', // Fallback to English, Portuguese, or French if detected language is not supported
      interpolation: {
        escapeValue: false, // React already safes from xss
      },
    },
    (err, t) => {
      if (err) return console.error('i18next init failed', err);
      console.log('i18next initialized', t);
      const detectedLanguage = i18next.language;
      console.log('Detected language:', detectedLanguage);

      // Optionally, set the detected language as the fallback if it's supported
      if (['en', 'pt', 'fr'].includes(detectedLanguage)) {
        i18next.fallbackLng = detectedLanguage;
      }
    }
  );
