import React, { Suspense, lazy } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';
import USER_ROLES from '../constants/roles';
import AuthGuard from '../guards/AuthGuard';
import RoleBasedGuard from '../guards/RoleBasedGuard';
import FavoriteChamps from '../pages/livescore/FavoriteChamps';
import DocumentsSetting from '../pages/profile/settings/DocumentsSetting';
import ConfidentialityPolitic from '../pages/profile/settings/document/ConfidentialityPolitic';
import GeneralTermsOfUse from '../pages/profile/settings/document/GeneralTermsOfUse';
import LegalNotice from '../pages/profile/settings/document/LegalNotice';
import QuizzChallengeAmi from '../pages/quizz/QuizzChallengeAmi';
import QuizzHistorique from '../pages/quizz/QuizzHistorique';
import QuizzList from '../pages/quizz/QuizzList';
import QuizzPodium from '../pages/quizz/QuizzPodium';
import QuizzQuestion from '../pages/quizz/QuizzQuestion';
import QuizzResult from '../pages/quizz/QuizzResult';
import QuizzStartDefi from '../pages/quizz/QuizzStartDefi';
import { POST_PATH } from './path';
// import QuizzMain from '../pages/quizz/QuizzMain';
import ChoixQuizz from '../pages/quizz/ChoixQuizz.js';
import Classement from '../pages/quizz/Classement.js';
import ListeDetails from '../pages/quizz/ListeDetails.js';
import Partager from '../pages/quizz/Partager.js';

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const location = useLocation();

  return (
    <Suspense key={location.key}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  // For LMB
  const teamExternalId = localStorage.getItem('Sfeira.abonnement.team');

  return useRoutes([
    // Main Routes
    {
      children: [
        { element: <NotFound />, path: '404' },
        {
          element: (
            <RoleBasedGuard roles={[USER_ROLES.ADMIN, USER_ROLES.FAN]}>
              <MainHome />
            </RoleBasedGuard>
          ),
          index: true,
        },
        {
          path: POST_PATH.create,
          element: (
            <AuthGuard redirectTo={POST_PATH.create}>
              <RoleBasedGuard roles={[USER_ROLES.ADMIN, USER_ROLES.FAN]}>
                <CreatePostSection />
              </RoleBasedGuard>
            </AuthGuard>
          ),
        },
        {
          path: 'livescore',
          children: [
            {
              index: true,
              element: (
                <RoleBasedGuard roles={[USER_ROLES.ADMIN, USER_ROLES.FAN]}>
                  <Livescore />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'searchbar',
              element: (
                <AuthGuard redirectTo="livescore/searchbar">
                  <RoleBasedGuard roles={[USER_ROLES.ADMIN, USER_ROLES.FAN]}>
                    <SearchBar />
                  </RoleBasedGuard>
                </AuthGuard>
              ),
            },
            {
              path: 'favoritechamps',
              element: (
                <AuthGuard redirectTo="livescore/favoritechamps">
                  <RoleBasedGuard roles={[USER_ROLES.ADMIN, USER_ROLES.FAN]}>
                    <FavoriteChamps />
                  </RoleBasedGuard>
                </AuthGuard>
              ),
            },
            {
              path: ':name',
              element: (
                <RoleBasedGuard roles={[USER_ROLES.ADMIN, USER_ROLES.FAN]}>
                  <LivescoreDetails />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'champion/:id',
              element: (
                <RoleBasedGuard roles={[USER_ROLES.ADMIN, USER_ROLES.FAN]}>
                  <LeagueDetail />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          element: (
            <RoleBasedGuard roles={[USER_ROLES.ADMIN, USER_ROLES.FAN]}>
              <Browse />
            </RoleBasedGuard>
          ),
          path: 'browse',
        },
        {
          element: <Podcast />,
          path: 'podcast',
        },
        {
          element: (
            <RoleBasedGuard roles={[USER_ROLES.ADMIN, USER_ROLES.FAN]}>
              <Quizz />
            </RoleBasedGuard>
          ),
          path: 'quizz',
        },
        {
          element: (
            <AuthGuard redirectTo="notification">
              <RoleBasedGuard roles={[USER_ROLES.ADMIN, USER_ROLES.FAN]}>
                <Notification />
              </RoleBasedGuard>
            </AuthGuard>
          ),
          path: 'notification',
        },
        {
          element: (
            <AuthGuard redirectTo="notification">
              <RoleBasedGuard roles={[USER_ROLES.ADMIN, USER_ROLES.FAN]}>
                <PostDetails />
              </RoleBasedGuard>
            </AuthGuard>
          ),
          path: 'notification/:id',
        },
        {
          path: 'messaging',
          children: [
            {
              index: true,
              element: (
                <AuthGuard redirectTo="messaging">
                  <RoleBasedGuard roles={[USER_ROLES.ADMIN, USER_ROLES.FAN]}>
                    <Messaging />
                  </RoleBasedGuard>
                </AuthGuard>
              ),
            },
            {
              path: 'groups',
              children: [
                {
                  index: true,
                  element: (
                    <AuthGuard redirectTo="messaging/groups">
                      <RoleBasedGuard
                        roles={[USER_ROLES.ADMIN, USER_ROLES.FAN]}
                      >
                        <DiscussionGroups />
                      </RoleBasedGuard>
                    </AuthGuard>
                  ),
                },
                {
                  path: 'new',
                  element: (
                    <AuthGuard redirectTo="messaging/groups">
                      <NewGroup />
                    </AuthGuard>
                  ),
                },
              ],
            },
            {
              path: 'messages',
              children: [
                {
                  index: true,
                  element: (
                    <AuthGuard redirectTo="messaging/messages">
                      <RoleBasedGuard
                        roles={[USER_ROLES.ADMIN, USER_ROLES.FAN]}
                      >
                        <Message />
                      </RoleBasedGuard>
                    </AuthGuard>
                  ),
                },
                {
                  path: 'new',
                  element: (
                    <AuthGuard redirectTo="messaging/messages">
                      <RoleBasedGuard
                        roles={[USER_ROLES.ADMIN, USER_ROLES.FAN]}
                      >
                        <NewMessage />
                      </RoleBasedGuard>
                    </AuthGuard>
                  ),
                },
              ],
            },
            {
              path: 'invitations',
              children: [
                {
                  index: true,
                  element: (
                    <AuthGuard redirectTo="messaging/invitations">
                      <RoleBasedGuard
                        roles={[USER_ROLES.ADMIN, USER_ROLES.FAN]}
                      >
                        <InvitationsList />
                      </RoleBasedGuard>
                    </AuthGuard>
                  ),
                },
                {
                  path: ':id',
                  element: (
                    <AuthGuard redirectTo="messaging/invitations">
                      <RoleBasedGuard
                        roles={[USER_ROLES.ADMIN, USER_ROLES.FAN]}
                      >
                        <ReceivedInvitation />
                      </RoleBasedGuard>
                    </AuthGuard>
                  ),
                },
              ],
            },
            {
              path: 'invitations',
              children: [
                {
                  index: true,
                  element: (
                    <AuthGuard redirectTo="messaging/invitations">
                      <RoleBasedGuard
                        roles={[USER_ROLES.ADMIN, USER_ROLES.FAN]}
                      >
                        <InvitationsList />
                      </RoleBasedGuard>
                    </AuthGuard>
                  ),
                },
                {
                  path: 'from',
                  element: (
                    <AuthGuard redirectTo="messaging/invitations">
                      <RoleBasedGuard
                        roles={[USER_ROLES.ADMIN, USER_ROLES.FAN]}
                      >
                        <ReceivedInvitation />
                      </RoleBasedGuard>
                    </AuthGuard>
                  ),
                },
              ],
            },
          ],
        },
        {
          element: (
            <AuthGuard redirectTo="/">
              <RoleBasedGuard roles={[USER_ROLES.ADMIN, USER_ROLES.FAN]}>
                <PostDetails />
              </RoleBasedGuard>
            </AuthGuard>
          ),
          path: 'carrouselPost/:id',
        },

        {
          path: 'quizz',
          children: [
            {
              path: 'theme/:nbr',
              element: (
                <RoleBasedGuard roles={[USER_ROLES.ADMIN, USER_ROLES.FAN]}>
                  <QuizzTheme />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'theme/:userId',
              element: (
                <RoleBasedGuard roles={[USER_ROLES.ADMIN, USER_ROLES.FAN]}>
                  <QuizzTheme />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'quizz-list/:id/:nbr',
              element: (
                <RoleBasedGuard roles={[USER_ROLES.ADMIN, USER_ROLES.FAN]}>
                  <QuizzList />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'quizz-list/:id/:userId',
              element: (
                <RoleBasedGuard roles={[USER_ROLES.ADMIN, USER_ROLES.FAN]}>
                  <QuizzList />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'jouer-seul/:id/:nbr/:type',
              element: (
                <RoleBasedGuard roles={[USER_ROLES.ADMIN, USER_ROLES.FAN]}>
                  <QuizzJouerSeul />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'question/:id/:nbr/:type',
              element: (
                <RoleBasedGuard roles={[USER_ROLES.ADMIN, USER_ROLES.FAN]}>
                  <QuizzQuestion />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'question/:id/:userId',
              element: (
                <RoleBasedGuard roles={[USER_ROLES.ADMIN, USER_ROLES.FAN]}>
                  <QuizzQuestion />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'result',
              element: (
                <RoleBasedGuard roles={[USER_ROLES.ADMIN, USER_ROLES.FAN]}>
                  <QuizzResult />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'liste-ami/:id/:nbr/:type',
              element: (
                <RoleBasedGuard roles={[USER_ROLES.ADMIN, USER_ROLES.FAN]}>
                  <ListeAmis />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'challenge-ami/:userId',
              element: (
                <RoleBasedGuard roles={[USER_ROLES.ADMIN, USER_ROLES.FAN]}>
                  <QuizzChallengeAmi />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'start-defi/:id/:userId',
              element: (
                <RoleBasedGuard roles={[USER_ROLES.ADMIN, USER_ROLES.FAN]}>
                  <QuizzStartDefi />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'result-challenge',
              element: (
                <RoleBasedGuard roles={[USER_ROLES.ADMIN, USER_ROLES.FAN]}>
                  <ResultChallenge />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'quizz-podium/:typeJeu',
              element: (
                <RoleBasedGuard roles={[USER_ROLES.ADMIN, USER_ROLES.FAN]}>
                  <QuizzPodium />
                </RoleBasedGuard>
              ),
            },
            // {
            //   path: 'quizz-partage/:id',
            //   element: (
            //     <RoleBasedGuard roles={[USER_ROLES.ADMIN, USER_ROLES.FAN]}>
            //       <Partager />
            //     </RoleBasedGuard>
            //   ),
            // },
            {
              path: 'liste-details/:id/:nbr/:type/:voir',
              element: (
                <RoleBasedGuard roles={[USER_ROLES.ADMIN, USER_ROLES.FAN]}>
                  <ListeDetails />
                </RoleBasedGuard>
              ),
            },
            // {
            //   path: 'partager-resultat',
            //   element: (
            //     <RoleBasedGuard roles={[USER_ROLES.ADMIN, USER_ROLES.FAN]}>
            //       <PartagerResultat />
            //     </RoleBasedGuard>
            //   ),
            // },
            {
              path: 'contenu-partager',
              element: (
                <RoleBasedGuard roles={[USER_ROLES.ADMIN, USER_ROLES.FAN]}>
                  <ContenuPartager />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'choix/:id/:nbr',
              element: (
                <RoleBasedGuard roles={[USER_ROLES.ADMIN, USER_ROLES.FAN]}>
                  <ChoixQuizz />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'quizz-partage/:id',
              element: (
                <RoleBasedGuard roles={[USER_ROLES.ADMIN, USER_ROLES.FAN]}>
                  <Partager />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'liste-details/:id',
              element: (
                <RoleBasedGuard roles={[USER_ROLES.ADMIN, USER_ROLES.FAN]}>
                  <ListeDetails />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'partager-resultat',
              element: (
                <RoleBasedGuard roles={[USER_ROLES.ADMIN, USER_ROLES.FAN]}>
                  <PartagerResultat />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'contenu-partager',
              element: (
                <RoleBasedGuard roles={[USER_ROLES.ADMIN, USER_ROLES.FAN]}>
                  <ContenuPartager />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'classement',
              element: (
                <RoleBasedGuard roles={[USER_ROLES.ADMIN, USER_ROLES.FAN]}>
                  <Classement />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'choix/:id',
              element: (
                <RoleBasedGuard roles={[USER_ROLES.ADMIN, USER_ROLES.FAN]}>
                  <ChoixQuizz />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'quizzhistorique',
              element: (
                <RoleBasedGuard roles={[USER_ROLES.ADMIN, USER_ROLES.FAN]}>
                  <QuizzHistorique />
                </RoleBasedGuard>
              ),
            },
          ],
        },

        {
          path: 'profile',
          children: [
            {
              path: ':id',
              element: (
                <AuthGuard redirectTo={'/'}>
                  <RoleBasedGuard roles={[USER_ROLES.ADMIN, USER_ROLES.FAN]}>
                    <Profile />
                  </RoleBasedGuard>
                </AuthGuard>
              ),
            },
            {
              index: true,
              element: (
                <AuthGuard redirectTo={'/'}>
                  <RoleBasedGuard roles={[USER_ROLES.ADMIN, USER_ROLES.FAN]}>
                    <Profile />
                  </RoleBasedGuard>
                </AuthGuard>
              ),
            },
            {
              path: 'settings',
              children: [
                {
                  index: true,
                  element: (
                    <RoleBasedGuard roles={[USER_ROLES.ADMIN, USER_ROLES.FAN]}>
                      <Settings />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: 'account',
                  children: [
                    { index: true, element: <AccountSettings /> },
                    { path: 'email', element: <EmailSettings /> },
                    { path: 'name', element: <NameSettings /> },
                    { path: 'family', element: <FamilySettings /> },
                    { path: 'name', element: <NameSettings /> },
                    { path: 'age', element: <AgeSettings /> },
                    { path: 'city', element: <CitySettings /> },
                    { path: 'age', element: <AgeSettings /> },
                    { path: 'phone', element: <PhoneSettings /> },
                  ],
                },
                {
                  path: 'document',
                  children: [
                    { index: true, element: <DocumentsSetting /> },
                    { path: 'legal-notice', element: <LegalNotice /> },
                    { path: 'general-terms', element: <GeneralTermsOfUse /> },
                    {
                      path: 'confidentiality-politic',
                      element: <ConfidentialityPolitic />,
                    },
                  ],
                },
                { path: 'profile_type', element: <ProfileTypeSettings /> },
                { path: 'security', element: <SecuritySettings /> },
                { path: 'career', element: <Career /> },
                { path: 'notification', element: <NotificationProfile /> },
                { path: 'privacy', element: <Privacy /> },
                { path: 'club', element: <ClubSettings /> },
              ],
            },
            {
              path: 'send-invitation',
              element: <SendInvitation />,
            },
          ],
        },
        {
          path: 'posts/profile',
          element: (
            <RoleBasedGuard roles={[USER_ROLES.ADMIN, USER_ROLES.FAN]}>
              <ProfilePosts />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'search',
          element: (
            <AuthGuard redirectTo="search">
              <RoleBasedGuard roles={[USER_ROLES.ADMIN, USER_ROLES.FAN]}>
                <Search />
              </RoleBasedGuard>
            </AuthGuard>
          ),
        },
        {
          path: 'ticketing',
          children: [
            {
              index: true,
              element: <Ticketing />,
            },
            {
              path: 'panier',
              element: <Cart />,
            },
            { path: 'welcome', element: <Welcome /> },
            {
              path: 'reservation',
              children: [
                { index: true, element: <Reservation /> },
                {
                  path: 'group',
                  children: [
                    { index: true, element: <ReservationGroup /> },
                    { path: 'check-code', element: <CheckGroupCode /> },
                  ],
                },
                {
                  path: 'group',
                  children: [
                    { index: true, element: <ReservationGroup /> },
                    { path: 'create-compte', element: <CreateCompte /> },
                  ],
                },
                {
                  path: 'group',
                  children: [
                    { index: true, element: <ReservationGroup /> },
                    {
                      path: 'acount-success',
                      element: <CreateAcountSuccess />,
                    },
                  ],
                },
              ],
            },
            {
              path: 'vos-billets',
              element: (
                <AuthGuard redirectTo="ticketing/vos-billets">
                  <RoleBasedGuard roles={[USER_ROLES.ADMIN, USER_ROLES.FAN]}>
                    <MyTicketList />
                  </RoleBasedGuard>
                </AuthGuard>
              ),
            },
            {
              path: 'billing',
              children: [
                {
                  index: true,
                  element: (
                    <AuthGuard redirectTo="ticketing/billing">
                      <RoleBasedGuard
                        roles={[USER_ROLES.ADMIN, USER_ROLES.FAN]}
                      >
                        <BillingPage />
                      </RoleBasedGuard>
                    </AuthGuard>
                  ),
                },
                {
                  path: 'success',
                  element: (
                    <AuthGuard redirectTo="ticketing/billing">
                      <RoleBasedGuard
                        roles={[USER_ROLES.ADMIN, USER_ROLES.FAN]}
                      >
                        <SuccessPaymentPage />
                      </RoleBasedGuard>
                    </AuthGuard>
                  ),
                },
              ],
            },
            {
              path: 'votre-carte-abonnement',
              children: [
                {
                  index: true,
                  element: (
                    <AuthGuard redirectTo={`ticketing/vos-billets`}>
                      <ViewSubscriptionTicket />
                    </AuthGuard>
                  ),
                },
                {
                  path: 'qrcode',
                  element: (
                    <AuthGuard redirectTo={`ticketing/vos-billets`}>
                      <ViewSubscriberQrCode />
                    </AuthGuard>
                  ),
                },
              ],
            },
            {
              path: 'votre-billet',
              children: [
                {
                  index: true,
                  element: (
                    <AuthGuard redirectTo="ticketing/vos-billets">
                      <RoleBasedGuard
                        roles={[USER_ROLES.ADMIN, USER_ROLES.FAN]}
                      >
                        <MyTicket />
                      </RoleBasedGuard>
                    </AuthGuard>
                  ),
                },
                {
                  path: 'qrcode',
                  element: (
                    <AuthGuard redirectTo="ticketing/vos-billets">
                      <RoleBasedGuard
                        roles={[USER_ROLES.ADMIN, USER_ROLES.FAN]}
                      >
                        <TicketQrCode />
                      </RoleBasedGuard>
                    </AuthGuard>
                  ),
                },
              ],
            },
          ],
        },
      ],
      element: <MainLayout />,
      path: '',
    },

    // HEXA-GAMES
    {
      path: 'hexa-games',
      children: [
        {
          path: ':matchId',
          element: <HexaGames />,
        },
        {
          path: ':matchId/reservation',
          element: <HexaGamesReservation />,
        },
        {
          path: ':matchId/panier',
          element: <HexaGamesCart />,
        },
        {
          path: ':matchId/checkout',
          element: <CheckoutPaiement />,
        },
        {
          path: ':matchId/valider',
          element: <HexaGamesPaymentValidated />,
        },
        { path: 'create-compte', element: <HexaGamesCreateCompte /> },
        {
          path: 'account-success',
          element: <HexaGamesCreateAcountSuccess />,
        },
      ],
    },

    //VIVATECH
    {
      path: 'vivatech',
      element: <VivaTechPage />,
    },

    // Abonnement
    {
      path: 'abonnement',
      children: [
        {
          index: true,
          element: <Subscription />,
        },
        {
          path: ':teamExternalId',
          element: <Subscription />,
        },
        {
          path: ':teamExternalId/home',
          element: <HomePage />,
        },
        {
          path: ':teamExternalId/reabonnement',
          element: <Resubscription />,
        },
        {
          path: ':teamExternalId/about-me',
          element: <UserInformations />,
        },
        {
          path: 'success',
          element: (
            <RoleBasedGuard roles={[USER_ROLES.ADMIN, USER_ROLES.FAN]}>
              <ResubscriptionSuccess />
            </RoleBasedGuard>
          ),
        },
        {
          path: ':teamExternalId/paiement',
          element: <SubscriptionPayment />,
        },
        {
          path: ':teamExternalId/ticket-sales',
          element: <TicketSalesHome />,
        },
        {
          path: ':teamExternalId/mobil-paiement',
          element: <MobilPaiement />,
        },
        {
          path: ':teamExternalId/valider',
          element: (
            <AuthGuard
              redirectTo={`abonnement/${teamExternalId}/valider`}
              screenHint="signup"
            >
              <SubscriptionPaymentValidated />
            </AuthGuard>
          ),
        },
        {
          path: ':teamExternalId/first',
          element: <SubscriptionPage />,
        },
        {
          path: ':teamExternalId/season',
          element: <ChoiseSeason />,
        },
        {
          path: ':teamExternalId/pack',
          element: <ChoisePack />,
        },
      ],
    },

    // SIGNUP
    {
      path: 'signup',
      element: (
        <AuthGuard redirectTo="signup">
          <RoleBasedGuard roles={[USER_ROLES.ADMIN, USER_ROLES.FAN]}>
            <SignUp />
          </RoleBasedGuard>
        </AuthGuard>
      ),
    },
    {
      path: 'tutorial',
      element: (
        <RoleBasedGuard roles={[USER_ROLES.ADMIN, USER_ROLES.FAN]}>
          <Tutorial />
        </RoleBasedGuard>
      ),
    },
    {
      path: 'matches',
      children: [
        {
          index: true,
          element: (
            <RoleBasedGuard roles={[USER_ROLES.CONTROLLER]}>
              <Matches />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'controle',
          children: [
            {
              index: true,
              element: (
                <RoleBasedGuard roles={[USER_ROLES.CONTROLLER]}>
                  <Controle />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'historique',
              children: [
                {
                  index: true,
                  element: (
                    <RoleBasedGuard roles={[USER_ROLES.CONTROLLER]}>
                      <ControlHistory />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: 'detail',
                  element: (
                    <RoleBasedGuard roles={[USER_ROLES.CONTROLLER]}>
                      <HistoryDetail />
                    </RoleBasedGuard>
                  ),
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: 'messaging/groups/conversation/:id',
      element: (
        <RoleBasedGuard roles={[USER_ROLES.ADMIN, USER_ROLES.FAN]}>
          <GroupConversation />
        </RoleBasedGuard>
      ),
    },
    {
      path: 'messaging/groups/new-conversation/:id',
      element: (
        <RoleBasedGuard roles={[USER_ROLES.ADMIN, USER_ROLES.FAN]}>
          <NewGroupConversation />
        </RoleBasedGuard>
      ),
    },
    {
      path: 'messaging/messages/conversation/:id',
      element: (
        <AuthGuard redirectTo="messaging/conversation">
          <RoleBasedGuard roles={[USER_ROLES.ADMIN, USER_ROLES.FAN]}>
            <MessageDiscussion />
          </RoleBasedGuard>
        </AuthGuard>
      ),
    },
    {
      path: 'player',
      children: [
        { index: true, element: <CreatePlayerProfile /> },
        {
          path: 'create-profile',
          element: <ProfileWithLicenseNumber />,
        },
        {
          path: 'check-id',
          element: <CheckID />,
        },
        {
          path: 'wrong-id',
          element: <WrongID />,
        },
        {
          path: 'validated-id',
          element: <ValidatedID />,
        },
        {
          path: 'profile',
          children: [
            { index: true, element: <PlayerProfile /> },
            {
              path: 'settings',
              children: [
                { index: true, element: <PlayerGeneralInformations /> },
                {
                  path: 'my-agent',
                  element: <PlayerAgentContactDetails />,
                },
              ],
            },
            { path: 'skills', element: <PlayerSkills /> },
            {
              path: 'career',
              element: <PlayerCareer />,
            },
            // { path: 'commentaire', element: <CommentaireJoueur /> },
            // {
            //   path: 'recommandation_list',
            //   element: <RecommendationList />,
            // },
            // {
            //   path: 'recommandation_success',
            //   element: <RecommendationSuccess />,
            // },
          ],
        },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

const MainHome = Loadable(lazy(() => import('../pages/home/index')));
const CreatePostSection = Loadable(
  lazy(() => import('../pages/home/createPost/CreatePostSection'))
);
const PostDetails = Loadable(
  lazy(() => import('../pages/home/postList/PostById'))
);
const MainLayout = Loadable(lazy(() => import('../layouts/main/MainLayout')));
const NotFound = Loadable(lazy(() => import('../pages/404/Page404')));

//BottomNavigation
const Browse = Loadable(lazy(() => import('../pages/browse/index')));
const Podcast = Loadable(lazy(() => import('../pages/podcast')));
const Livescore = Loadable(lazy(() => import('../pages/livescore/index.js')));
const SearchBar = Loadable(lazy(() => import('../pages/livescore/SearchBar')));
const LivescoreDetails = Loadable(
  lazy(() => import('../pages/livescore/LivescoreDetails'))
);
const LeagueDetail = Loadable(
  lazy(() => import('../pages/livescore/LeagueDetail'))
);
const Notification = Loadable(
  lazy(() => import('../pages/notification/index'))
);
const Search = Loadable(lazy(() => import('../pages/search/index')));

// Messages
const Messaging = Loadable(lazy(() => import('../pages/messaging/index')));
const DiscussionGroups = Loadable(
  lazy(() => import('../pages/messaging/groups/index'))
);
const Message = Loadable(
  lazy(() => import('../pages/messaging/messages/index'))
);
const SendInvitation = Loadable(
  lazy(() => import('../pages/profile/messaging/SendInvitation'))
);
const InvitationsList = Loadable(
  lazy(() => import('../pages/messaging/invitations/index'))
);
const ReceivedInvitation = Loadable(
  lazy(() => import('../pages/messaging/invitations/ReceivedInvitation'))
);
const NewGroup = Loadable(
  lazy(() => import('../pages/messaging/groups/NewGroup'))
);
const GroupConversation = Loadable(
  lazy(() => import('../pages/messaging/groups/Conversation'))
);
const NewGroupConversation = Loadable(
  lazy(() => import('../pages/messaging/groups/NewConversation'))
);
const NewMessage = Loadable(
  lazy(() => import('../pages/messaging/messages/NewMessage'))
);
const MessageDiscussion = Loadable(
  lazy(() => import('../pages/messaging/messages/Discussion'))
);

// Profile
const Quizz = Loadable(lazy(() => import('../pages/quizz/index')));
const QuizzTheme = Loadable(
  lazy(() => import('../pages/quizz/inquizz/QuizzTheme'))
);
const ResultChallenge = Loadable(
  lazy(() => import('../pages/quizz/QuizzResultChallenge'))
);
const ContenuPartager = Loadable(
  lazy(() => import('../pages/quizz/ContenuPartager'))
);
const ListeAmis = Loadable(lazy(() => import('../pages/quizz/ListeAmis')));
const PartagerResultat = Loadable(
  lazy(() => import('../pages/quizz/PartagerResultat'))
);
const QuizzJouerSeul = Loadable(
  lazy(() => import('../pages/quizz/QuizzJouerSeul'))
);

const Profile = Loadable(lazy(() => import('../pages/profile/index')));
const ProfilePosts = Loadable(
  lazy(() => import('../pages/profile/Post/PostList'))
);
const Settings = Loadable(
  lazy(() => import('../pages/profile/settings/index'))
);
const Career = Loadable(lazy(() => import('../pages/profile/Career')));
const NotificationProfile = Loadable(
  lazy(() => import('../pages/profile/settings/Notification'))
);
const Privacy = Loadable(
  lazy(() => import('../pages/profile/settings/Privacy/Privacy'))
);
const AccountSettings = Loadable(
  lazy(() => import('../pages/profile/settings/account/AccountSettings'))
);
const EmailSettings = Loadable(
  lazy(() => import('../pages/profile/settings/account/EmailSettings'))
);
const ProfileTypeSettings = Loadable(
  lazy(() => import('../pages/profile/settings/ProfileTypeSettings'))
);
const SecuritySettings = Loadable(
  lazy(() => import('../pages/profile/settings/SecuritySettings'))
);
const NameSettings = Loadable(
  lazy(() => import('../pages/profile/settings/account/NameSettings'))
);
const FamilySettings = Loadable(
  lazy(() => import('../pages/profile/settings/account/FamilySettings'))
);
const AgeSettings = Loadable(
  lazy(() => import('../pages/profile/settings/account/AgeSettings'))
);
const CitySettings = Loadable(
  lazy(() => import('../pages/profile/settings/account/CitySettings'))
);
const PhoneSettings = Loadable(
  lazy(() => import('../pages/profile/settings/account/PhoneSettings'))
);
const ClubSettings = Loadable(
  lazy(() => import('../pages/profile/settings/club/index.js'))
);
const SignUp = Loadable(lazy(() => import('../pages/signup/index')));
const Tutorial = Loadable(lazy(() => import('../pages/tutorial/index')));
const Matches = Loadable(lazy(() => import('../pages/matches/index')));
// Ticketing
const Ticketing = Loadable(lazy(() => import('../pages/ticketing/index')));
const Controle = Loadable(lazy(() => import('../pages/matches/Controle')));
const ControlHistory = Loadable(
  lazy(() => import('../pages/matches/ControlHistory'))
);
const HistoryDetail = Loadable(
  lazy(() => import('../pages/matches/HistoryDetail'))
);
const Cart = Loadable(lazy(() => import('../pages/ticketing/cart/index')));
const BillingPage = Loadable(
  lazy(() => import('../pages/ticketing/billing/index'))
);
const Reservation = Loadable(
  lazy(() => import('../pages/ticketing/reservation/index'))
);
const MyTicketList = Loadable(
  lazy(() => import('../pages/ticketing/myTicket/list/index'))
);
const MyTicket = Loadable(
  lazy(() => import('../pages/ticketing/myTicket/index'))
);
const TicketQrCode = Loadable(
  lazy(() => import('../pages/ticketing/myTicket/qrcode/index'))
);
const Welcome = Loadable(
  lazy(() => import('../pages/ticketing/welcome/index'))
);
const CheckGroupCode = Loadable(
  lazy(() => import('../pages/ticketing/reservation/group/check-code/index'))
);

const CreateCompte = Loadable(
  lazy(() => import('../pages/ticketing/reservation/group/create-compte/index'))
);
const CreateAcountSuccess = Loadable(
  lazy(() =>
    import('../pages/ticketing/reservation/group/acount-success/index')
  )
);
const ReservationGroup = Loadable(
  lazy(() => import('../pages/ticketing/reservation/group/index'))
);
const SuccessPaymentPage = Loadable(
  lazy(() => import('../pages/ticketing/billing/success/index'))
);

// Hexa games
const HexaGames = Loadable(
  lazy(() => import('../pages/ticketing/hexa-games/index'))
);
const HexaGamesReservation = Loadable(
  lazy(() => import('../pages/ticketing/hexa-games/Reservation'))
);
const HexaGamesCart = Loadable(
  lazy(() => import('../pages/ticketing/hexa-games/Cart'))
);
const HexaGamesPaymentValidated = Loadable(
  lazy(() => import('../pages/ticketing/hexa-games/Validated'))
);
const CheckoutPaiement = Loadable(
  lazy(() => import('../pages/ticketing/hexa-games/checkoutPaiement'))
);
const HexaGamesCreateCompte = Loadable(
  lazy(() => import('../pages/ticketing/hexa-games/create-compte/index'))
);
const HexaGamesCreateAcountSuccess = Loadable(
  lazy(() => import('../pages/ticketing/hexa-games/acount-success/index'))
);
//vivatech
const VivaTechPage = Loadable(lazy(() => import('../pages/vivatech/index.js')));

// LMB Subscription
const Subscription = Loadable(
  lazy(() => import('../pages/subscription/index'))
);
const Resubscription = Loadable(
  lazy(() => import('../pages/subscription/Resubscription'))
);
const ResubscriptionSuccess = Loadable(
  lazy(() => import('../pages/subscription/ResubscriptionSuccess.js'))
);
const SubscriptionPage = Loadable(
  lazy(() => import('../pages/subscription/SubscriptionPage'))
);
const UserInformations = Loadable(
  lazy(() => import('../pages/subscription/UserInformations'))
);
const ChoisePack = Loadable(
  lazy(() => import('../pages/subscription/ChoisePack'))
);

const ChoiseSeason = Loadable(
  lazy(() => import('../pages/subscription/ChoiseSeason'))
);
const SubscriptionPayment = Loadable(
  lazy(() => import('../pages/subscription/SubscriptionPayment'))
);

const MobilPaiement = Loadable(
  lazy(() => import('../pages/subscription/MobilPaiement'))
);
const SubscriptionPaymentValidated = Loadable(
  lazy(() => import('../pages/subscription/Validated'))
);
const HomePage = Loadable(lazy(() => import('../pages/subscription/HomePage')));
const ViewSubscriptionTicket = Loadable(
  lazy(() => import('../pages/ticketing/myTicket/ViewSubscriptionTicket'))
);
const ViewSubscriberQrCode = Loadable(
  lazy(() => import('../pages/ticketing/myTicket/qrcode/ViewSubscriberQrCode'))
);

const TicketSalesHome = Loadable(
  lazy(() => import('../pages/subscription/TicketSalesHome'))
);

// Player
const CreatePlayerProfile = Loadable(
  lazy(() => import('../pages/player/index'))
);
const PlayerProfile = Loadable(
  lazy(() => import('../pages/player/profile/index'))
);
const ProfileWithLicenseNumber = Loadable(
  lazy(() =>
    import('../pages/player/createProfile/ProfileWithLicenseNumber.js')
  )
);
const CheckID = Loadable(
  lazy(() => import('../pages/player/createProfile/Quiz/CheckID.js'))
);
const WrongID = Loadable(
  lazy(() => import('../pages/player/createProfile/Quiz/WrongID.js'))
);
const ValidatedID = Loadable(
  lazy(() => import('../pages/player/createProfile/Quiz/ValidID.js'))
);
const PlayerGeneralInformations = Loadable(
  lazy(() => import('../pages/player/profile/generalInformations/index'))
);
const PlayerAgentContactDetails = Loadable(
  lazy(() => import('../pages/player/profile/generalInformations/agent/index'))
);
const PlayerSkills = Loadable(
  lazy(() => import('../pages/player/profile/Skills/EditSkills.js'))
);
const PlayerCareer = Loadable(
  lazy(() => import('../pages/player/profile/Career/EditCareer.js'))
);
// Routes for recommandations
// const CommentaireJoueur = Loadable(
//   lazy(() => import('../pages/profile/joueur/joueurCommentaire/Commentaire'))
// );
// const RecommendationList = Loadable(
//   lazy(() =>
//     import('../pages/profile/joueur/joueurCommentaire/RecommendationList')
//   )
// );
// const RecommendationSuccess = Loadable(
//   lazy(() =>
//     import('../pages/profile/joueur/joueurCommentaire/RecommendationSuccess')
//   )
// );
