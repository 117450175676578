import { createSlice } from '@reduxjs/toolkit';
export const listeSlice = createSlice({
  name: 'liste',
  initialState: {
    value: 0,
  },
  reducers: {
    change: (state, payload) => {
      state.value = payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { change } = listeSlice.actions;
export default listeSlice.reducer;
