import React, { useState } from 'react';

const MessageOptionContext = React.createContext();

const MessageOptionContextProvider = ({ children }) => {
  const [showOptions, setShowOptions] = useState(false);
  const [currentMessage, setCurrentMessage] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  const value = {
    showOptions,
    currentMessage,
    isEditing,
    setIsEditing,
    setCurrentMessage,
    setShowOptions,
  };

  return (
    <MessageOptionContext.Provider value={value}>
      {children}
    </MessageOptionContext.Provider>
  );
};

export { MessageOptionContext, MessageOptionContextProvider };
