import React, { createContext, useReducer } from 'react';

const initialNotificationState = {
  unreadNotificationCount: null,
};

const actions = {
  setUnreadNotificationCount: (state, payload) => {
    return {
      ...state,
      unreadNotificationCount: payload,
    };
  },
};

const NotificationContext = createContext(initialNotificationState);

const reducer = (state, { payload, type }) => {
  if (actions[type]) {
    return actions[type](state, payload);
  }
  return state;
};

const NotificationContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialNotificationState);

  return (
    <NotificationContext.Provider
      value={{ notificationDispatch: dispatch, notificationState: state }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export { NotificationContext, NotificationContextProvider };
