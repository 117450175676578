import React from 'react';
import DynamicSVG from '../../components/DynamicSVG';
import { ACTIVE_SVG_STROKE } from '../../utils/svgColor';
import { useNavigate } from 'react-router';
import { AnimatePresence, m } from 'framer-motion';

const MainHeader = ({
  items,
  leftText,
  leftIconAction,
  rightIcons,
  isRightIconsWithStrokeColor = true,
  rightIconsAction,
  rightItem,
  leftElement,
  LeftIcon,
  centerText,
  centerElement,
  fixed,
  className,
  style,
  leftTextStyle,
  centerTextStyle,
}) => {
  const navigate = useNavigate();
  const moreClassName = fixed
    ? 'sm:w-1/2 md:w-2/3 lg:w-1/3 xl:w-1/4 mr-4 -ml-4 px-3.5 fixed pt-8'
    : 'sm:w-full md:w-full lg:w-full xl:w-full relative';

  const handleAction = (action) => {
    action();
  };

  const goBack = () => {
    navigate(-1);
  };

  const rigthIconRedirect = () => {
    if (rightIconsAction) {
      rightIconsAction();
    } else {
      goBack();
    }
  };

  return items === 2 ? (
    <AnimatePresence>
      <m.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
      >
        <div
          className={`flex w-full justify-between z-20 top-0 pt-5 pb-5 mt-4 ${className} ${moreClassName}`}
          style={style}
        >
          <div className="flex gap-2 items-center justify-center">
            <DynamicSVG
              src={`./assets/icons/ic_arrow_left.svg`}
              className="cursor-pointer"
              onClick={rigthIconRedirect}
              strokeColor={ACTIVE_SVG_STROKE}
            />
            {LeftIcon && (
              <DynamicSVG
                src={LeftIcon}
                className="cursor-pointer"
                strokeColor={ACTIVE_SVG_STROKE}
              />
            )}
            <p className="title text-white" style={leftTextStyle}>
              {leftText}
            </p>
          </div>
          <div className="flex gap-5">
            {rightIcons
              ?.filter((rightIcon) => !rightIcon.hide)
              ?.map((rightIcon, index) => (
                <div key={index} className="relative flex items-center">
                  <DynamicSVG
                    src={rightIcon.src}
                    className="cursor-pointer"
                    strokeColor={isRightIconsWithStrokeColor && 'white'}
                    onClick={() => handleAction(rightIcon.onClick)}
                  />
                  {rightIcon.notificationCount > 0 && (
                    <div className="absolute flex items-center justify-center w-3 h-3 p-2 bg-blue rounded-full ml-4 -mt-3 text-white text-[8px]">
                      {rightIcon.notificationCount}
                    </div>
                  )}
                </div>
              ))}
          </div>
        </div>
      </m.div>
    </AnimatePresence>
  ) : (
    <div
      className={`flex w-full ${
        rightIcons || (rightItem && 'justify-between')
      } z-20 top-0 pt-8 pb-5 px-3.5 ${className} ${moreClassName}`}
      style={style}
    >
      <div className="flex items-center justify-center gap-5">
        <DynamicSVG
          src={`./assets/icons/ic_arrow_left.svg`}
          className="cursor-pointer"
          onClick={() => {
            leftIconAction ? handleAction(leftIconAction) : goBack();
          }}
          strokeColor={ACTIVE_SVG_STROKE}
        />
        {leftElement && leftElement}
      </div>
      {centerText && (
        <p className="title text-white" style={centerTextStyle}>
          {centerText}
        </p>
      )}
      {centerElement && centerElement}
      {rightIcons && (
        <div className="flex gap-5">
          {rightIcons.map((rightIcon, index) => (
            <DynamicSVG
              src={rightIcon.src}
              className="cursor-pointer"
              strokeColor="white"
              onClick={() => handleAction(rightIcon.onClick)}
              key={index}
            />
          ))}
        </div>
      )}
      {rightItem && rightItem}
    </div>
  );
};

export default MainHeader;
