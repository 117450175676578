import React from 'react';
import { Helmet } from 'react-helmet-async';
import { forwardRef } from 'react';

const Page = forwardRef(
  ({ children, customClassName, title = '', meta, ...other }, ref) => (
    <>
      <Helmet>
        <title>{`${title} | SFEIRA 1.3.1`}</title>
        {meta}
      </Helmet>

      <div
        ref={ref}
        {...other}
        className={`${
          customClassName ? customClassName : 'bg-primaryDark-background w-full'
        }`}
      >
        {children}
      </div>
    </>
  )
);

export default Page;
