import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const useGetUnreadMessagesNumber = () => {
  const { user } = useAuth0();
  const [unreadMessagesNumber, setUnreadMessagesNumber] = useState(0);
  const getUnreadMessagesNumber = (message) => {
    const messageLists = message.messages;
    let unread = 0;
    if (messageLists) {
      for (const messageId in messageLists) {
        const message = messageLists[messageId];
        const messageViewers = message.viewers;
        if (!messageViewers.includes(user?.sub)) {
          unread++;
        }
      }
      setUnreadMessagesNumber(unread);
      return unread;
    }
  };
  return {
    getUnreadMessagesNumber,
    unreadMessagesNumber,
    setUnreadMessagesNumber,
  };
};

export default useGetUnreadMessagesNumber;
