import React, { useEffect, useState } from 'react';
import axios from 'axios';
import DynamicSVG from '../../components/DynamicSVG';
import { Link, useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import Spinner from '../../components/Spinner';
// import InfiniteScroll from 'react-infinite-scroll-component';
import MainHeader from '../../layouts/main/MainHeader';
import { useSelector } from 'react-redux';
import Image from './component/image-background';
// import { getUserInfoById } from '../../services/User';
// import { searchUserRelation } from '../../services/Relation';
// import { getUserSuggestions } from '../../services/Relation';

const ListeDetails = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [isLoading, setIsLoading] = useState(true);
  // const [userInfoList, setUserInfoList] = useState([]);
  const [data, setData] = useState([]);
  // const [page, setPage] = useState(1);
  // const [hasNextPage, setHasNextPage] = useState(true);
  const [search, setSearch] = useState('');
  const [select, setSelect] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const liste = useSelector((state) => state.liste.value);

  const { id, nbr, type /* , voir */ } = useParams();
  const { user } = useAuth0();
  const userConnecter = user.sub;

  const quizId = liste?.payload?.quizId;

  const handleRequest = (id) => {
    // console.log(id, 'id');
    setSelect((current) => [...current, id]);
    // console.log(select, 'el');
    setSelectedUsers((current) =>
      current.includes(id)
        ? current.filter((userId) => userId !== id)
        : [...current, id]
    );
  };

  const handleSendInvitations = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_BACK_OFFICE_URL}quiz/${liste?.payload?.quizId}/send-invitations`,
        // `http://localhost:8080/quiz/${liste?.payload?.quizId}/send-invitations`,
        {
          amis: selectedUsers,
        }
      )
      .then((response) => {
        console.log('Invitations envoyées avec succès', response.data);
        selectedUsers.forEach((user) => {
          axios
            .post(
              // 'http://localhost:8080/notifications/send-invitation',
              `${process.env.REACT_APP_API_BACK_OFFICE_URL}notifications/send-invitation`,
              {
                userConnecter,
                user,
                quizId,
              }
            )
            .then((notificationResponse) => {
              console.log(
                'Notifications envoyées avec succès',
                notificationResponse.data
              );
            })
            .catch((notificationError) => {
              console.error(
                "Erreur lors de l'envoi des notifications",
                notificationError
              );
            });
        });

        setSelectedUsers([]);
      })
      .catch((error) => {
        console.error("Erreur lors de l'envoi des invitations", error);
      });
  };

  // useEffect(() => {
  //   const fetchCount = async (relationType) => {
  //     const token = await getAccessTokenSilently();
  //     const data = {
  //       query: {
  //         userId: id ? id : user?.sub,
  //         relationType: relationType,
  //       },
  //     };
  //     const followers = await searchUserRelation(token, data);
  //     if (followers) {
  //       const promises = followers.map(async (follow) => {
  //         const userRelationId =
  //           relationType === 'FOLLOW' ? follow.startId : follow.endId;
  //         const userById = await getUserInfoById(userRelationId, token);
  //         if (userById) {
  //           return { ...follow, user: userById };
  //         }
  //         return follow;
  //       });
  //       Promise.all(promises).then((results) => {
  //         setData(results);
  //         // setIsLoading(false);
  //       });
  //     }
  //   };
  //   fetchCount();
  // }, [getAccessTokenSilently, user, id]);

  // const [suggestions, setSuggestions] = useState([]);
  // // const [isLoading, setIsLoading] = useState(true);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const token = await getAccessTokenSilently();

  //       const response = await getUserSuggestions(token);

  //       if (response && response.data) {
  //         setSuggestions(response.data);
  //       }
  //     } catch (error) {
  //       console.error(
  //         "Erreur lors de la récupération des suggestions d'utilisateurs :",
  //         error
  //       );
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   };

  //   fetchData();
  // }, []);

  async function fetchUsers() {
    try {
      setIsLoading(true);
      const token = await getAccessTokenSilently();
      const response = await axios.get(
        `${process.env.REACT_APP_API_USER_URL}public?search=${search}`,
        // `http://localhost:4000/public?search=${search}`,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const userList = response.data.users;
      setData(userList);
      // setData((prevItems) => [...prevItems, ...userList]);
      // setPage((prevPage) => prevPage + 1);
      // if (userList.length > 0) {
      //   setHasNextPage(true);
      // } else {
      //   setHasNextPage(false);
      // }
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleSearch = (event) => {
    if (event.target.value.length >= 3) {
      const searchQuery = event.target.value.toLowerCase();

      setSearch(searchQuery);
      fetchUsers();
    }
  };
  // const observerTarget = useRef(null);

  // useEffect(() => {
  //   const observer = new IntersectionObserver(
  //     (entries) => {
  //       if (entries[0].isIntersecting) {
  //         fetchUsers();
  //       }
  //     },
  //     { threshold: 1 }
  //   );

  //   if (observerTarget.current) {
  //     observer.observe(observerTarget.current);
  //   }

  //   return () => {
  //     if (observerTarget.current) {
  //       observer.unobserve(observerTarget.current);
  //     }
  //   };
  // }, [observerTarget]);

  return (
    <div className="w-full h-screen">
      {/* <InfiniteScroll
        dataLength={data.length}
        next={fetchUsers}
        hasMore={hasNextPage}
        loader={<h4>Loading...</h4>}
      > */}
      <Image />
      <MainHeader items={2} leftText="Défier" />
      <div className="flex items-center">
        <input
          type="text"
          placeholder="Rechercher"
          className="text-sm p-10 py-3 my-2 text-white bg-[#1A2342] rounded-full align-middle items-center w-full"
          onChange={handleSearch}
        />
        <DynamicSVG
          src="./assets/icons/ic_search.svg"
          className="w-1 h-1 absolute pl-2 pb-6 "
          strokeColor="#6b7280"
        />
      </div>
      {isLoading ? (
        <div className="flex items-center justify-center h-screen">
          <Spinner />
        </div>
      ) : (
        <ul className="">
          <div className="flex">
            <h4 className="text-white">Mon réseau</h4>
          </div>
          {data.map((userInfo, index) => {
            return (
              <div
                key={index}
                className="bg-[#1A2342] rounded-md my-2 flex items-center p-2"
              >
                {/* <UserPicture userInfo={userInfo} /> */}
                <img
                  src={
                    userInfo?.user_metadata?.profile_picture_flavors?.S ||
                    process.env.REACT_APP_AUTH0_REDIRECT_URI +
                      './assets/images/img_default_profile.png'
                  }
                  className="w-10 h-10 rounded-full"
                />
                <div className=" text-white pl-5 truncate w-48">
                  {userInfo?.user_metadata?.family_name}{' '}
                  {userInfo?.user_metadata?.given_name}
                </div>

                {!select.includes(userInfo.user_id) ? (
                  <button
                    onClick={() => handleRequest(userInfo?.user_id)}
                    className="absolute text-white bg-blue right-7 ml-2 bg-primaryDark-text py-1 px-2 rounded-xl h-8 text-xs"
                  >
                    {' '}
                    Défier
                  </button>
                ) : (
                  <>
                    <button
                      onClick={() => handleRequest(userInfo?.user_id)}
                      className="absolute text-[#1A2342] bg-[#28F365] right-7 ml-2 bg-primaryDark-text py-1 px-2 rounded-xl h-8 text-xs"
                    >
                      {' '}
                      <Link to={`/quizz/jouer-seul/${id}/${nbr}/${type}`}>
                        {' '}
                        Envoyée{' '}
                      </Link>{' '}
                    </button>
                  </>
                )}
              </div>
            );
          })}
        </ul>
      )}
      <div className="sticky bottom-3 m-auto pb-4 w-fit">
        <Link to={`/quizz/jouer-seul/${id}/${nbr}/${type}`}>
          <button
            className="flex justify-center text-sm py-3 my-2 text-white bg-[#018FFB] rounded-2xl w-[200px]"
            onClick={() => handleSendInvitations()}
          >
            Jouer
          </button>{' '}
        </Link>
      </div>
      {/* </InfiniteScroll> */}{' '}
    </div>
  );
};

export default ListeDetails;
