import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import USER_ROLES, { checkRole } from '../constants/roles';
import useAuthUserInfo from '../hooks/useAuthUserInfo';

export default function RoleBasedGuard({ hasContent, roles, children }) {
  const { userInfo } = useAuthUserInfo();
  const navigate = useNavigate();

  useEffect(() => {
    if (userInfo && !checkRole(userInfo.role, false, roles)) {
      // define a must redirection for custom roles here
      if (userInfo.role === USER_ROLES.CONTROLLER) {
        navigate('/matches');
      } else {
        navigate('/404');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  if (!userInfo) return <> {children} </>;

  if (typeof roles !== 'undefined' && !roles.includes(userInfo?.role)) {
    return hasContent ? (
      <div className="opacity-60 pointer-events-none">{hasContent}</div>
    ) : null;
  }

  return <> {children} </>;
}
