import { closeSnackbar } from 'notistack';
import React from 'react';

const DefaultSnackbar = ({ payload }) => {
  return (
    <div
      className="flex items-center justify-center gap-7 text-white cursor-pointer w-[20rem] px-2 py-4 rounded-xl"
      style={{
        background:
          'var(--gradient-primary, linear-gradient(135deg, #017FFE 1.04%, #28F365 98.44%))',
      }}
      onClick={() => closeSnackbar()}
    >
      <p className="text-sm">{payload}</p>
    </div>
  );
};

export default DefaultSnackbar;
