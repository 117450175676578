import React, { useEffect } from 'react';
import { Stepper, Step } from '@material-tailwind/react';
import { useLocation, useParams } from 'react-router';

const Steppers = () => {
  const [activeStep, setActiveStep] = React.useState(0);
  const location = useLocation();
  const { id /* , userId */, nbr, type } = useParams();

  useEffect(() => {
    switch (location.pathname) {
      case '/':
        setActiveStep(0);
        break;
      case `/quizz/theme/${nbr}`:
      case `/quizz/quizz-list/${id}/${nbr}`:
        setActiveStep(1);
        break;
      case `/quizz/choix/${id}/${nbr}`:
      case `/quizz/liste-ami/${id}/${nbr}/${type}`:
        setActiveStep(2);
        break;
      case `/quizz/jouer-seul/${id}/${nbr}/${type}`:
      case `/quizz/start-defi/${id}`:
        setActiveStep(3);
        break;
    }
  }, [location.pathname, id, nbr]);

  return (
    <div>
      <div className="h-[10px] w-50">
        <Stepper
          activeStep={activeStep}
          lineClassName="bg-white p-[4px]"
          activeLineClassName="bg-[#018FFB]"
        >
          <Step
            className="h-4 w-4 p-3 flex flex-col !bg-blue-gray-50 text-white/75 cursor-pointer !bg-white"
            activeClassName="ring-0 !bg-[#018FFB] text-[#018FFB]"
            completedClassName=" !bg-[#018FFB] text-white"
            style={{
              background:
                activeStep === 0
                  ? 'linear-gradient(149.49deg, #017FFE 20.82%, #28F365 143.12%)'
                  : 'none',
            }}
            // onClick={() => setActiveStep(0)}
          >
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-[14px]">
              1
            </div>
            <div className="absolute top-8 w-7 text-center !text-[8px]">
              <p
                className={`text-white ${
                  activeStep === 0 ? 'opacity-100' : 'opacity-40'
                } text-[10px]`}
              >
                Questions
              </p>
            </div>
          </Step>
          <Step
            className="h-4 w-4 p-3 !bg-blue-gray-50 text-white/75 cursor-pointer !bg-white"
            activeClassName="ring-0 !bg-[#018FFB] text-[#018FFB]"
            completedClassName="!bg-[#018FFB] text-white"
            style={{
              background:
                activeStep === 1
                  ? 'linear-gradient(149.49deg, #017FFE 20.82%, #28F365 143.12%)'
                  : 'none',
            }}
            // onClick={() => setActiveStep(1)}
          >
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-[14px]">
              2
            </div>
            <div className="absolute top-8 w-14 text-center text-{8px}">
              <p
                className={`text-white ${
                  activeStep === 1 ? 'opacity-100' : 'opacity-50'
                } text-[10px]`}
              >
                Thématiques
              </p>
            </div>
          </Step>

          <Step
            className="h-4 w-4 p-3 !bg-blue-50 text-white/75 cursor-pointer !bg-white"
            activeClassName="ring-0 !bg-[#018FFB] text-[#018FFB]"
            completedClassName="!bg-[#018FFB] text-white"
            style={{
              background:
                activeStep === 2
                  ? 'linear-gradient(149.49deg, #017FFE 20.82%, #28F365 143.12%)'
                  : 'none',
            }}
            // onClick={() => setActiveStep(2)}
          >
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-[14px]">
              3
            </div>
            <div className="absolute top-8 w-max text-center text-xs ">
              <p
                className={`text-white ${
                  activeStep === 2 ? 'opacity-100' : 'opacity-40'
                } text-[10px]`}
              >
                Solo/Défi
              </p>
            </div>
          </Step>

          <Step
            className="h-4 w-4 p-3 !bg-blue-gray-50 text-white/75 cursor-pointer !bg-white"
            activeClassName="ring-0 !bg-[#018FFB] text-[#018FFB]"
            completedClassName="!bg-[#018FFB] text-white"
            style={{
              background:
                activeStep === 3
                  ? 'linear-gradient(149.49deg, #017FFE 20.82%, #28F365 143.12%)'
                  : 'none',
            }}
            // onClick={() => setActiveStep(3)}
          >
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-[14px]">
              4
            </div>
            <div className="absolute top-8 w-max text-center text-xs">
              <p
                className={`text-white ${
                  activeStep === 3 ? 'opacity-100' : 'opacity-40'
                } text-[10px]`}
              >
                Jouer
              </p>
            </div>
          </Step>
        </Stepper>
      </div>
      <br />
      <br />
    </div>
  );
};

export default Steppers;
