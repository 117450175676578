import axiosInstance from '../utils/api';
import leaguesDetails from '../constants/leagueDetails';

const REACT_APP_API_MATCH_URL = process.env.REACT_APP_API_MATCH_URL;
const REACT_APP_API_PROGNOSTIC_RELATION_URL =
  process.env.REACT_APP_API_PROGNOSTIC_RELATION_URL;
const REACT_APP_SEASON = process.env.REACT_APP_SEASON;

const sortMatches = (matches) =>
  matches.sort(
    (match1, match2) => Date.parse(match1.date) - Date.parse(match2.date)
  );

export const getLeagues = async () => {
  try {
    const results = await axiosInstance(
      REACT_APP_API_MATCH_URL,
      'get',
      'leagues'
    );

    const mappedLeagues = results.map((league) => {
      const detail = leaguesDetails.find(
        (value) =>
          value.name.replace(/\s/g, '').toLowerCase() ===
          league.name.replace(/\s/g, '').toLowerCase()
      );

      return {
        ...league,
        logo: detail?.logo,
      };
    });

    return mappedLeagues;
  } catch (error) {
    console.error('Error in getLeagues:', error);
  }
};

export const getClosestMatches = async (leagueId) => {
  try {
    const res = await axiosInstance(
      REACT_APP_API_MATCH_URL,
      'get',
      `matches/${leagueId}/closest/`,
      {}
    );
    return sortMatches(res);
  } catch (error) {
    console.error(error);
  }
};

export const getAllMatchesByRound = async (leagueId, roundId) => {
  try {
    const res = await axiosInstance(
      REACT_APP_API_MATCH_URL,
      'get',
      `matches/${leagueId}/${roundId}`,
      {}
    );
    return sortMatches(res);
  } catch (error) {
    console.error(error);
  }
};

export const getAllRoundsByLeague = async (leagueId) => {
  try {
    const res = await axiosInstance(
      REACT_APP_API_MATCH_URL,
      'get',
      `leagues/${leagueId}/rounds`,
      {}
    );
    return res;
  } catch (error) {
    console.error(error);
  }
};

export const getMatchesPrognostics = async (matchIds, token) => {
  try {
    const res = await axiosInstance(
      REACT_APP_API_PROGNOSTIC_RELATION_URL,
      'post',
      `/prognostics/matches`,
      { Authorization: `Bearer ${token}` },
      { matchIds }
    );
    return res;
  } catch (error) {
    console.error(error);
  }
};

export const savePrognostic = async (prognostics, token) => {
  try {
    const res = await axiosInstance(
      REACT_APP_API_PROGNOSTIC_RELATION_URL,
      'post',
      `/prognostics`,
      { Authorization: `Bearer ${token}` },
      { ...prognostics }
    );
    return res;
  } catch (error) {
    console.error(error);
  }
};

export const deletePrognosticByMatchId = async (token, matchId) => {
  try {
    const res = await axiosInstance(
      REACT_APP_API_PROGNOSTIC_RELATION_URL,
      'delete',
      `/prognostics/${matchId}`,
      { Authorization: `Bearer ${token}` }
    );
    return res;
  } catch (error) {
    console.error(error);
  }
};

export const getGlobalRanking = async (token) => {
  try {
    const res = await axiosInstance(
      REACT_APP_API_PROGNOSTIC_RELATION_URL,
      'get',
      `/prognostics/ranking/global`,
      { Authorization: `Bearer ${token}` }
    );
    return res;
  } catch (error) {
    console.error(error);
  }
};

export const getAllLeaguesHeadlines = async () => {
  try {
    const res = await axiosInstance(
      REACT_APP_API_MATCH_URL,
      'get',
      `matches/headlines/allLeagues/${REACT_APP_SEASON}`
    );
    return res;
  } catch (error) {
    console.error(error);
  }
};
