import React from 'react';

export default function GeneralTermsOfUseText() {
  return (
    <div className="text-white text-sm mb-16">
      <div className="text-center">En vigueur au 30/01/2023</div>
      <p>
        Les présentes conditions générales d'utilisation (dites « CGU ») ont
        pour objet l'encadrement juridique des modalités de mise à disposition
        du site et des services proposés par SFEIRA APP et de définir les
        conditions d’accès et d’utilisation des services par « l'Utilisateur ».
        Les présentes CGU sont accessibles sur le site à la rubrique «CGU».
      </p>

      <div className="text-xl">
        ARTICLE 1 : Accès au site et à l’application Sfeira APP
      </div>

      <p>
        Le site www.sfeira.com est un site vitrine, permettant à l'Utilisateur
        de consulter les fonctionnalités proposées par l’application SFEIRA. Le
        site est accessible gratuitement en tout lieu à tout Utilisateur ayant
        un accès à Internet. Tous les frais supportés par l'Utilisateur pour
        accéder au service (matériel informatique, logiciels, connexion
        Internet, etc.) sont à sa charge.
      </p>

      <p>
        Le Site permet aux utilisateurs d’accéder aux réseaux sociaux de la
        Société (Facebook, Twitter, Instagram) en cliquant sur l’icône
        représentant le réseau social. Cette action amène l’utilisateur à se
        connecter ou à s’inscrire audits réseaux sociaux. La Société n’est pas
        responsable des actions effectuées par l’utilisateur sur le site de ces
        réseaux sociaux. En cas de doute, de problème ou de réclamation,
        l’utilisateur devra contacter le service client du réseau social
        concerné ou se référer à leurs Conditions générales d’utilisation.
        L’application Sfeira App permet d’accéder aux services mis en place par
        la société. Sfeira App vous permet de : Créer un profil et définir vos
        préférences footballistiques, éditer votre « Sfeira Card », contacter
        des utilisateurs du réseau SFEIRA, poster des informations / médias
        relatives à l’actualité du football, défier vos amis sur vos
        connaissances du football et suivre les résultats des matchs et de votre
        équipe préférée en direct. Ces fonctionnalités peuvent être enrichies ou
        modifiées à tout moment par la SAS SFEIRA sans que sa responsabilité ne
        puisse être engagée à ce titre.
      </p>

      <div className="text-xl">ARTICLE 2 : Accès à l’application</div>

      <p>
        Pour accéder à l’Application, vous devez posséder un terminal mobile et
        un accès au réseau Internet. L’Application est téléchargeable
        gratuitement depuis les plateformes « Apple Store » et « Google Play
        Store » sur les terminaux mobiles suivants : - téléphone mobile Iphone®
        d’Apple® disposant du système d’exploitation iOS sous iOS 12.4 minimum -
        téléphone mobile disposant du système d’exploitation Android® SDK 21
        minimum. La version du logiciel de l’Application peut être mise à jour
        régulièrement pour ajouter de nouvelles fonctions et de nouveaux
        services ou pour corriger tout bug impactant le fonctionnement optimal
        de l’application. Une fois l’Application installée sur votre terminal,
        il vous suffit de vous connecter en utilisant votre adresse e-mail ou
        tout autre système d’authentification proposé par la société. Vous vous
        engagez, lors de votre utilisation et navigation sur l’Application, à
        fournir des informations vraies, exactes et aussi complètes que
        possible. Vous vous engagez également à mettre à jour ces informations
        dans les meilleurs délais en cas de changement de celles-ci.
      </p>

      <div className="text-xl">ARTICLE 3 : Collecte des données</div>
      <p>
        Le site internet www.sfeira.com est un site vitrine ne recueillant
        aucunes informations personnelles à l’exception de l’adresse mail, du
        nom et du prénom de l’utilisateur si ce dernier s’inscrit à la
        newsletter de Sfeira. Sfeira App collecte des données personnelles. Les
        données personnelles utilisées sont principalement votre nom, prénom,
        date de naissance, club préféré, ville de résidence, adresse mail,
        numéro de téléphone, photo de profi, compétences footballistiques. Vos
        droits d’accès et de rectification peuvent être exercés en nous envoyant
        un mail à l’adresse contact@sfeira.com Sur Sfeira APP, vous pouvez
        envoyer des messages, poster des photos et des vidéos, et bien plus.
        Nous appelons « activité » tout ce que vous faites sur Sfeira App. Nous
        recueillons des informations relatives à votre activité sur nos Produits
        ainsi que les informations que vous fournissez notamment : • Le contenu
        que vous créez, comme les publications, les commentaires ou les fichiers
        vidéos et photos. • Les types de contenus que vous visualisez ou avec
        lesquels vous interagissez, et la manière dont vous interagissez avec
        eux. • Les applications et les fonctionnalités que vous utilisez et les
        actions que vous faites avec celles-ci. • Les hashtags que vous utilisez
        • L’heure, la fréquence et la durée de vos activités sur nos Produits
      </p>

      <div className="text-xl">ARTICLE 4 : Propriété intellectuelle</div>

      <p>
        Les marques, logos, signes ainsi que tous les contenus du site (textes,
        images, son…) font l'objet d'une protection par le Code de la propriété
        intellectuelle et plus particulièrement par le droit d'auteur.
        L'Utilisateur doit solliciter l'autorisation préalable du site pour
        toute reproduction, publication, copie des différents contenus. Il
        s'engage à une utilisation des contenus du site dans un cadre
        strictement privé, toute utilisation à des fins commerciales et
        publicitaires est strictement interdite. Toute représentation totale ou
        partielle de ce site par quelque procédé que ce soit, sans
        l’autorisation expresse de l’exploitant du site Internet constituerait
        une contrefaçon sanctionnée par l’article L 335-2 et suivants du Code de
        la propriété intellectuelle. Il est rappelé conformément à l’article
        L122-5 du Code de propriété intellectuelle que l’Utilisateur qui
        reproduit, copie ou publie le contenu protégé doit citer l’auteur et sa
        source.
      </p>

      <div className="text-xl">ARTICLE 5 : Responsabilité</div>

      <p>
        Les sources des informations diffusées sur le site www.sfeira.com sont
        réputées fiables mais le site ne garantit pas qu’il soit exempt de
        défauts, d’erreurs ou d’omissions. Les informations communiquées sont
        présentées à titre indicatif et général sans valeur contractuelle.
        Malgré des mises à jour régulières, le site www.sfeira.com ne peut être
        tenu responsable de la modification des dispositions administratives et
        juridiques survenant après la publication. De même, le site ne peut être
        tenue responsable de l’utilisation et de l’interprétation de
        l’information contenue dans ce site. Le site www.sfeira.com ne peut être
        tenu pour responsable d’éventuels virus qui pourraient infecter
        l’ordinateur ou tout matériel informatique de l’Internaute, suite à une
        utilisation, à l’accès, ou au téléchargement provenant de ce site. La
        responsabilité du site ne peut être engagée en cas de force majeure ou
        du fait imprévisible et insurmontable d'un tiers.
      </p>

      <div className="text-xl">ARTICLE 6 : Liens hypertextes</div>

      <p>
        Des liens hypertextes peuvent être présents sur le site. L’Utilisateur
        est informé qu’en cliquant sur ces liens, il sortira du site
        www.sfeira.com. Ce dernier n’a pas de contrôle sur les pages web sur
        lesquelles aboutissent ces liens et ne saurait, en aucun cas, être
        responsable de leur contenu.
      </p>

      <div className="text-xl">ARTICLE 7 : Cookies</div>

      <p>
        L’Utilisateur est informé que lors de ses visites sur le site, un cookie
        peut s’installer automatiquement sur son logiciel de navigation. Les
        cookies sont de petits fichiers stockés temporairement sur le disque dur
        de l’ordinateur de l’Utilisateur par votre navigateur et qui sont
        nécessaires à l’utilisation du site www.sfeira.com. Les cookies ne
        contiennent pas d’information personnelle et ne peuvent pas être
        utilisés pour identifier quelqu’un. Un cookie contient un identifiant
        unique, généré aléatoirement et donc anonyme. Certains cookies expirent
        à la fin de la visite de l’Utilisateur, d’autres restent. L’information
        contenue dans les cookies est utilisée pour améliorer le site
        www.sfeira.com. En naviguant sur le site, L’Utilisateur les accepte.
        L’Utilisateur pourra désactiver ces cookies par l’intermédiaire des
        paramètres figurant au sein de son logiciel de navigation.
      </p>

      <div className="text-xl">
        ARTICLE 8 : Droit applicable et juridiction compétente
      </div>

      <p>
        La législation française s'applique au présent contrat. En cas d'absence
        de résolution amiable d'un litige né entre les parties, les tribunaux
        français seront seuls compétents pour en connaître. Pour toute question
        relative à l’application des présentes CGU, vous pouvez joindre
        l’éditeur aux coordonnées inscrites à l’ARTICLE 1.
      </p>

      <div className="text-xl">ARTICLE 9 – DISPONIBILITE DE L’APPLICATION</div>
      <p>
        L’Application est accessible en ligne 7 jours sur 7 et 24 heures sur 24.
        Toutefois, compte tenu de la complexité du réseau internet et de
        l'afflux, à certaines heures, des utilisateurs d'internet, SFEIRA SAS
        n'offre aucune garantie de continuité du service et ne pourra voir sa
        responsabilité engagée en cas d'impossibilité temporaire d'accéder en
        tout ou partie à l’Application. Aucun droit à indemnisation n’est
        accordé au titre du présent alinéa. SFEIRA se réserve le droit de
        cesser, sans préavis, sans indemnité et à son entière discrétion, à
        titre définitif ou provisoire, de fournir tout ou partie du Service ou
        des fonctionnalités du Site.
      </p>
    </div>
  );
}
