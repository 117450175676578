import { useAuth0 } from '@auth0/auth0-react';
import React, { createContext, useState } from 'react';

export const ProfileContext = createContext();

export default function ProfileIdContextProvider({ children }) {
  const [profileId, setProfileId] = useState('');
  const [initialized, setInitialized] = useState(false);
  const { user } = useAuth0();
  if (user && profileId != user.sub && !initialized) {
    setInitialized(true);
    setProfileId(user.sub);
  }

  return (
    <ProfileContext.Provider
      value={{ profileId, setProfileId, setInitialized }}
    >
      {children}
    </ProfileContext.Provider>
  );
}
