/**
 * This service is 'private' and should not be called by other classes than './Notification'
 */

import { PushNotifications } from '@capacitor/push-notifications';
import {
  registerToken,
  showInAppMessagingInvitationNotification,
  showInAppMessagingNotification,
  showInAppNotification,
  unregisterToken,
} from './Notification';
import { INVITATION_MESSAGE, NEW_MESSAGE } from '../../constants/notifications';

// let initialized = false;
let user = null;

export const messageListener = async (callback, navigate) => {
  PushNotifications.addListener('pushNotificationReceived', (notification) => {
    const location = window.location.href;
    const splitedLocation = location.split('/');
    const restrictedLocation = splitedLocation[3] === 'messaging';
    const payload = notification.body;
    if (callback) callback();

    if (payload.data.notificationType === NEW_MESSAGE) {
      if (!restrictedLocation)
        return showInAppMessagingNotification(payload, navigate, user);
    } else if (payload.data.notificationType === INVITATION_MESSAGE) {
      if (!restrictedLocation)
        return showInAppMessagingInvitationNotification(
          payload,
          navigate,
          user
        );
    } else {
      // show normal notification banner for other notifications
      return showInAppNotification(payload.notification.body, navigate, user);
    }
  });
};

export const checkAndRequestPermission = async () => {
  console.log('Initializing push notifications');
  try {
    let permStatus = await PushNotifications.checkPermissions();

    if (permStatus.receive === 'prompt') {
      permStatus = await PushNotifications.requestPermissions();
    }

    if (permStatus.receive !== 'granted') {
      showInAppNotification(
        'Veuillez autoriser Sfeira à vous envoyer des notifications pour les recevoir'
      );
      throw new Error('User denied permissions!');
    }
    if (permStatus.receive === 'granted') {
      await PushNotifications.register();
    }

    console.log('User allowed permission');

    return true;
  } catch (error) {
    console.error('Error in checkAndRequestPermission:', error);
    return false;
  }
};

export const registerDevice = async (
  authToken,
  deviceType,
  callback,
  navigate,
  userInfo
) => {
  console.log('Registering device');
  let registered = false;
  user = userInfo;

  if (!registered) {
    // add a listener for registration, to be able to save the token
    await PushNotifications.removeAllListeners();
    await PushNotifications.addListener('registrationError', (err) => {
      console.error('Registration error: ', err.error);
    });

    const deviceToken = await PushNotifications.addListener(
      'registration',
      async (deviceToken) => {
        try {
          console.info('Registration token: ', deviceToken.value);
          console.info('Auth token: ', authToken);

          await registerToken(authToken, deviceToken.value, deviceType);
          return deviceToken.value;
        } catch (e) {
          console.error('Unable to register token :', e);
        }
      }
    );

    // Register push notifications
    await PushNotifications.register();

    // Configure default notification channel
    PushNotifications.createChannel({
      description: 'Sfeira notification channel',
      id: 'sfeira_notification_channel',
      importance: 3,
      name: 'Sfeira channel',
    });

    await messageListener(callback, navigate);
    registered = true;
    return deviceToken;
  }

  return null;
};

export const unregisterDevice = async (authToken, deviceToken) => {
  if (!authToken || !deviceToken) {
    console.error('Invalid authToken or deviceToken provided');
    return;
  }

  try {
    await PushNotifications.unregister();
    await unregisterToken(authToken, deviceToken);
  } catch (error) {
    console.error('Error during unregisterDevice:', error);
    // Propager l'erreur à l'appelant si nécessaire
    throw error;
  }
};
