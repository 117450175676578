import React, { createContext, useReducer } from 'react';
import { areObjectsEqual } from '../utils/object';

const initialFeedState = {
  mySfeiraFeed: [],
  generalFeed: [],
  generalFeedPage: 1,
};

const actions = {
  setMySfeiraFeed: (state, payload) => {
    const newPosts = state.mySfeiraFeed.filter(
      (post) => !payload.some((p) => areObjectsEqual(p, post))
    );

    return {
      ...state,
      mySfeiraFeed: [...newPosts, ...payload],
    };
  },
  setGeneralFeed: (state, payload) => {
    const newPosts = state.generalFeed.filter(
      (post) => !payload.some((p) => areObjectsEqual(p, post))
    );

    return {
      ...state,
      generalFeed: [...newPosts, ...payload],
    };
  },
  setGeneralFeedPage: (state, payload) => {
    return {
      ...state,
      generalFeedPage: payload,
    };
  },
  resetMySfeiraFeed: (state) => {
    return {
      ...state,
      mySfeiraFeed: [],
    };
  },
  resetGeneralFeed: (state) => {
    return {
      ...state,
      generalFeed: [],
      generalFeedPage: 1,
    };
  },
  resetMyFeed: (state) => {
    return {
      ...state,
      mySfeiraFeed: [],
      generalFeed: [],
      generalFeedPage: 1,
    };
  },
  removePost: (state, payload) => {
    const newMySfeiraFeed = state.mySfeiraFeed.filter(
      (feed) => feed.post._id !== payload.post._id
    );
    const newGeneralFeed = state.generalFeed.filter(
      (feed) => feed.post._id !== payload.post._id
    );

    return {
      mySfeiraFeed: [...newMySfeiraFeed],
      generalFeed: [...newGeneralFeed],
    };
  },
  updateGeneralPostComments: (state, payload) => {
    if (!state || !payload.comments || !payload.postId) {
      return;
    }
    const generalFeed = state.generalFeed;
    // console.log('feed posts ', generalFeed);

    const targetPost = generalFeed.find(
      (post) => post?.post?._id === payload.postId
    );
    // console.log('target posts: ', targetPost);
    if (targetPost) {
      const targetIndex = generalFeed.indexOf(targetPost);
      if (targetIndex >= 0) {
        generalFeed[targetIndex] = {
          ...targetPost,
          post: { ...targetPost.post, comments: payload.comments },
        };
      }
    }
    return {
      ...state,
      generalFeed,
    };
  },
};

const FeedContext = createContext(initialFeedState);

const reducer = (state, { payload, type }) => {
  if (actions[type]) {
    return actions[type](state, payload);
  }
  return state;
};

const FeedContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialFeedState);

  return (
    <FeedContext.Provider value={{ feedDispatch: dispatch, feedState: state }}>
      {children}
    </FeedContext.Provider>
  );
};

export { FeedContext, FeedContextProvider };
