import React from 'react';
import Page from '../../components/Page';
import MainHeader from '../../layouts/main/MainHeader';

import { Link } from 'react-router-dom';
import Image from './component/image-background';

const Classement = () => {
  const theme = [
    {
      id: 1,
      imageSrc: `${process.env.REACT_APP_AUTH0_REDIRECT_URI}/assets/images/point.png`,
      nomTheme: 'Solo',
      typeJeu: 'solo',
    },
    {
      id: 2,
      imageSrc: `${process.env.REACT_APP_AUTH0_REDIRECT_URI}/assets/images/defi.png`,
      nomTheme: 'Défi',
      typeJeu: 'défi',
    },
    {
      id: 3,
      imageSrc: `${process.env.REACT_APP_AUTH0_REDIRECT_URI}/assets/images/global.png`,
      nomTheme: 'Global',
      typeJeu: 'global',
    },
    {
      id: 4,
      imageSrc: `${process.env.REACT_APP_AUTH0_REDIRECT_URI}/assets/images/Question.png`,
      nomTheme: 'Thèmes',
      typeJeu: 'thème',
    },
  ];

  return (
    <Page
      className="flex flex-col h-full min-h-screen justify-center items-center relative overflow-hidden"
      title="Quizz"
    >
      <Image />
      <div className="pt-3.5">
        <MainHeader items={2} leftText="Classement" />
      </div>

      <h4 className="text-white">Résultats</h4>
      {theme.map((dataset) => {
        return (
          <div
            className="bg-[#1A2342] rounded-md my-2 flex items-center p-2.5"
            key={dataset.id}
          >
            <div className={dataset.id == 1 ? 'w-12 mr-5 ml-1' : 'w-12 mr-6'}>
              <img src={dataset.imageSrc} className="h-10" />
            </div>
            <span className=" text-white">{dataset.nomTheme}</span>
            <button className="ml-auto">
              <Link to={`/quizz/quizz-podium/${dataset.typeJeu}`}>
                <img
                  src={
                    process.env.REACT_APP_AUTH0_REDIRECT_URI +
                    '/assets/images/Arrow-left.png'
                  }
                  className="h-5"
                />
              </Link>
            </button>
          </div>
        );
      })}
    </Page>
  );
};

export default Classement;
