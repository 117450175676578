// test if id is objectID, return true or false
export function isValidObjectId(str) {
  const validHexCharacters = /^[0-9a-fA-F]{24}$/;
  return validHexCharacters.test(str);
}

export function formatAppleUserId(userId) {
  return userId?.startsWith('apple') ? userId.replace(/[.]/g, '_') : userId;
}

export function revertAppleUserId(userId) {
  return userId?.startsWith('apple') ? userId.replace(/[_]/g, '.') : userId;
}
