import { useAuth0 } from '@auth0/auth0-react';
import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
import { createContext, useEffect, useReducer } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { getDatabaseUserInfo } from '../services/User';
import { unregisterDevice as unregisterNativeDevice } from '../services/notifications/NativeNotification';
import { unregisterDevice as unregisterWebDevice } from '../services/notifications/WebNotification';
// import useFetchAuth0Token from '../hooks/fetch/auth0/useFetchAuth0Token';
import { Device } from '@capacitor/device';
import { linksWithoutSignup } from '../constants/linksWithoutSignup';
import useFetchAllRelationsOfAnUser from '../hooks/fetch/relation/useFetchAllRelationsOfAnUser';
import useFetchUserRelations from '../hooks/fetch/relation/useFetchUserRelation';
// import setAxiosAuthorization from '../utils/sessionToken';

const REACT_APP_AUTH0_REDIRECT_URI = process.env.REACT_APP_AUTH0_REDIRECT_URI;
const isPushNotificationsAvailable =
  Capacitor.isPluginAvailable('PushNotifications');

const initialState = {
  userInfo: null,
  userFollowRelation: null,
  userFollowingRelation: null,
  userFavoriteClubRelation: null,
  userSuggestionsRelation: null,
  isLoading: false,
  userDeviceToken: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { userInfo } = action.payload;
    return {
      ...state,
      userInfo,
      isLoading: true,
    };
  },
  USER_DEVICE_TOKEN: (state, action) => {
    const { deviceToken } = action.payload;
    return {
      ...state,
      userDeviceToken: deviceToken,
    };
  },
  USER_FOLLOW_RELATION: (state, action) => {
    const { relationData } = action.payload;
    return {
      ...state,
      userFollowRelation: relationData,
    };
  },
  USER_FOLLOWING_RELATION: (state, action) => {
    const { relationData } = action.payload;
    return {
      ...state,
      userFollowingRelation: relationData,
    };
  },
  USER_FAVORITE_CLUB_RELATION: (state, action) => {
    const { relationData } = action.payload;
    return {
      ...state,
      userFavoriteClubRelation: relationData,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isLoading: false,
    userInfo: null,
  }),
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  logouts: () => Promise.resolve(),
  reloadUserFollowRelations: () => Promise.resolve(),
});

function AuthUserProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { user, logout, getAccessTokenSilently, error, loginWithRedirect } =
    useAuth0();
  // const { auth0Token } = useFetchAuth0Token();
  const { fetchAllRelationsOfAnUser } = useFetchAllRelationsOfAnUser();
  const { fetchUserRelation } = useFetchUserRelations();
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    const initialize = async () => {
      try {
        if (user) {
          const token = await getAccessTokenSilently();
          const isLinkWithoutSignup = linksWithoutSignup.some((link) =>
            pathname.startsWith(link)
          );

          // Get user profile datas
          const userProfileData = await getDatabaseUserInfo(user.sub, token);
          if (userProfileData) {
            if (userProfileData.calibrated || isLinkWithoutSignup) {
              dispatch({
                type: 'INITIALIZE',
                payload: {
                  userInfo: userProfileData,
                  isLoading: true,
                },
              });
            } else {
              navigate('/signup');
            }
          } else if (!isLinkWithoutSignup) {
            navigate('/signup');
          }

          // Get user relation's list
          const userRelations = await fetchAllRelationsOfAnUser();
          if (userRelations.length > 0) {
            userRelations.forEach((userRelation) => {
              dispatch({
                type: userRelation.type,
                payload: {
                  relationData: userRelation.relationData,
                },
              });
            });
          }
        } else {
          resetToInitalStates();
        }
      } catch (err) {
        console.log('error from auth0', error);
        // logouts();
        resetToInitalStates();
        // Handle an expired token by redirecting the user to login
        if (err.error === 'invalid_grant') {
          loginWithRedirect({
            appState: {
              returnTo: '/',
            },
            async openUrl(url) {
              // Redirect using Capacitor's Browser plugin
              await Browser.open({
                url,
                windowName: '_self',
              });
            },
          });
        }
      }
    };

    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const resetToInitalStates = () => {
    dispatch({
      type: 'INITIALIZE',
      payload: {
        userInfo: null,
        isLoading: false,
      },
    });
    dispatch({
      type: 'USER_FOLLOW_RELATION',
      payload: {
        userFollowRelation: null,
        // isLoading: true,
      },
    });
    dispatch({
      type: 'USER_FOLLOWING_RELATION',
      payload: {
        userFollowingRelation: null,
        // isLoading: true,
      },
    });
    dispatch({
      type: 'USER_FAVORITE_CLUB_RELATION',
      payload: {
        userFavoriteClubRelation: null,
        // isLoading: true,
      },
    });
    // dispatch({
    //   type: 'USER_DEVICE_TOKEN',
    //   payload: {
    //     deviceToken: null,
    //   },
    // });
  };

  const logouts = async (backTo) => {
    const authToken = await getAccessTokenSilently();
    // Unregister device for disabling notifications
    await (async () => {
      if (isPushNotificationsAvailable) {
        await unregisterNativeDevice(authToken, state.userDeviceToken);
      } else {
        await unregisterWebDevice(authToken, state.userDeviceToken);
      }
    })();

    // Clear localStorage apart from provicy accepted
    if (localStorage.getItem('Sfeira.privacy-policy.accepted')) {
      localStorage.clear();
      localStorage.setItem('Sfeira.privacy-policy.accepted', true);
    } else {
      localStorage.clear();
    }

    dispatch({ type: 'LOGOUT' });
    logout({
      logoutParams: {
        returnTo: REACT_APP_AUTH0_REDIRECT_URI + (backTo ?? ''),
      },
      async openUrl(url) {
        // Redirect using Capacitor's Browser plugin
        await Browser.open({
          url,
          windowName: '_self',
        });
      },
    });
    const info = await Device.getInfo();
    if (info.platform === 'ios') {
      navigate('/');
    }
  };

  const reloadUserFollowRelations = async () => {
    const data = {
      query: {
        userId: user?.sub,
        relationType: 'FOLLOW_REQ',
      },
    };

    const userFetchedRelation = await fetchUserRelation(data);

    if (userFetchedRelation) {
      dispatch({
        type: 'USER_FOLLOWING_RELATION',
        payload: {
          relationData: userFetchedRelation,
        },
      });
    }
  };

  const setUserDeviceToken = async (deviceToken) => {
    dispatch({
      type: 'USER_DEVICE_TOKEN',
      payload: {
        deviceToken,
      },
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        logouts,
        reloadUserFollowRelations,
        setUserDeviceToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthUserProvider };
