import axios from 'axios';
import React, { useEffect, useState } from 'react';
import DynamicSVG from '../../components/DynamicSVG';

import { useNavigate, useParams } from 'react-router-dom';
import MainHeader from '../../layouts/main/MainHeader';
// import { useAuth0 } from '@auth0/auth0-react';
import Steppers from './Steppers';
import Image from './component/image-background';

const QuizzList = () => {
  const [themeData, setThemedata] = useState([]);
  const [fetchQuizz] = useState([]);
  const [choisi, setChoisi] = useState('');
  const navigate = useNavigate();

  const { id, nbr } = useParams();

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_BACK_OFFICE_URL}levels/${id}`)
      // .get(`http://localhost:8080/levels/${id}`)
      .then((res) => setThemedata(res.data))
      .catch((err) => console.log(err));
  }, [fetchQuizz]);

  // const handleSearch = (event) => {
  //   if (event.target.value.length >= 3) {
  //     setFetchQuizz(event.target.value);
  //   }
  // };

  // const handleInvite = async (quizz) => {
  //   // const quizz = e.target.value
  //   try {
  //     const invitationData = {

  //       senderId: sendId,
  //       user: userId,
  //       levels: quizz._id,

  //     };

  //     // Envoyer l'invitation au serveur
  //     const response = await axios.post(`${process.env.REACT_APP_API_BACK_OFFICE_URL}invite/create`, invitationData);

  //     console.log("Invitation envoyée avec succès :", response.data);
  //   } catch (error) {
  //     console.error("Erreur lors de l'envoi de l'invitation :", error);
  //   }
  // };

  const levelImg = (quizz) => {
    if (quizz.questlevel === 1) {
      return {
        imageSrc: `${process.env.PUBLIC_URL}/assets/images/facile.png`,
        text: 'Facile',
      };
    } else if (quizz.questlevel === 2) {
      return {
        imageSrc: `${process.env.PUBLIC_URL}/assets/images/moyen.png`,
        text: 'Moyen',
      };
    } else if (quizz.questlevel === 3) {
      return {
        imageSrc: `${process.env.PUBLIC_URL}/assets/images/difficile.png`,
        text: 'Difficile',
      };
    }
  };

  return (
    <div className="w-full">
      <Image />
      <MainHeader items={2} leftText="Quiz" />
      <Steppers />

      {/* <div className="flex items-stretch mt-5 space-x-4 mb-2">
        <select
          className="text-sm p-3 py-3 my-2 text-black bg-white rounded-full align-middle items-center w-full"
          placeholder="test"
        >
          <option>Période</option>
          {themeData.map((themeDataConfig) => (
            <option key={themeDataConfig._id}>{themeDataConfig.name}</option>
          ))}
        </select>
        <select className="text-sm p-3 py-3 my-2 text-black bg-white rounded-full align-middle items-center w-full">
          <option>Difficulté</option>
          {themeData.map((themeDataConfig) => (
            <option key={themeDataConfig._id}>{themeDataConfig.name}</option>
          ))}
        </select>
      </div> */}
      <p className="text-white text-[16px] mt-5">Selectionner un niveau</p>
      <div className="w-full">
        {themeData
          .filter((fetchQuizz) => fetchQuizz)
          .map((quizz) => {
            return (
              <div key={quizz._id}>
                <div
                  className={`bg-[#1A2342] rounded-md my-2 p-1.5 flex justify-between items-center w-full ${
                    quizz._id === choisi?._id
                      ? 'border-2 border-solid border-[#36EBCA] p-2'
                      : ''
                  }  `}
                  onClick={() => setChoisi(quizz)}
                >
                  {/* <div> */}
                  <DynamicSVG
                    src="./assets/icons/ic_question.svg"
                    // className="w-1 h-1 absolute pl-2 pb-4 "
                  />
                  <div className="p-2 w-60">
                    <span className="text-white">{quizz.themes.nomTheme}</span>
                    <div className="flex justify-between">
                      <div className="flex items-center  text-xs text-white mr-0.5">
                        {/* <li className="text-grey text-xs pl-16 flex items-stretch "> */}{' '}
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            '/assets/images/mdi_calendar-outline.png'
                          }
                          alt="Image"
                          className="rounded-none w-4 h-4 mr-1"
                        />
                        {quizz?.period ? quizz?.period : ''}
                      </div>
                      <div className="flex items-center text-xs text-white">
                        <img
                          src={levelImg(quizz).imageSrc}
                          alt="Image"
                          className="rounded-none w-3 h-3 mr-1"
                        />
                        {levelImg(quizz).text}
                      </div>
                      {/* </li> */}
                    </div>
                  </div>
                  <div className="flex items-center text-xs w-[200px]  text-white pl-10">
                    <img
                      src={process.env.PUBLIC_URL + '/assets/images/Vector.png'}
                      alt="Image"
                      className="rounded-none w-4 h-4 mr-1"
                    />
                    <span>350 points</span>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
      <div className="flex justify-center">
        {/* <Link className="text-sm text-center py-3 my-6 text-white bg-[#018FFB] rounded-full w-1/2 mb-24  "   to={userId
                  ? `/quizz/start-defi/${choisi._id}/${userId}`
                  : `/quizz/jouer-seul/${choisi._id}`}
                  onClick={handleInvite(choisi)}> */}
        <button
          className={`text-sm text-center py-3 my-6 text-white bg-[#018FFB] rounded-full w-1/2 mb-24 ${
            choisi ? '' : 'cursor-not-allowed pointer-events-none opacity-70'
          }`}
          onClick={() => navigate(`/quizz/choix/${choisi._id}/${nbr}`)}
        >
          Continue
        </button>
      </div>
    </div>
  );
};

export default QuizzList;
