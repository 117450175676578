import DynamicSVG from '../../../../components/DynamicSVG';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import LegalNoticeText from '../../../privacy/LegalNoticeText';

export default function LegalNotice() {
  const navigate = useNavigate();
  return (
    <div className="mt-6 text-white w-full">
      <div className="text-center mb-8">Mentions légales</div>
      <DynamicSVG
        src="./assets/icons/ic_arrow_left.svg"
        className="cursor-pointer absolute top-10 left-5"
        strokeColor="#FFFFFF"
        onClick={() => {
          navigate(`/profile/settings`);
        }}
      />
      <LegalNoticeText />
    </div>
  );
}
