import { useAuth0 } from '@auth0/auth0-react';
import useFetchUserRelations from './useFetchUserRelation';
// import useFetchAuth0Token from '../auth0/useFetchAuth0Token';

const useFetchAllRelationsOfAnUser = () => {
  const { user } = useAuth0();
  // const { auth0Token } = useFetchAuth0Token();
  const { fetchUserRelation } = useFetchUserRelations();

  const relationTypes = [
    {
      type: 'USER_FOLLOW_RELATION',
      data: {
        query: {
          userId: user?.sub,
          relationType: 'FOLLOW',
        },
      },
    },
    {
      type: 'USER_FOLLOWING_RELATION',
      data: {
        query: {
          userId: user?.sub,
          relationType: 'FOLLOW_REQ',
        },
      },
    },
    {
      type: 'USER_FAVORITE_CLUB_RELATION',
      data: {
        query: {
          userId: user?.sub,
          relationType: 'FAVORITE_CLUB',
        },
      },
    },
    // TODO: add FAVORITE_AMATEUR_CLUB relation here
  ];

  const fetchAllRelationsOfAnUser = async () => {
    const fetchedRelations = [];

    for (const relationType of relationTypes) {
      const userRelation = await fetchUserRelation(relationType.data);
      if (userRelation.length > 0) {
        fetchedRelations.push({
          type: relationType.type,
          relationData: userRelation,
        });
      }
    }

    return fetchedRelations;
  };

  return {
    fetchAllRelationsOfAnUser,
  };
};

export default useFetchAllRelationsOfAnUser;
