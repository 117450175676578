import { useAuth0 } from '@auth0/auth0-react';

import { Browser } from '@capacitor/browser';
import { useCallback, useEffect } from 'react';

const REACT_APP_AUTH0_REDIRECT_URI = process.env.REACT_APP_AUTH0_REDIRECT_URI;
export default function AuthGuard({ children, redirectTo, screenHint }) {
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  const loginAuth0 = useCallback(async () => {
    try {
      if (!isAuthenticated) {
        loginWithRedirect({
          appState: {
            returnTo: redirectTo ? redirectTo : REACT_APP_AUTH0_REDIRECT_URI,
          },
          authorizationParams: {
            screen_hint: screenHint ?? 'signin',
          },
          async openUrl(url) {
            // Redirect using Capacitor's Browser plugin
            await Browser.open({
              url,
              windowName: '_self',
            });
          },
        });
      }
    } catch (e) {
      if (e.error === 'invalid_grant' || e.error === 'missing_refresh_token') {
        loginWithRedirect({
          appState: {
            returnTo: redirectTo ? redirectTo : REACT_APP_AUTH0_REDIRECT_URI,
          },
          async openUrl(url) {
            // Redirect using Capacitor's Browser plugin
            await Browser.open({
              url,
              windowName: '_self',
            });
          },
        });
      }
    }
  }, [loginWithRedirect]);

  useEffect(() => {
    loginAuth0();
  }, [isAuthenticated]);

  return <>{isAuthenticated && children}</>;
}
