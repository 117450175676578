import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import OneChamp from './OneChamp';
import DynamicSVG from '../../components/DynamicSVG';
import { DEFAULT_SVG_STROKE } from '../../utils/svgColor';
import useLeagues from '../../hooks/useLeagues';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import useCreateRelation from '../../hooks/fetch/relation/useCreateRelation';
import useFetchAuth0Token from '../../hooks/fetch/auth0/useFetchAuth0Token';
import useUpdateRelation from '../../hooks/fetch/relation/useUpdateRelation';
import useFetchUserRelations from '../../hooks/fetch/relation/useFetchUserRelation';
import { useAuth0 } from '@auth0/auth0-react';
import { getCityName } from '../../constants/cityName';

export default function FavoriteChamps() {
  const [starSelected, setStarSelected] = useState({});
  const [allLeagues, setAllLeagues] = useState([]);
  const navigate = useNavigate();
  const [leagues] = useLeagues();
  const [searchQuery, setSearchQuery] = useState('');
  const { auth0Token } = useFetchAuth0Token();
  const { fetchUserRelation } = useFetchUserRelations();
  const { createRelation } = useCreateRelation();
  const { updateRelation } = useUpdateRelation();
  const { user } = useAuth0();

  useEffect(() => {
    const startData = async () => {
      const initLeagues = await findFavoriteLeagueRelation();
      if (initLeagues.length) {
        const newStarSelected = {};
        initLeagues[0].endId.forEach((league) => {
          newStarSelected[league.externalId] = true;
        });

        setStarSelected(newStarSelected);

        const leaguesWithProperties = leagues.map((league) => {
          const cityName = getCityName(league.name);
          return {
            logo: cityName.logo,
            ...cityName,
            ...league,
            country: cityName.name,
          };
        });

        const selectedLeagues = initLeagues[0].endId
          .map((league) => {
            const foundLeague = leaguesWithProperties.find(
              (l) => l.externalId === league.externalId
            );
            return foundLeague ? { ...foundLeague } : null;
          })
          .filter(Boolean);

        const unselectedLeagues = leaguesWithProperties.filter(
          (league) => !newStarSelected[league.externalId]
        );

        const updatedLeagues = [...selectedLeagues, ...unselectedLeagues];

        setAllLeagues(updatedLeagues);
      } else {
        const leaguesWithProperties = leagues.map((league) => {
          const cityName = getCityName(league.name);
          return {
            logo: cityName.logo,
            ...cityName,
            ...league,
            country: cityName.name,
          };
        });
        setAllLeagues(leaguesWithProperties);
      }
    };

    startData();
  }, [leagues]);

  useEffect(() => {
    const fetchData = async () => {
      const selectedLeagues = allLeagues.filter(
        (league) => starSelected[league.externalId]
      );
      let favLeague = await findFavoriteLeagueRelation();
      if (user && user?.sub && auth0Token) {
        if (selectedLeagues.length > 0) {
          if (favLeague.length) {
            const relationId = favLeague[0]._id;
            updateFavoriteLeagueRelation(selectedLeagues, relationId);
          } else {
            createFavoriteLeagueRelation(selectedLeagues);
          }
        }
        if (!selectedLeagues.length && favLeague.length) {
          const relationId = favLeague[0]._id;
          updateFavoriteLeagueRelation(selectedLeagues, relationId);
        }
      }
    };
    fetchData();
  }, [starSelected, allLeagues, user, auth0Token]);

  const goBack = () => {
    navigate(-1);
  };

  const createFavoriteLeagueRelation = async (clubIds) => {
    await createRelation(auth0Token, {
      relationType: 'FAVORITE_LEAGUE',
      endId: clubIds,
    });
  };

  const findFavoriteLeagueRelation = async () => {
    const data = {
      query: {
        userId: user?.sub,
        relationType: 'FAVORITE_LEAGUE',
      },
    };

    const userFetchedRelation = await fetchUserRelation(data);
    return userFetchedRelation;
  };

  const updateFavoriteLeagueRelation = async (favLeagues, relationId) => {
    await updateRelation(auth0Token, favLeagues, relationId);
  };

  const handleStarClick = (externalId) => {
    const updatedSelected = { ...starSelected };
    updatedSelected[externalId] = !updatedSelected[externalId];
    setStarSelected(updatedSelected);

    const updatedLeagues = allLeagues.slice().sort((a, b) => {
      const aSelected = updatedSelected[a.externalId] ? -1 : 1;
      const bSelected = updatedSelected[b.externalId] ? -1 : 1;
      return aSelected - bSelected;
    });

    setAllLeagues(updatedLeagues);
  };

  const filtered = allLeagues.filter(
    (league) =>
      (league.name &&
        league.name.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (league.country &&
        league.country.toLowerCase().includes(searchQuery.toLowerCase()))
  );

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates })
  );

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext
        items={allLeagues.map((league) => league.externalId)}
        strategy={verticalListSortingStrategy}
      >
        <div className="flex flex-col items-center py-3 gap-3 w-full mb-14 ">
          <div className="flex flex-row w-full pb-4 pt-12">
            <DynamicSVG
              src={`./assets/icons/ic_arrow_left.svg`}
              className="cursor-pointer"
              onClick={goBack}
              strokeColor={DEFAULT_SVG_STROKE}
            />
            <input
              className="ml-2 text-primaryLight-background text-opacity-50 w-full outline-none"
              placeholder="Rechercher une compétition, un pays ..."
              style={{ backgroundColor: 'transparent' }}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
          <div className="border-b border-white border-opacity-25 w-screen"></div>
          {searchQuery ? (
            <div className="flex flex-col gap-2 w-full p-2">
              {filtered.length > 0 ? (
                filtered.map((league, index) => (
                  <OneChamp
                    key={index}
                    selected={starSelected[league.externalId]}
                    onClick={() => handleStarClick(league.externalId)}
                    data={league}
                  />
                ))
              ) : (
                <div className="px-1 bg-primaryDark-background mb-14 pb-5">
                  <p className="text-primaryDark-color text-center mt-10">
                    Aucune ligue correspondante !
                  </p>
                </div>
              )}
            </div>
          ) : (
            <div className="flex flex-col gap-2 w-full p-2">
              {allLeagues.map((league, index) => (
                <OneChamp
                  key={index}
                  selected={starSelected[league.externalId]}
                  onClick={() => handleStarClick(league.externalId)}
                  data={league}
                />
              ))}
            </div>
          )}
        </div>
      </SortableContext>
    </DndContext>
  );

  function handleDragEnd(event) {
    const { active, over } = event;
    // Check if active and over are defined
    if (active && over && active.id !== over.id) {
      setAllLeagues((items) => {
        const oldIndex = items.findIndex(
          (item) => item.externalId === active.id
        );
        const newIndex = items.findIndex((item) => item.externalId === over.id);

        // Check if oldIndex and newIndex are valid
        if (oldIndex !== -1 && newIndex !== -1) {
          return arrayMove(items, oldIndex, newIndex);
        }

        return items;
      });
    }
  }
}
