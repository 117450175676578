export const getCityName = (league) => {
  switch (league) {
    case 'Premier League':
      return {
        name: 'ENGLAND',
        logo: `/assets/icons/ic_PL_logo.svg`,
      };
    case 'Ligue 1':
      return {
        name: 'FRANCE',
        logo: `/assets/icons/ic_L1_logo.svg`,
      };
    case 'Bundesliga':
      return {
        name: 'GERMANY',
        logo: `/assets/icons/ic_Bundesliga_logo.svg`,
      };
    case 'Serie A':
      return {
        name: 'ITALIA',
        logo: `/assets/icons/ic_serieA_logo.svg`,
      };
    case 'La Liga':
      return {
        name: 'SPAIN',
        logo: `/assets/icons/ic_LaLiga_logo.svg`,
      };
    case 'Jupiler Pro League':
      return {
        name: 'BELGIUM',
        logo: `/assets/images/img_jupiler_league.png`,
      };
    case 'UEFA Champions League':
      return {
        name: 'EUROPE',
        logo: `/assets/icons/ic_uefa_champions_league.svg`,
      };
    case 'Ligue 2':
      return {
        name: 'FRANCE',
        logo: `/assets/icons/ic_L2_logo.svg`,
      };
    case 'National 1':
      return {
        name: 'FRANCE',
        logo: `/assets/images/img_ligue_1.png`,
      };
    case 'World Cup - Women':
      return {
        name: 'WORLD',
        logo: `/assets/images/img_word_cup_women.png`,
      };
    case 'UEFA Europa League':
      return {
        name: 'EUROPE',
        logo: `/assets/images/img_europa_league.png`,
      };
    case 'Primeira Liga':
      return {
        name: 'PORTUGAL',
        logo: `/assets/images/img_primeira_liga.png`,
      };
    case 'Africa Cup of Nations':
      return {
        name: 'AFRICA',
        logo: `/assets/images/img_can.png`,
      };
    case 'UEFA Europa Conference League':
      return {
        name: 'EUROPE',
        logo: `/assets/images/img_conference_league.png`,
      };
    case 'Euro Championship - Qualification':
      return {
        name: 'EUROPE',
        logo: `/assets/images/img_euro.png`,
      };
    case 'Friendlies':
      return {
        name: 'WORLD',
        logo: `/assets/images/img_friendlies.png`,
      };
    case 'FA Cup':
      return {
        name: 'ENGLAND',
        logo: `/assets/images/league/45.png`,
      };
    case 'League Cup':
      return {
        name: 'ENGLAND',
        logo: `/assets/images/league/48.png`,
      };
    case 'Feminine Division 1':
      return {
        name: 'FRANCE',
        logo: `/assets/images/league/64.png`,
      };
    case 'Coupe de France':
      return {
        name: 'FRANCE',
        logo: `/assets/images/league/66.png`,
      };
    case 'DFB Pokal':
      return {
        name: 'GERMANY',
        logo: `/assets/images/league/81.png`,
      };
    case 'Frauen Bundesliga':
      return {
        name: 'GERMANY',
        logo: `/assets/images/league/82.png`,
      };
    case 'Taça de Portugal':
      return {
        name: 'PORTUGAL',
        logo: `/assets/images/league/96.png`,
      };
    case 'Coppa Italia':
      return {
        name: 'ITALY',
        logo: `/assets/images/league/137.png`,
      };
    case 'Serie A Women':
      return {
        name: 'ITALY',
        logo: `/assets/images/league/139.png`,
      };
    case 'Primera División Femenina':
      return {
        name: 'SPAIN',
        logo: `/assets/images/league/142.png`,
      };
    case 'Copa del Rey':
      return {
        name: 'SPAIN',
        logo: `/assets/images/league/143.png`,
      };
    case 'Super League Women':
      return {
        name: 'BELGIUM',
        logo: `/assets/images/league/146.png`,
      };
    case 'Cup':
      return {
        name: 'BELGIUM',
        logo: `/assets/images/league/147.png`,
      };
    case 'Trophée des Champions':
      return {
        name: 'FRANCE',
        logo: `/assets/images/league/526.png`,
      };
    case 'Community Shield':
      return {
        name: 'ENGLAND',
        logo: `/assets/images/league/528.png`,
      };
    case 'Super Cup':
      return {
        name: 'GERMANY',
        logo: `/assets/images/league/529.png`,
      };
    case 'SuperCoppa':
      return {
        name: 'ITALY',
        logo: `/assets/images/league/547.png`,
      };
    case 'SuperCopa de Espana':
      return {
        name: 'SPAIN',
        logo: `/assets/images/league/556.png`,
      };
    case "Women's Championship":
      return {
        name: 'ENGLAND',
        logo: `/assets/images/league/699.png`,
      };
    case 'Copa Federacion':
      return {
        name: 'SPAIN',
        logo: `/assets/images/league/735.png`,
      };
    case '1a Divisão - Women':
      return {
        name: 'PORTUGAL',
        logo: `/assets/images/league/948.png`,
      };

    default:
      return {
        name: 'EUROPE',
        logo: `/assets/images/img_premier_league.png`,
      };
  }
};
