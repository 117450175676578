import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Page from '../../components/Page';
// import { CircularProgressbar } from 'react-circular-progressbar';
import { useAuth0 } from '@auth0/auth0-react';
import 'react-circular-progressbar/dist/styles.css';
import { useSelector } from 'react-redux';
import MainHeader from '../../layouts/main/MainHeader';
import Image from './component/image-background';
// import GradientSVG from './Gradient';
import CustomCircularProgress from './CustomCircularProgress';

function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

const QuizzQuestion = () => {
  const [questions, setQuestions] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  // const [userAnswer, setUserAnswer] = useState("");
  const [countCorrect, setCountCorrect] = useState(0);
  const [getAnswer, setAnswer] = useState('');
  const [current, setCurrent] = useState([]);

  const [correctCount, setCorrectCount] = useState(0);
  const [incorrectCount, setIncorrectCount] = useState(0);
  const [unansweredCount, setUnansweredCount] = useState(0);

  const [timeRemaining, setTimeRemaining] = useState(20);
  const [timers, setTimer] = useState(0);
  const [cliquer, setCliquer] = useState(false);

  const { id, nbr, type } = useParams();
  const [players, setPlayers] = useState([]);
  const [counter, setCounter] = useState(0);
  const [scores, setScores] = useState([]);
  const buttonRef = useRef(null);

  const navigate = useNavigate();

  // const { getAccessTokenSilently } = useAuth0();

  const quizId = useSelector((state) => state.liste.value);
  const findScoreById = (idUser) => {
    const index = scores.findIndex((score) => score.user === idUser);
    if (index > -1) {
      return scores[index].score;
    }
  };

  const handleClickResult = () => {
    if (currentStep + 1 === questions.length) {
      navigate(`/quizz/result-challenge`, {
        state: {
          correctCount,
          incorrectCount,
          unansweredCount,
          id,
          completionPercentage,
          totalpoint,
          themes: questions[0].tags[0],
        },
      });
    }
  };

  const { user } = useAuth0();
  const userId = user.sub;

  useEffect(() => {
    const fetchQuizPlayers = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BACK_OFFICE_URL}quiz/${quizId?.payload?.quizId}/players`
          // `http://localhost:8080/quiz/${quizId?.payload?.quizId}/players`
        );
        const data = response.data.players;
        const user_auth = data.filter((item) => userId === item.user_id);
        const index = data.findIndex((item) => userId === item.user_id);
        let tmp = data[0];
        data[0] = user_auth[0];
        data[index] = tmp;
        setPlayers(data);
        setScores(response.data.quiz.scores);
      } catch (error) {
        console.error(
          'Erreur lors de la récupération des joueurs du quiz',
          error
        );
      }
    };

    fetchQuizPlayers();
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      if (timeRemaining > 0) {
        setTimeRemaining(timeRemaining - 1);
        // setMax((timeRemaining * 100) / 20);
      }
      if (timeRemaining === 0) {
        if (currentStep < questions.length - 1) {
          setCurrentStep(currentStep + 1);
          setCliquer(false);
          setCurrent([]);
          setTimeRemaining(20);
        }
      }
    }, 1000);
    setTimer(timer);
    return () => {
      clearInterval(timer);
    };
  }, [timeRemaining]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_BACK_OFFICE_URL}api/gen/${id}`)
      // .get(`http://localhost:8080/api/gen/${id}`)
      .then((response) => {
        // const shuffledQuestions = shuffleArray(response.data).slice(0, 10);
        const shuffledQuestions = shuffleArray(response.data).slice(0, nbr);
        setQuestions(shuffledQuestions);
      })
      .catch((error) => {
        console.error(
          'Erreur lors de la récupération des données des questions : ',
          error
        );
      });
  }, []);

  const checkAnswer = (userAnswer, current) => {
    setCounter(counter + 1);
    setCurrent([current]);
    clearInterval(timers);

    setCliquer(true);
    if (userAnswer === questions[currentStep].rightAnswer) {
      setAnswer(userAnswer);
      setCountCorrect(countCorrect + (correctCount / nbr) * 350);
      setCorrectCount(correctCount + 1);
    } else {
      setIncorrectCount(incorrectCount + 1);
      if (
        questions[currentStep].answer1 === questions[currentStep].rightAnswer
      ) {
        setCurrent((current) => [...current, 1]);
      }
      if (
        questions[currentStep].answer2 === questions[currentStep].rightAnswer
      ) {
        setCurrent((current) => [...current, 2]);
      }
      if (
        questions[currentStep].answer3 === questions[currentStep].rightAnswer
      ) {
        setCurrent((current) => [...current, 3]);
      }
      if (
        questions[currentStep].answer4 === questions[currentStep].rightAnswer
      ) {
        setCurrent((current) => [...current, 4]);
      }
      setAnswer(questions[currentStep].rightAnswer);
    }

    setTimeout(() => {
      if (currentStep < questions.length - 1) {
        setCurrentStep(currentStep + 1);
        // setUserAnswer("");
        setCurrent([]);
        setTimeRemaining(20);
        // setIsCorrect(null);
        setCliquer(false);

        setAnswer('');
      }
    }, 2000);

    setUnansweredCount(questions.length - (correctCount + incorrectCount));
  };
  useEffect(() => {
    if (counter === questions.length && buttonRef.current) {
      buttonRef.current.click();
    }
  }, [counter]);

  const completionPercentage = (correctCount / nbr) * 100;
  let totalpoint = (correctCount / nbr) * 350;

  if (currentStep === questions.length) {
    return (
      <Page
        className="flex flex-col h-full min-h-screen justify-center items-center relative overflow-hidden"
        title="Quizz"
      ></Page>
    );
  }

  return (
    // <Page
    //   className="flex flex-col h-full min-h-screen justify-center items-center relative overflow-y-scroll"
    //   title="Quizz"
    // >
    <div className="h-[900px]">
      <Image />
      <MainHeader items={2} leftText="Quiz" />
      <>
        {type === 'avec_amis' && (
          <div className="flex  items-center justify-center  space-x-8">
            <div className="flex flex-col items-center">
              <img
                src={
                  players[0]?.user_metadata?.profile_picture_flavors?.S ||
                  process.env.PUBLIC_URL +
                    '/assets/images/img_default_profile.png'
                }
                alt="avatar"
                className="w-12 h-12 rounded-full object-cover"
              />
              <div className="flex items-center mt-1">
                <img
                  src={process.env.PUBLIC_URL + '/assets/images/point.png'}
                  className="w-5 h-6 mr-1"
                />
                <h4 className="custom-button-point !text-xl">
                  {players[0]?.user_id == userId
                    ? Math.round(totalpoint)
                    : findScoreById(players[0]?.user_id) == undefined
                    ? 0
                    : findScoreById(players[0]?.user_id)}{' '}
                </h4>
              </div>
            </div>
            <div>
              <p className="text-white text-[12px]">VS</p>
            </div>
            <div className="flex -space-x-14 overflow-hidden">
              {/* {players?.map((invitedUser, index) => ( */}
              <div className="relative">
                {/* {index != 0 && ( */}
                <div>
                  <img
                    src={
                      players[1]?.user_metadata?.profile_picture_flavors?.S ||
                      process.env.PUBLIC_URL +
                        '/assets/images/img_default_profile.png'
                    }
                    alt="avatar"
                    className="w-12 h-12 rounded-full object-cover"
                  />
                  <div className="flex items-center mt-1">
                    <img
                      src={process.env.PUBLIC_URL + '/assets/images/point.png'}
                      className="w-5 h-6 mr-1"
                    />
                    <h4 className="custom-button-point !text-xl">
                      {/* {typeof findScoreById(invitedUser?.invitedUser)} */}
                      {players[1]?.user_id == userId
                        ? Math.round(totalpoint)
                        : findScoreById(players[1]?.user_id) == undefined
                        ? 0
                        : findScoreById(players[1]?.user_id)}{' '}
                    </h4>
                  </div>
                </div>
                {/* )} */}
              </div>
              {/* // ))} */}
            </div>
          </div>
        )}
        {type === 'partage' && (
          <div className="flex  items-center justify-center  space-x-8">
            <div className="flex flex-col items-center">
              <img
                src={
                  players[0]?.user_metadata?.profile_picture_flavors?.S ||
                  process.env.PUBLIC_URL +
                    '/assets/images/img_default_profile.png'
                }
                alt="avatar"
                className="w-12 h-12 rounded-full object-cover"
              />
              <div className="flex items-center mt-1">
                <img
                  src={process.env.PUBLIC_URL + '/assets/images/point.png'}
                  className="w-5 h-6 mr-1"
                />
                <h4 className="custom-button-point !text-xl">
                  {Math.round(totalpoint)}
                </h4>
              </div>
            </div>
            <div>
              <p className="text-white text-[12px]">VS</p>
            </div>
            <div className="flex flex-col items-center">
              <img
                src={
                  process.env.PUBLIC_URL +
                  '/assets/images/PlayerQuizhorsSfeira.png'
                }
                className="rounded-none h-[45px] mr-2 mx-2"
              />
              <div className="flex items-center mt-1">
                <img
                  src={process.env.PUBLIC_URL + '/assets/images/point.png'}
                  className="w-5 h-6 mr-1"
                />{' '}
                <h4 className="custom-button-point !text-xl">
                  0{' '}
                  {/* {players[0]?.user_id == userId
                    ? Math.round(totalpoint)
                    : findScoreById(players[0]?.user_id) == undefined
                    ? 0
                    : findScoreById(players[0]?.user_id)} */}
                </h4>
              </div>
            </div>
          </div>
        )}{' '}
        {type === 'solo' && (
          <div className="flex flex-col items-center">
            <img
              src={
                players[0]?.user_metadata?.profile_picture_flavors?.S ||
                process.env.PUBLIC_URL +
                  '/assets/images/img_default_profile.png'
              }
              alt="avatar"
              className="w-12 h-12 rounded-full object-cover"
            />
            <div className="flex items-center mt-1">
              <img
                src={process.env.PUBLIC_URL + '/assets/images/point.png'}
                className="w-5 h-6 mr-1"
              />{' '}
              <h4 className="custom-button-point !text-xl">
                {Math.round(totalpoint)}{' '}
              </h4>
            </div>
          </div>
        )}
        <div
          className="bg-cover bg-center bg-no-repeat w-full h-fit mt-4 mb-8"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/bg-quest.png)`,
          }}
        >
          <div className="text-white font-semibold text-lg my-4 mx-4 pt-16 py-5">
            <span>QUESTION {`${currentStep + 1} / ${questions.length}`}</span>
            <p>{questions[currentStep].question}</p>
          </div>
        </div>
        <div className="w-64 m-auto">
          <button
            disabled={cliquer}
            className={`outline outline-offset-2 outline-white mb-5 py-2 mt-1 w-full rounded-full text-white ${
              current.includes(1) &&
              getAnswer === questions[currentStep].answer1
                ? 'outline outline-offset-2 !outline-[#28F365] text-[#28F365] '
                : ''
            } ${
              current.includes(1) &&
              questions[currentStep].answer1 !== getAnswer
                ? 'outline outline-offset-2 !outline-[#E42F5A] text-[#E42F5A]'
                : ''
            }`}
            onClick={() => checkAnswer(questions[currentStep].answer1, 1)}
          >
            {questions[currentStep].answer1}
          </button>
          <button
            disabled={cliquer}
            className={`outline outline-offset-2 outline-white mb-5 py-2 w-full rounded-full text-white ${
              current.includes(2) &&
              getAnswer === questions[currentStep].answer2
                ? 'outline outline-offset-2 !outline-[#28F365] text-[#28F365]'
                : ''
            } ${
              current.includes(2) &&
              questions[currentStep].answer2 !== getAnswer
                ? 'outline outline-offset-2 !outline-[#E42F5A] text-[#E42F5A]'
                : ''
            }`}
            onClick={() => checkAnswer(questions[currentStep].answer2, 2)}
          >
            {questions[currentStep].answer2}
          </button>
          <button
            disabled={cliquer}
            className={`outline outline-offset-2 outline-white py-2 mb-5 w-full rounded-full text-white ${
              current.includes(3) &&
              getAnswer === questions[currentStep].answer3
                ? 'outline outline-offset-2 !outline-[#28F365] text-[#28F365]'
                : ''
            } ${
              current.includes(3) &&
              questions[currentStep].answer3 !== getAnswer
                ? 'outline outline-offset-2 !outline-[#E42F5A] text-[#E42F5A]'
                : ''
            }`}
            onClick={() => checkAnswer(questions[currentStep].answer3, 3)}
          >
            {questions[currentStep].answer3}
          </button>
          <button
            disabled={cliquer}
            className={`outline outline-offset-2 outline-white py-2 mb-5 w-full rounded-full text-white ${
              current.includes(4) &&
              getAnswer === questions[currentStep].answer4
                ? 'outline outline-offset-2 !outline-[#28F365] text-[#28F365]'
                : ''
            } ${
              current.includes(4) &&
              questions[currentStep].answer4 !== getAnswer
                ? 'outline outline-offset-2 !outline-[#E42F5A] text-[#E42F5A]'
                : ''
            }`}
            onClick={() => checkAnswer(questions[currentStep].answer4, 4)}
          >
            {questions[currentStep].answer4}
          </button>
        </div>
        <div className="flex items-center justify-center">
          <CustomCircularProgress count={timeRemaining} />
        </div>
        <div className="text-center ">
          {currentStep + 1 === questions.length && (
            <button
              ref={buttonRef}
              className="bg-[#018FFB] rounded-full text-white p-2 px-6 cursor-pointer" /*  onClick={handlePoints(questions)} */
              onClick={handleClickResult}
            >
              {/* <Link
                to="/quizz/result-challenge"
                state={{
                  correctCount,
                  incorrectCount,
                  unansweredCount,
                  id,
                  completionPercentage,
                  totalpoint,
                  themes: questions[0].tags[0],
                }}
              > */}
              Mes résultats
              {/* </Link> */}
            </button>
          )}
        </div>
      </>
    </div>
    // </Page>
  );
};

export default QuizzQuestion;
