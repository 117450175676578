import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import MainHeader from '../../layouts/main/MainHeader';
import { Stepper, Step, Typography } from '@material-tailwind/react';

const QuizzStartDefi = () => {
  const { id, userId } = useParams();
  const [activeStep, setActiveStep] = useState(0);
  return (
    <div>
      <div className="fixed top-0 sm:w-1/2 md:w-2/3 lg:w-1/3 xl:w-1/4">
        <img
          src={
            process.env.REACT_APP_AUTH0_REDIRECT_URI +
            '/assets/images/img_sfeira_watermark.png'
          }
          alt="sfeira"
        />
        <div className="livescore-purple-neon"></div>
        <div className="livescore-blue-neon"></div>
      </div>
      <MainHeader items={2} leftText="Quiz" />
      <div className="h-[10px]">
        <Stepper
          activeStep={activeStep}
          lineClassName="bg-white"
          activeLineClassName="bg-[#018FFB]"
        >
          <Step
            className="h-4 w-4 !bg-blue-gray-50 text-white/75 cursor-pointer !bg-white"
            activeClassName="ring-0 !bg-[#018FFB] text-[#018FFB]"
            completedClassName=" !bg-[#018FFB] text-white"
            onClick={() => setActiveStep(0)}
          >
            1
            <div className="absolute -bottom-[2.3rem] w-max text-center text-xs">
              <Typography variant="h6" color="inherit">
                Questions
              </Typography>
            </div>
          </Step>
          <Step
            className="h-4 w-4 !bg-blue-gray-50 text-white/75 cursor-pointer !bg-white"
            activeClassName="ring-0 !bg-[#018FFB] text-[#018FFB]"
            completedClassName="!bg-[#018FFB] text-white"
            onClick={() => setActiveStep(1)}
          >
            2
            <div className="absolute -bottom-[2.3rem] w-max text-center text-xs">
              <Typography variant="h6" color="inherit">
                Thématiques
              </Typography>
            </div>
          </Step>

          <Step
            className="h-4 w-4 !bg-blue-50 text-white/75 cursor-pointer !bg-white"
            activeClassName="ring-0 !bg-[#018FFB] text-[#018FFB]"
            completedClassName="!bg-[#018FFB] text-white"
            onClick={() => setActiveStep(2)}
          >
            3
            <div className="absolute -bottom-[2.3rem] w-max text-center text-xs ">
              <Typography variant="h6" color="inherit">
                Solo/Défi
              </Typography>
            </div>
          </Step>

          <Step
            className="h-4 w-4 !bg-blue-gray-50 text-white/75 cursor-pointer !bg-white"
            activeClassName="ring-0 !bg-[#018FFB] text-[#018FFB]"
            completedClassName="!bg-[#018FFB] text-white"
            onClick={() => setActiveStep(3)}
          >
            4
            <div className="absolute -bottom-[2.3rem] w-max text-center text-xs">
              <Typography variant="h6" color="inherit">
                Jouer
              </Typography>
            </div>
          </Step>
        </Stepper>
      </div>
      <br />
      <br />
      <div className="text-center text-white leading-5">
        <div>
          <span className="text-2xl">A toi jouer !</span>
          <h2 className="flex justify-center">
            {' '}
            Montre ton expertise sur le terrain
          </h2>
        </div>
        <br />
        <div className="flex items-center justify-evenly">
          <div>
            <img
              src={
                process.env.REACT_APP_AUTH0_REDIRECT_URI +
                '/assets/images/user.png'
              }
              alt="Image 1"
              className="w-20 h-20"
            />
          </div>
          <div>
            <p className="text-white text-[29px]">VS</p>
          </div>
          <div>
            <img
              src={
                process.env.REACT_APP_AUTH0_REDIRECT_URI +
                '/assets/images/user2.png'
              }
              alt="Image 2"
              className="w-20 h-20"
            />
          </div>
        </div>
        <br />
        <div className="w-full h-[60px] bg-[#018FFB] rounded-xl flex items-center justify-center">
          <h1 className="text-center text-white text-[23px] font-bold font-['Inter'] leading-[27px]">
            Champions League Legends
          </h1>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4  h-[60px] my-4">
        <div className="flex items-center bg-[#2A2342] rounded-xl">
          <img
            src={
              process.env.REACT_APP_AUTH0_REDIRECT_URI +
              '/assets/images/Question.png'
            }
            className="w-9 h-9 mr-2 mx-2"
          />
          <div>
            <span className=" text-white ">10 questions</span>{' '}
          </div>
        </div>

        <div className="flex items-center bg-[#2A2342] rounded-xl">
          <img
            src={
              process.env.REACT_APP_AUTH0_REDIRECT_URI +
              '/assets/icons/pointcard.png'
            }
            className="rounded-none w-8 mr-2 mx-2"
          />
          <div>
            {' '}
            <span className=" text-white text-sm">+350 points</span>
          </div>
        </div>
      </div>
      {/* <p className="text-white">La compétition européenne reine des clubs reprend ses droits ce mardi. L’occasion est belle de tester votre connaissance de la Coupe aux grandes oreilles.</p>
            <div className="bg-[#2A2342] rounded-md my-5 py-3">
                <div className="flex items-center justify-evenly">
                    <div>
                    <span className=" text-white ">Vous</span>

                    </div>
                    <div>
                    <img
                            src={process.env.REACT_APP_AUTH0_REDIRECT_URI + '/assets/images/img_user_5.png'}
                            alt="Image 1"
                            className=""
                        />
                    </div>
                    <div>
                    <p className="text-white">VS</p>  

                    </div>
                    <div>
                    <img
                            className=""
                            src={process.env.REACT_APP_AUTH0_REDIRECT_URI + '/assets/images/img_user.png'}
                            alt="Image 2"
                        /> 
                    </div>
                    <div>
                    <span className=" text-white ">User</span>                                  

                    </div>
                                
                           
                </div> 
            </div> */}
      <div className="flex justify-center my-16">
        <Link
          className="text-sm text-center py-3 my-2 text-black bg-[#018FFB] rounded-full w-1/2 mb-24 "
          to={`/quizz/question/${id}/${userId}`}
        >
          Commencer le quiz
        </Link>
      </div>
    </div>
  );
};

export default QuizzStartDefi;
