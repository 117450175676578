import useMessagingFilters from '../../useMessaging';
import useGetUnreadMessagesNumber from './useGetUnreadMessagesNumber';

const useGetAllUnreadMessagesNumber = () => {
  const {
    messagingState: {
      invitationsMessages,
      groupsMessages,
      individualsMessages,
    },
    MessagingDispatch,
  } = useMessagingFilters();

  const { getUnreadMessagesNumber } = useGetUnreadMessagesNumber();

  const getAllUnreadMessagesNumber = () => {
    let unreadMessages = 0;
    const messages = [
      ...invitationsMessages,
      ...groupsMessages,
      ...individualsMessages,
    ];

    for (const message of messages) {
      const res = getUnreadMessagesNumber(message);
      unreadMessages = unreadMessages + res;
    }

    MessagingDispatch({
      type: 'setUnreadMessagesNumber',
      payload: unreadMessages,
    });
  };

  return { getAllUnreadMessagesNumber };
};

export default useGetAllUnreadMessagesNumber;
