import React from 'react';
import DynamicSVG from '../../../components/DynamicSVG';

export default function SettingItem({
  icon,
  text,
  rounded,
  action,
  disabled,
  settingImg,
}) {
  return (
    <div
      className={`flex flex-row justify-between bg-[#1a2342] py-3 pl-3 w-11/12 mx-auto cursor-pointer ${rounded} ${
        disabled ? 'text-[#4d4d4d] ' : 'text-white'
      }`}
      onClick={disabled ? null : action}
    >
      <div className="flex flew-row">
        {settingImg ? (
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/${settingImg}`}
            alt="ic"
            className="cursor-pointer mr-3 w-5 h-5"
          />
        ) : (
          <DynamicSVG
            src={`./assets/icons/${icon}`}
            className="cursor-pointer mr-2"
            strokeColor={disabled ? '#4d4d4d' : '#FFFFFF'}
            onClick={disabled ? null : action}
          />
        )}
        <div className="text-[13px] my-auto">{text}</div>
      </div>
      {!disabled && (
        <div>
          <DynamicSVG
            src={`./assets/icons/ic_arrow_right.svg`}
            className="cursor-pointer mr-2"
            strokeColor="#FFFFFF"
          />
        </div>
      )}
    </div>
  );
}
