import Axios from 'axios';
import axiosInstance from '../utils/api';
import { revertAppleUserId } from '../utils/idCheck';

const REACT_APP_API_BACK_OFFICE_URL = process.env.REACT_APP_API_BACK_OFFICE_URL;

const REACT_APP_API_USER_URL = process.env.REACT_APP_API_USER_URL;

export const getDatabaseUserInfo = async (userId, token) => {
  try {
    const formatedUserAppleId = userId?.startsWith('apple')
      ? revertAppleUserId(userId)
      : userId;
    const res = await axiosInstance(
      REACT_APP_API_USER_URL,
      'get',
      `users/${formatedUserAppleId}`,
      {
        Authorization: `Bearer ${token}`,
      }
    );
    return res;
  } catch (error) {
    console.error(error);
  }
};

export const getUserInfoById = async (userId, token) => {
  try {
    const formatedUserAppleId = userId?.startsWith('apple')
      ? revertAppleUserId(userId)
      : userId;
    const res = await axiosInstance(
      REACT_APP_API_USER_URL,
      'get',
      `users/info/${formatedUserAppleId}`,
      {
        Authorization: `Bearer ${token}`,
      }
    );
    return res;
  } catch (error) {
    console.error(error);
  }
};

export const getUserById = async (userId, token) => {
  try {
    const formatedUserAppleId = userId?.startsWith('apple')
      ? revertAppleUserId(userId)
      : userId;
    const res = await axiosInstance(
      REACT_APP_API_USER_URL,
      'get',
      `users/${formatedUserAppleId}`,
      {
        Authorization: `Bearer ${token}`,
      }
    );
    return res;
  } catch (error) {
    console.error(error);
  }
};

export const getMongoUserInfo = async (userId) => {
  const uri = `${REACT_APP_API_BACK_OFFICE_URL}users/mongouser/${userId}`;
  try {
    const response = await Axios.get(uri, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response;
  } catch (error) {
    throw new Error('Get user information failed');
  }
};

export const updateUser = async (userId, token, data) => {
  try {
    const res = await axiosInstance(
      REACT_APP_API_USER_URL,
      'patch',
      `users/${userId}`,
      {
        Authorization: `Bearer ${token}`,
      },
      {
        ...data,
      }
    );
    return res;
  } catch (error) {
    console.error(error);
  }
};

export const deleteUser = async (userId, token) => {
  try {
    const res = await axiosInstance(
      REACT_APP_API_USER_URL,
      'delete',
      `users/${userId}`,
      {
        Authorization: `Bearer ${token}`,
      }
    );
    return res;
  } catch (error) {
    console.error(error);
  }
};

export const getUserByName = async (name, token) => {
  try {
    const res = await axiosInstance(
      REACT_APP_API_USER_URL,
      'post',
      'users/search',
      {
        Authorization: `Bearer ${token}`,
      },
      {
        query: {
          match: {
            name: name,
          },
        },
      }
    );
    return res;
  } catch (error) {
    console.error(error);
  }
};
