import { getDB } from '../../../services/messaging/firebaseSetup';
import { get, ref, set, update } from 'firebase/database';
import { formatAppleUserId } from '../../../utils/idCheck';

const useMessagingGroup = () => {
  const checkIfGroupInfoExist = async (groupId) => {
    if (!groupId) return null;

    let db = getDB();
    const groupInfoRef = ref(db, `/group-info/` + groupId);

    try {
      const snapshot = await get(groupInfoRef);
      const groupInfo = snapshot.val();

      return groupInfo || false;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const getGroupInfo = async (groupId) => {
    if (!groupId) return null;

    let db = getDB();
    const groupInfoRef = ref(db, `/group-info/` + groupId);

    try {
      const snapshot = await get(groupInfoRef);
      const groupInfo = snapshot.val();

      return groupInfo || null;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const createGroupInfo = async (groupId) => {
    try {
      let originalGroup = null;
      let createdGroupInfo;

      if (!groupId) return null;

      createdGroupInfo = await checkIfGroupInfoExist(groupId);
      if (createdGroupInfo) {
        return createdGroupInfo;
      }

      let db = getDB();
      const groupRef = ref(db, `/groups/` + groupId);
      const groupInfoRef = ref(db, `/group-info/` + groupId);

      const snapshot = await get(groupRef);

      originalGroup = snapshot.val();
      // console.log(originalGroup);

      //eslint-disable-next-line
      const { messages, ...groupInfo } = originalGroup;

      await set(groupInfoRef, groupInfo);

      // console.log(groupInfo);
      // createdGroupInfo = groupInfo;

      return createdGroupInfo;
    } catch (error) {
      console.error('Error creating group info:', error);
      return null;
    }
  };

  const deleteUserFromGroup = async (userId, groupId) => {
    const userIdFormated = formatAppleUserId(userId);
    let deleteStatus = false;

    if (!userIdFormated || !groupId) return deleteStatus;

    try {
      const groupInfo = await getGroupInfo(groupId);

      if (!groupInfo) return deleteStatus;

      const group_copy = { ...groupInfo };

      if (groupInfo?.admins?.includes(userIdFormated)) {
        const newAdmins = groupInfo.admins?.filter(
          (adminId) => adminId !== userIdFormated
        );
        group_copy.admins = newAdmins;
      }

      const newUserIds = groupInfo.userIds.filter(
        (memberId) => memberId !== userIdFormated
      );
      group_copy.userIds = newUserIds;

      const newGroupMembers = groupInfo.groupMembers.filter(
        (groupMember) => groupMember.user_id !== userIdFormated
      );
      group_copy.groupMembers = newGroupMembers;

      if (group_copy?.userIdsObjectList[userIdFormated]) {
        delete group_copy.userIdsObjectList[userIdFormated];
      }

      // Updating firebase
      const db = getDB();
      const groupRef = ref(db, `/group-info/` + groupId);

      await update(groupRef, group_copy);

      // console.log('Data was successfully updated.');
      deleteStatus = group_copy;
    } catch (error) {
      console.error('Error updating data:', error);
    }

    return deleteStatus;
  };

  const appointNewGroupAdmin = async (userId, groupId) => {
    const userIdFormated = formatAppleUserId(userId);
    let updateStatus = false;
    if (!userIdFormated || !groupId) return updateStatus;
    const groupInfo = await getGroupInfo(groupId);
    if (!groupInfo) return updateStatus;

    const group_copy = { ...groupInfo };
    if (userIdFormated) {
      if (!groupInfo.admins.includes(userIdFormated)) {
        group_copy.admins.push(userIdFormated);
      }
    } else {
      group_copy.admins.push(groupInfo?.userIds[0]);
    }
    const db = getDB();
    const groupRef = ref(db, `/group-info/` + groupId);
    update(groupRef, group_copy)
      .then(() => {
        // console.log('Admin list was successfully updated.');
        updateStatus = group_copy;
        return updateStatus;
      })
      .catch((error) => {
        console.error('Error updating data:', error);
        return updateStatus;
      });
    return updateStatus;
  };

  const updateBasicInfo = async (name, media, groupId) => {
    try {
      let updateStatus = false;

      const groupInfo = await getGroupInfo(groupId);
      if (!groupInfo) return updateStatus;

      const group_copy = { ...groupInfo };

      if (name) {
        group_copy.groupName = name;
      }

      if (media) {
        group_copy.groupPicture = media ? media.medias[0].flavors : null;
      }

      const db = getDB();
      const groupRef = ref(db, `/group-info/` + groupId);

      // console.log('info to update ', group_copy);

      await update(groupRef, group_copy);

      // console.log('Basic info was successfully updated.');
      updateStatus = group_copy;

      return updateStatus;
    } catch (error) {
      console.error('Error updating basic info:', error);
      return false;
    }
  };

  const addPersonToGroupInfo = async (person, groupId) => {
    try {
      if (!person || !groupId) return;
      let groupInfo = await getGroupInfo(groupId);
      if (!groupInfo) {
        groupInfo = await createGroupInfo(groupId);
      }
      if (!groupInfo) return;
      let groupInterface = { ...groupInfo };
      // console.log('GM', groupInterface.groupMembers);
      const selectionId = formatAppleUserId(person.user_id);
      if (groupInterface?.userIds?.includes(selectionId)) {
        console.log('duplicate selection found');
        return;
      }
      // console.log('selection not found');

      //adding info
      groupInterface?.userIds.push(selectionId);
      groupInterface?.groupMembers.push(person);
      groupInterface.userIdsObjectList[selectionId] = selectionId;

      //updating firebase
      const db = getDB();
      const groupRef = ref(db, `/group-info/` + groupId);
      await update(groupRef, groupInterface);
      // console.log('Basic info was successfully updated.');
      return groupInterface;
    } catch (error) {
      return null;
    }
  };

  return {
    createGroupInfo,
    checkIfGroupInfoExist,
    getGroupInfo,
    deleteUserFromGroup,
    appointNewGroupAdmin,
    updateBasicInfo,
    addPersonToGroupInfo,
  };
};

export default useMessagingGroup;
