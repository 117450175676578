import { useState } from 'react';
import { updateRelation as updateRelationService } from '../../../services/Relation';

const useUpdateRelation = () => {
  const [isUpdatingRelation, setIsUpdatingRelation] = useState(false);
  const [isRelationUpdated, setIsRelationUpdated] = useState(false);

  const updateRelation = async (auth0Token, data, relationId) => {
    try {
      setIsUpdatingRelation(true);
      await updateRelationService(data, relationId, auth0Token);
      setIsUpdatingRelation(false);
      setIsRelationUpdated(true);
    } catch (e) {
      setIsUpdatingRelation(true);
      setIsRelationUpdated(false);
      console.error(e);
    }
  };

  return { updateRelation, isUpdatingRelation, isRelationUpdated };
};

export default useUpdateRelation;
