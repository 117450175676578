import SettingItem from './SettingItem';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import DynamicSVG from '../../../components/DynamicSVG';

export default function DocumentsSetting() {
  const navigate = useNavigate();
  return (
    <div className="mt-6 text-white w-full">
      <div className="text-center mb-8">Paramètres</div>
      <DynamicSVG
        src="./assets/icons/ic_arrow_left.svg"
        className="cursor-pointer absolute top-10 left-5"
        strokeColor="#FFFFFF"
        onClick={() => {
          navigate(`/profile/settings`);
        }}
      />
      <SettingItem
        text="C.G.U"
        action={() => {
          window.open('/assets/documents/sfeira-cgu.pdf', '_blank');
        }}
      />
      <SettingItem
        text="Politique de protection des données"
        action={() => {
          window.open('/assets/documents/sfeira-rgpd.pdf', '_blank');
        }}
      />
      <SettingItem
        text="C.G.V"
        action={() => {
          window.open('/assets/documents/sfeira-cgv.pdf', '_blank');
        }}
      />
      <SettingItem
        text="Référencement"
        action={() => {
          window.open('/assets/documents/sfeira-referencement.pdf', '_blank');
        }}
      />
    </div>
  );
}
