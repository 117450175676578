// slick-carousel
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/black-and-white.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
// import reportWebVitals from './reportWebVitals';
import { SnackbarProvider } from 'notistack';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import store from './app/store';
import { AuthUserProvider } from './context/AuthUser';
import { FeedContextProvider } from './context/FeedContext';
import { HashtagContextProvider } from './context/HasTagContext';
import { LikeContextProvider } from './context/LikeContext';
import { LngProvider } from './context/lngContext.js';
import { MessagingContextProvider } from './context/MessagingContext';
import { MessageOptionContextProvider } from './context/MessagingOptionContext';
import { NotificationContextProvider } from './context/NotificationContext';
import ProfileIdContextProvider from './context/ProfileIdContextProvider';
import { TicketReservationContextProvider } from './context/TicketReservationContext';
import AuthProvider from './guards/AuthProvider';
import './i18n.js';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <HelmetProvider>
      <SnackbarProvider
        style={{
          padding: 0,
          background: 'none',
          boxShadow: 'none',
          display: 'flex',
          justifyContent: 'center',
          justifyItems: 'center',
        }}
        autoHideDuration={10000}
      >
        <BrowserRouter>
          <LikeContextProvider>
            <AuthProvider>
              <AuthUserProvider>
                <FeedContextProvider>
                  <NotificationContextProvider>
                    <HashtagContextProvider>
                      <TicketReservationContextProvider>
                        <ProfileIdContextProvider>
                          <MessagingContextProvider>
                            <MessageOptionContextProvider>
                              <LngProvider>
                                <App />
                              </LngProvider>
                            </MessageOptionContextProvider>
                          </MessagingContextProvider>
                        </ProfileIdContextProvider>
                      </TicketReservationContextProvider>
                    </HashtagContextProvider>
                  </NotificationContextProvider>
                </FeedContextProvider>
              </AuthUserProvider>
            </AuthProvider>
          </LikeContextProvider>
        </BrowserRouter>
      </SnackbarProvider>
    </HelmetProvider>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
