const leaguesDetails = [
  {
    name: 'Premier league',
    logo: '/assets/icons/ic_PL_logo.svg',
    color: '#441848',
  },
  {
    name: 'Ligue 1',
    logo: '/assets/icons/ic_L1_logo.svg',
    color: '#441848',
  },
  {
    name: 'Bundesliga',
    logo: '/assets/icons/ic_Bundesliga_logo.svg',
    color: '#441848',
  },
  {
    name: 'Serie A',
    logo: '/assets/icons/ic_serieA_logo.svg',
    color: '#441848',
  },
  {
    name: 'La liga',
    logo: '/assets/icons/ic_LaLiga_logo.svg',
    color: '#441848',
  },
  {
    name: 'Ligue 2',
    logo: '/assets/icons/ic_L2_logo.svg',
    color: '#441848',
  },
  {
    name: 'National 1',
    logo: '/assets/images/img_ligue_1.png',
    color: '#441848',
  },
  {
    name: 'Jupiler Pro League',
    logo: '/assets/images/img_jupiler_league.png',
    color: '#CD142A',
  },
  {
    name: 'UEFA Champions League',
    logo: '/assets/icons/ic_uefa_champions_league.svg',
    color: '#441848',
  },
  {
    name: 'World Cup - Women',
    logo: '/assets/images/img_word_cup_women.png',
    color: '#441848',
  },
  {
    name: 'UEFA Europa League',
    logo: '/assets/images/img_europa_league.png',
    color: '#441848',
  },
  {
    name: 'Primeira Liga',
    logo: '/assets/images/img_primeira_liga.png',
    color: '#441848',
  },
  {
    name: 'Africa Cup of Nations',
    logo: '/assets/images/img_can.png',
    color: '#441848',
  },
  {
    name: 'UEFA Europa Conference League',
    logo: '/assets/images/img_conference_league.png',
    color: '#441848',
  },
  {
    name: 'Euro Championship - Qualification',
    logo: '/assets/images/img_euro.png',
    color: '#441848',
  },
  {
    name: 'Friendlies',
    logo: '/assets/images/img_friendlies.png',
    color: '#441848',
  },
  {
    name: 'FA Cup',
    logo: '/assets/images/league/45.png',
    color: '#441848',
  },
  {
    name: 'League Cup',
    logo: '/assets/images/league/48.png',
    color: '#441848',
  },

  {
    name: 'Feminine Division 1',
    logo: '/assets/images/league/64.png',
    color: '#441848',
  },
  {
    name: 'Coupe de France',
    logo: '/assets/images/league/66.png',
    color: '#441848',
  },
  {
    name: 'DFB Pokal',
    logo: '/assets/images/league/81.png',
    color: '#441848',
  },
  {
    name: 'Frauen Bundesliga',
    logo: '/assets/images/league/82.png',
    color: '#441848',
  },
  {
    name: 'Taça de Portugal',
    logo: '/assets/images/league/96.png',
    color: '#441848',
  },
  {
    name: 'Coppa Italia',
    logo: '/assets/images/league/137.png',
    color: '#441848',
  },
  {
    name: 'Serie A Women',
    logo: '/assets/images/league/139.png',
    color: '#441848',
  },
  {
    name: 'Primera División Femenina',
    logo: '/assets/images/league/142.png',
    color: '#441848',
  },
  {
    name: 'Copa del Rey',
    logo: '/assets/images/league/143.png',
    color: '#441848',
  },
  {
    name: 'Super League Women',
    logo: '/assets/images/league/146.png',
    color: '#441848',
  },
  {
    name: 'Cup',
    logo: '/assets/images/league/147.png',
    color: '#441848',
  },
  {
    name: 'Trophée des Champions',
    logo: '/assets/images/league/526.png',
    color: '#441848',
  },
  {
    name: 'Community Shield',
    logo: '/assets/images/league/528.png',
    color: '#441848',
  },
  {
    name: 'Super Cup',
    logo: '/assets/images/league/529.png',
    color: '#441848',
  },
  {
    name: 'SuperCoppa',
    logo: '/assets/images/league/547.png',
    color: '#441848',
  },
  {
    name: 'SuperCopa de Espana',
    logo: '/assets/images/league/556.png',
    color: '#441848',
  },
  {
    name: "Women's Championship",
    logo: '/assets/images/league/699.png',
    color: '#441848',
  },
  {
    name: 'Copa Federacion',
    logo: '/assets/images/league/735.png',
    color: '#441848',
  },
  {
    name: '1a Divisão - Women',
    logo: '/assets/images/league/948.png',
    color: '#441848',
  },
];

export default leaguesDetails;
