import {
  equalTo,
  // get,
  onValue,
  orderByChild,
  query,
  ref,
} from 'firebase/database';
import { getDB } from '../../../services/messaging/firebaseSetup';
import { useAuth0 } from '@auth0/auth0-react';
import { useState } from 'react';
import useMessagingFilters from '../../useMessaging';
import useMessagingGroup from './useMessagingGroup';
import { formatAppleUserId } from '../../../utils/idCheck';

const useGetUserMessages = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useAuth0();
  const { createGroupInfo } = useMessagingGroup();

  const { MessagingDispatch } = useMessagingFilters();

  const dispatchMessages = async (messages) => {
    for (const message of messages) {
      const filteredUser = message.groupMembers?.find(
        (object) => object.user_id === formatAppleUserId(user?.sub)
      );

      // filter groups messages
      if (message.groupType === 'group') {
        // check and create groupInfos
        await createUserGroupInfos([message]);
        const filteredMessages = await filterOutGroups([message]);
        if (!filteredMessages[0]) {
          continue;
        }

        if (filteredUser && filteredUser.invitationStatus === 'pending') {
          // filter invitations messages
          MessagingDispatch({
            type: 'setInvitationsMessages',
            payload: filteredMessages[0],
          });
        } else if (
          filteredUser &&
          filteredUser.invitationStatus === 'accepted'
        ) {
          // filter groups messages
          MessagingDispatch({
            type: 'setGroupsMessages',
            payload: filteredMessages[0],
          });
        } else if (
          filteredUser &&
          filteredUser.invitationStatus === 'refused'
        ) {
          return;
        } else {
          MessagingDispatch({
            type: 'setGroupsMessages',
            payload: filteredMessages[0],
          });
        }
      } else {
        // filter individuals invitations messages
        if (filteredUser && filteredUser.invitationStatus === 'pending') {
          // filter invitations messages
          MessagingDispatch({
            type: 'setInvitationsMessages',
            payload: message,
          });
        } else if (
          filteredUser &&
          filteredUser.invitationStatus === 'accepted'
        ) {
          // filter individuals messages
          MessagingDispatch({
            type: 'setIndividualsMessages',
            payload: message,
          });
        } else if (
          filteredUser &&
          filteredUser.invitationStatus === 'refused'
        ) {
          return;
        } else {
          MessagingDispatch({
            type: 'setIndividualsMessages',
            payload: message,
          });
        }
      }
    }
  };

  const getUserMessages = () => {
    const userId = formatAppleUserId(user?.sub);
    const db = getDB();
    const myref = ref(db, 'groups');
    const filteredGroupsQuery = query(
      myref,
      orderByChild(`userIdsObjectList/${userId}`),
      equalTo(userId)
    );

    onValue(filteredGroupsQuery, (snapshot) => {
      const myGroups = snapshot.val();
      if (myGroups) {
        dispatchMessages(Object.values(myGroups));
      }
    });

    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };

  //TODO : Rewrite all the next methods to work on 1 message item to reduce treatment time

  const getUserGroupInfo = async () => {
    const userIdToFind = formatAppleUserId(user.sub);
    try {
      const db = getDB();
      const myref = ref(db, 'group-info');
      const filteredGroupsQuery = query(
        myref,
        orderByChild(`userIdsObjectList/${userIdToFind}`),
        equalTo(userIdToFind)
      );

      // Wrap the onValue function in a Promise
      const myGroups = await new Promise((resolve, reject) => {
        onValue(
          filteredGroupsQuery,
          (snapshot) => {
            const groups = snapshot.val();
            resolve(groups || []);
          },
          reject
        );
      });

      return myGroups;
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  const createUserGroupInfos = async (groupList) => {
    try {
      if (groupList.length === 0) {
        return;
      }
      let infoLessGP = [];
      let gpInfos = await getUserGroupInfo();
      gpInfos = Object.values(gpInfos);
      if (gpInfos.length === 0) {
        infoLessGP = groupList;
      } else {
        let gpInfoIds = gpInfos.map((gpInfo) => {
          return gpInfo.groupId;
        });
        for (let groupIndex in groupList) {
          // console.log({ group: groupList[groupIndex] });
          if (
            !gpInfoIds.includes(groupList[groupIndex].groupId) &&
            !infoLessGP.includes(groupList[groupIndex])
          ) {
            infoLessGP.push(groupList[groupIndex]);
          }
        }
      }
      // console.log({ infoLessGP });
      if (infoLessGP?.length > 0) {
        const promises = infoLessGP.map((gpInfo) => {
          return createGroupInfo(gpInfo.groupId);
        });
        await Promise.all(promises);
      }
      return infoLessGP;
    } catch (error) {
      return [];
    }
  };

  const filterOutGroups = async (groups) => {
    if (!groups || groups?.length <= 0) {
      return [];
    }
    let myGroupInfos = await getUserGroupInfo();
    if (!myGroupInfos) {
      return [];
    }
    myGroupInfos = Object.values(myGroupInfos);
    const myGroupIds = myGroupInfos.map((group) => {
      return group.groupId;
    });
    const myFinalGroups = [];
    for (let groupIndex in groups) {
      // console.log({ group: groupList[groupIndex] });
      if (
        myGroupIds.includes(groups[groupIndex].groupId) &&
        !myFinalGroups.includes(groups[groupIndex])
      ) {
        myFinalGroups.push(groups[groupIndex]);
      }
    }
    return myFinalGroups;
  };

  return { getUserMessages, isLoading, setIsLoading };
};

export default useGetUserMessages;
