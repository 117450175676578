import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: 'AIzaSyB3H7fMZYiNlgduejy9tbMaUc4AlnDWvlk',
  authDomain: 'sfeira-9d88f.firebaseapp.com',
  projectId: 'sfeira-9d88f',
  storageBucket: 'sfeira-9d88f.appspot.com',
  messagingSenderId: '884952987000',
  appId: '1:884952987000:web:a9b679dbea38f8ae38848d',
  measurementId: 'G-R32T82455L',
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
};

export const firebaseApp = initializeApp(firebaseConfig);
