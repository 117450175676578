import { createContext, useEffect, useReducer } from 'react';

const initialReservationState = [];

const actions = {
  reinit: () => {
    localStorage.removeItem('order');
    return [];
  },
  setFromLocalStorage: (state, payload) => {
    return payload;
  },
  setReservationData: (state, payload) => {
    const found = state.find((value) => value.match._id === payload.match._id);
    if (!found) {
      state.push({
        reservedPlaces: [],
        reservations: [],
        totalPrice: 0,
        ...payload,
      });
      localStorage.setItem('order', JSON.stringify(state));
      return state;
    }
    const newState = state.map((value) => {
      const found = value.match._id === payload.match._id;
      if (found) {
        return {
          ...value,
          ...payload,
        };
      }
      return value;
    });
    localStorage.setItem('order', JSON.stringify(newState));
    return newState;
  },
  removeElement: (state, index) => {
    state.splice(index, 1);
    localStorage.setItem('order', JSON.stringify(state));
    return [...state];
  },
};

const reducer = (state, { payload, type }) =>
  actions[type] ? actions[type](state, payload) : state;

const TicketReservationContext = createContext(initialReservationState);

const TicketReservationContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialReservationState);

  useEffect(() => {
    const orders = JSON.parse(localStorage.getItem('order'));
    if (state.length == 0 && orders && orders.length > 0) {
      dispatch({
        type: 'setFromLocalStorage',
        payload: orders,
      });
    }
  }, [state]);

  return (
    <TicketReservationContext.Provider
      value={{
        ticketReservationsDispatch: dispatch,
        ticketReservations: state,
      }}
    >
      {children}
    </TicketReservationContext.Provider>
  );
};

export { TicketReservationContext, TicketReservationContextProvider };
