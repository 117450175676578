import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import DynamicSVG from '../../components/DynamicSVG';
import { change } from '../../feature/liste/listeSlice';
import MainHeader from '../../layouts/main/MainHeader';
import Partager from './Partager';
import Steppers from './Steppers';
import Image from './component/image-background';

const ChoixQuizz = () => {
  const { id, nbr } = useParams();
  const [showModal, setShowModal] = useState(false);
  const { user } = useAuth0();
  const [activeUrl, setActiveUrl] = useState('');
  const sendId = user.sub;

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const validate = () => {
    if (activeUrl === 'partage') {
      setShowModal(true);
    } else if (activeUrl === 'avec_amis') {
      navigate(`/quizz/liste-ami/${id}/${nbr}/${activeUrl}`);
    } else {
      navigate(`/quizz/jouer-seul/${id}/${nbr}/${activeUrl}`);
    }
  };
  const handleChoice = (typeJeu) => {
    setActiveUrl(typeJeu);
  };

  const handleQuiz = async (typeJeu) => {
    // const quizz = e.target.value
    try {
      const quiz = {
        user: sendId,
        // theme: [],
        niveau: id,
        nombre_de_questions: nbr,
        type_jeu: typeJeu,
        amis: [],
        lien_partage: [],
      };
      const response = await axios.post(
        `${process.env.REACT_APP_API_BACK_OFFICE_URL}quiz/create`,
        // `http://localhost:8080/quiz/create`,
        quiz
      );
      if (response.data.success) {
        // setQuizId(response.data);

        dispatch(change(response.data));
      } else {
        console.error(
          'Erreur lors de la création du quiz :',
          response.data.message
        );
      }
      // const liste = useSelector((state) => state.liste.value);
      // console.log(liste);
    } catch (error) {
      console.error('Erreur enregistrement :', error);
    }
  };

  return (
    <div className="h-screen w-full">
      <Image />
      <MainHeader items={2} leftText="Quiz" />
      <Steppers />
      <div className="flex flex-col  text-center items-center">
        <p className="w-[250px] text-center text-white font-semibold text-[19px] my-10">
          Choisissez en mode solo ou en défiant vos amis !
        </p>

        <div className="flex gap-3 w-full">
          <div
            className={
              activeUrl == 'solo'
                ? 'bg-[#1A2342] w-full rounded-lg p-5  border border-solid border-[#36EBCA] border-3'
                : 'bg-[#1A2342] w-full rounded-lg p-5'
            }
          >
            <button
              // to={`/quizz/jouer-seul/${id}/${nbr}/${typeJeu}`}
              // onClick={() => handleChoice('solo')}bg-[#1A2342]
              onClick={() => {
                handleChoice('solo');
                handleQuiz('solo');
              }}
            >
              <div className="flex flex-col items-center pt-2">
                <DynamicSVG
                  src="./assets/icons/uiw_user.svg"
                  className="my-2"
                />
                <span className="text-white text-[14px]">Jouer seul</span>
              </div>
            </button>
          </div>
          <div
            className={
              activeUrl == 'avec_amis'
                ? 'bg-[#1A2342] rounded-lg w-full p-5  border border-solid border-[#36EBCA] border-3 opacity-25 pointer-events-none cursor-not-allowed'
                : 'bg-[#1A2342] rounded-lg w-full p-5 opacity-25 pointer-events-none cursor-not-allowed'
            }
          >
            <button
              to={`/quizz/liste-ami/${id}`}
              // onClick={() => handleChoice('avec_amis')}
              onClick={() => {
                handleChoice('avec_amis');
                handleQuiz('avec_amis');
              }}
            >
              <div className="flex flex-col items-center pt-2">
                <DynamicSVG src="./assets/icons/Vector.svg" className="my-2" />
                <span className="text-white text-[14px]">
                  Défier des Sfeiros
                </span>
              </div>
            </button>
          </div>
        </div>

        <button
          onClick={() => {
            handleChoice('partage');
            handleQuiz('partage');
          }}
          //  onClick={() => handleChoice('partage')}
          className={
            activeUrl == 'partage'
              ? 'bg-[#1A2342] rounded-lg w-full p-5 my-3 border border-solid border-[#36EBCA] border-3 opacity-25 pointer-events-none cursor-not-allowed'
              : 'bg-[#1A2342] rounded-lg w-full p-5 my-3 opacity-25 pointer-events-none cursor-not-allowed'
          }
        >
          <div className="flex flex-col items-center gap-1">
            <DynamicSVG
              src="./assets/icons/uiw_user-add.svg"
              className="my-2"
            />
            <span className="text-white text-[14px] mb-4">
              Inviter quelqu’un à rejoindre le quiz sfeira
            </span>
          </div>
        </button>
        <Partager
          visible={showModal}
          onClose={handleCloseModal}
          id={id}
          nbr={nbr}
          activeUrl={activeUrl}
        />
      </div>
      <div className="flex items-center justify-center -mt-5">
        {activeUrl === 'partage' && (
          <button
            className=" bg-white rounded-full text-[#1A2342] my-10 w-32 h-9 text-sm"
            onClick={validate}
          >
            Inviter
          </button>
        )}
        {activeUrl === 'solo' && (
          <button
            className=" bg-white rounded-full text-[#1A2342] my-10 w-32 h-9 text-sm"
            onClick={validate}
          >
            Jouer
          </button>
        )}
        {activeUrl === 'avec_amis' && (
          <button
            className=" bg-white rounded-full text-[#1A2342] my-10 w-32 h-9 text-sm"
            onClick={validate}
          >
            Défier
          </button>
        )}
      </div>
    </div>
  );
};

export default ChoixQuizz;
