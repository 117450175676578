const Image = () => {
  return (
    <div
      // className="fixed top-0 sm:w-1/2 md:w-2/3 lg:w-1/3 xl:w-1/4 -ml-4"
      className="fixed top-0 w-full sm:w-1/2 md:w-2/3 lg:w-1/3 lg:h-64 xl:w-1/4 -ml-4"
      style={{ pointerEvents: 'none' }}
    >
      <img
        src="/assets/images/img_sfeira_watermark.png"
        className="absolute top-0"
        // className="absolute top-0 xs:ml-10 sm:-ml-1 md:ml-4 lg:ml-1 xl:ml-[-0.05rem]"

        alt="sfeira"
      />
      <img
        src="/assets/images/img_green_blur.png"
        className="top-0 opacity-60"
        alt="sfeira"
      />
      <img
        src="/assets/images/img_purple_blur.png"
        className="absolute top-0 xs:-right-20 sm:-right-2 md:-right-2 lg:-right-2 xl:-right-2 opacity-60"
        alt="sfeira"
      />
    </div>
  );
};

export default Image;
