import React, { useRef } from 'react';
// import DynamicSVG from '../../components/DynamicSVG';
import ReactDOMServer from 'react-dom/server';
import LienPartager from './LienPartager';
import html2canvas from 'html2canvas';
import { useNavigate } from 'react-router';

const Partager = ({ visible, onClose, id, nbr, activeUrl }) => {
  const componentRef = useRef(null);
  const text = 'bonjour';
  const url = 'https://sfeira-web-staging.cleverapps.io';
  const navigate = useNavigate();

  const captureComponent = () => {
    if (componentRef.current) {
      html2canvas(componentRef.current).then((canvas) => {
        const imageDataUrl = canvas.toDataURL('image/png');
        shareOnWhatsApp(imageDataUrl);
      });
    }
  };
  const shareOnInstagram = () => {
    const customComponentHTML = ReactDOMServer.renderToStaticMarkup(
      <LienPartager ref={componentRef} />
    );
    window.open(
      `https://www.instagram.com/?url=${encodeURIComponent(
        `${text} ${url} ${customComponentHTML}`
      )}`,
      '_blank'
    );
  };

  // const shareOnFacebook = () => {
  //   const shareUrl =
  //     'https://www.facebook.com/sharer/sharer.php?u=' +
  //     encodeURIComponent('https://your-share-url.com');

  //   // Open a new window or redirect to the share URL
  //   window.open(shareUrl, '_blank');
  // }; en tant que publication et story pas message
  const shareOnFacebook = () => {
    const sendUrl =
      'https://www.facebook.com/dialog/send?' +
      '&link=' +
      encodeURIComponent(
        'https://sitanews.org/rencontre-avec-niklovens-fransaint-poete'
      ) + // Replace with the URL you want to share
      '&redirect_uri=' +
      encodeURIComponent(
        'https://sitanews.org/rencontre-avec-niklovens-fransaint-poete'
      ); // Replace with the redirect URL

    window.open(sendUrl, '_blank');
  };

  const shareOnWhatsApp = () => {
    const sfeira = 'https://sfeira-web-staging.cleverapps.io/';

    // document.querySelector('meta[property="og:image"]').getAttribute('content');

    // const customComponentHTML = ReactDOMServer.renderToStaticMarkup(
    //   <LienPartager />
    // );
    const textePartage =
      "Rejoins moi sur l'app Sfeira pour participer au quiz 100% foot auquel je viens de te défier ! Relèveras-tu le challenge ? Télécharge l'app et réglons ça sur le terrain !";

    // const sfeira = encodeURIComponent(window.location.href);
    navigate(`/quizz/jouer-seul/${id}/${nbr}/${activeUrl}`);

    const whatsappURL = `https://api.whatsapp.com/send?text=${textePartage} ${sfeira}`;
    window.open(whatsappURL, '_blank');
  };

  // const phoneNumber = +261343122835;
  // const message = 'bonjour cest moi ';

  // const shareSMS = (/* phoneNumber, message */) => {
  //   const smsUrl = `sms:${phoneNumber}?body=${encodeURIComponent(message)}`;
  //   window.open(smsUrl, '_blank');
  // };

  if (!visible) return null;

  return (
    <div className="bg-[#1A2342] rounded-lg w-full z-50 py-5">
      <div className="flex items-center justify-center space-x-4">
        <button onClick={onClose}>
          <img
            src={
              process.env.REACT_APP_AUTH0_REDIRECT_URI +
              '/assets/images/Barre-de-swipe.png'
            }
            className="w-15 h-1 my-1"
          />
        </button>
      </div>
      <h1 className="text-center text-white my-4">Inviter un ami non sfeira</h1>

      <div className="grid grid-cols-4 gap-4 text-white border-t text-xs px-2">
        <img
          src={
            process.env.REACT_APP_AUTH0_REDIRECT_URI +
            '/assets/images/partage_ami.png'
          }
          alt="Image 1"
          className="w-12"
        />
        <img
          src={
            process.env.REACT_APP_AUTH0_REDIRECT_URI +
            '/assets/images/partage_ami.png'
          }
          alt="Image 1"
          className="w-12"
        />
        <img
          src={
            process.env.REACT_APP_AUTH0_REDIRECT_URI +
            '/assets/images/partage_ami.png'
          }
          alt="Image 2"
          className="w-12"
        />
        <img
          src={
            process.env.REACT_APP_AUTH0_REDIRECT_URI +
            '/assets/images/partage_ami.png'
          }
          alt="Image 2"
          className="w-12"
        />
        <div
          className="flex flex-col items-center"
          onClick={() => {
            shareOnWhatsApp();
            captureComponent();
          }}
        >
          <img
            src={
              process.env.REACT_APP_AUTH0_REDIRECT_URI +
              '/assets/images/logos_whatsapp-icon.png'
            }
            alt="Image 1"
            className="w-10 h-10"
          />
          <span>Whatsapp</span>
        </div>
        <div className="flex flex-col items-center" /* onClick={shareSMS} */>
          <img
            src={
              process.env.REACT_APP_AUTH0_REDIRECT_URI +
              '/assets/images/fluent-mdl2_message-fill.png'
            }
            alt="Image 1"
            className="w-10 h-10"
          />
          <span>Message</span>
        </div>

        <div className="flex flex-col items-center" onClick={shareOnInstagram}>
          <img
            src={
              process.env.REACT_APP_AUTH0_REDIRECT_URI +
              '/assets/images/skill-icons_instagram.png'
            }
            alt="Image 1"
            className="w-10 h-10"
          />
          <span>Instagram</span>
        </div>
        <div className="flex flex-col items-center" onClick={shareOnFacebook}>
          <img
            src={
              process.env.REACT_APP_AUTH0_REDIRECT_URI +
              '/assets/images/logos_facebook.png'
            }
            alt="Image 1"
            className="w-10 h-10"
          />
          <span>Facebook</span>
        </div>
      </div>
    </div>
  );
};
export default Partager;
