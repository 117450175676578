import { getAnalytics } from 'firebase/analytics';
import { firebaseApp } from '../utils/firebaseConfig';

let analytics;

export const initializeAnalytics = () => {
  if (!analytics) {
    // Initialize Firebase
    console.log('initialize analytics');
    analytics = getAnalytics(firebaseApp);
  }
};
