import { useAuth0 } from '@auth0/auth0-react';
import { getUnreadNotificationsCountByUserId } from '../../../services/notifications/Notification';
import useNotificationFilters from '../../useNotification';
import { Browser } from '@capacitor/browser';

const REACT_APP_AUTH0_REDIRECT_URI = process.env.REACT_APP_AUTH0_REDIRECT_URI;
const useFetchUnreadNotificationCount = () => {
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const { notificationDispatch } = useNotificationFilters();

  const fetchNotificationsCount = async () => {
    try {
      const token = await getAccessTokenSilently();
      const count = await getUnreadNotificationsCountByUserId(token);
      notificationDispatch({
        type: 'setUnreadNotificationCount',
        payload: count.notificationsCount,
      });
    } catch (err) {
      if (err.error === 'invalid_grant') {
        loginWithRedirect({
          appState: {
            returnTo: REACT_APP_AUTH0_REDIRECT_URI,
          },
          async openUrl(url) {
            // Redirect using Capacitor's Browser plugin
            await Browser.open({
              url,
              windowName: '_self',
            });
          },
        });
      }
      console.error(err);
    }
  };

  return {
    fetchNotificationsCount,
  };
};

export default useFetchUnreadNotificationCount;
