import { Auth0Provider } from '@auth0/auth0-react';
import { useNavigate } from 'react-router';

const AuthProvider = ({ children, ...props }) => {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const redirect_uri = process.env.REACT_APP_AUTH0_REDIRECT_URI;
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

  const navigate = useNavigate();

  const onRedirectCallback = (appState) => {
    navigate(
      appState && appState.returnTo
        ? appState.returnTo
        : window.location.pathname
    );
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri,
        scope: 'profile email openid offline_access',
        audience,
      }}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens={true}
      useRefreshTokensFallback={false}
      cacheLocation="localstorage"
      {...props}
    >
      {children}
    </Auth0Provider>
  );
};

export default AuthProvider;
