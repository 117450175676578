function CustomCircularProgress({ count }) {
  const percentage = (20 - count) * 5; // Convertir le compte en pourcentage
  const endAngle = percentage * 3.6 - 90;

  // Calcul des coordonnées pour l'arc
  const x = 60 + 30 * Math.cos((endAngle * Math.PI) / 180);
  const y = 60 + 30 * Math.sin((endAngle * Math.PI) / 180);

  return (
    <svg width="120" height="120">
      <circle
        cx="60"
        cy="60"
        r="30"
        fill="none"
        stroke="#d6d6d6"
        strokeWidth="4"
      />
      <text
        x="50%"
        y="50%"
        dominantBaseline="middle"
        textAnchor="middle"
        fontSize="20px"
        fontWeight="bold"
        fill="white"
      >
        {count}
      </text>

      <path
        d={`M 60 30 A 30 30 0 ${percentage > 50 ? 1 : 0} 1 ${x} ${y}`}
        stroke={`url(#hello)`}
        strokeWidth="4"
        fill="none"
      />

      <circle cx={x} cy={y} r="6" fill="#28f365" />

      <defs>
        <linearGradient id="hello">
          <stop offset="16.29%" stopColor="#017ffe" />
          <stop offset="85.56%" stopColor="#28f365" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default CustomCircularProgress;
